import React, { useState } from 'react';
import { Form, Formik, FormikConfig } from 'formik';
import { FormikStepProps } from './FormikStep';
import { Row, Col, Button, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

export default function FormikStepper({
  children,
  ...props
}: FormikConfig<any>) {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<
    FormikStepProps
  >[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
        } else {
          setStep(s => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <div className="wizard clearfix">
            <div className="steps clearfix">
              <ul>
                {childrenArray.map((child, index) => (
                  <NavItem
                    key={child.props.label}
                    className={classnames({
                      current: step === index,
                    })}
                  >
                    <NavLink
                      className={classnames({
                        current: step === index,
                      })}
                    >
                      <span className="number">{`0${index + 1}`}</span>
                      {child.props.label}
                    </NavLink>
                  </NavItem>
                ))}
              </ul>
            </div>
          </div>

          {currentChild}
          <Row>
            <Col sm="12" className="d-flex justify-content-end">
              {step > 0 ? (
                <Button
                  className="m-2"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => setStep(s => s - 1)}
                >
                  上一步
                </Button>
              ) : null}
              <Button
                className="m-2"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? 'Submitting' : isLastStep() ? '提交' : '下一步'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
