import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Col,
  Card,
  CardBody,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardSubtitle,
} from 'reactstrap';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { useParams } from 'react-router-dom';
import { changePreloader } from 'store/Layout';
import { HouseCheck, BSForm, BWForm } from 'app/models';
import moment from 'moment';
import { getOneHouseCheckCase } from 'app/services/HouseCheckService';
import { getImageURL } from 'app/helpers/CommonHelper';

//Lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface HouseCheckDetailProps {
  bsFormid?: string;
  bwFormid?: string;
  houseCheckid: string;
}

interface GalleryIndexes {
  ri: number; // room index
  si: number; // section index
  ii: number; // image index
}

export function HouseCheckDetailPage() {
  const dispatch = useDispatch();
  const params = useParams<HouseCheckDetailProps>();
  const [formType, setFormType] = useState<'bs' | 'bw'>('bs');
  const [hcCase, setCase] = useState<HouseCheck | null>(null);
  const [form, setForm] = useState<BSForm | BWForm | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isGallery, setIsGallery] = useState<boolean>(false);
  const [gi, setGalleryIndexes] = useState<GalleryIndexes | null>(null);
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );

  const initDetail = useCallback(async () => {
    dispatch(changePreloader(true));
    const { houseCheckid } = params;
    try {
      const houseRes = await getOneHouseCheckCase(houseCheckid);
      setCase(houseRes);
      setForm(houseRes?.BSForm || null);
      dispatch(changePreloader(false));
    } catch (err) {
      console.log(err);
      dispatch(changePreloader(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initDetail();
  }, [initDetail]);

  const setHCForm = type => {
    if (type === 'bs') {
      setFormType('bs');
      setForm(hcCase?.BSForm || null);
    } else {
      setFormType('bw');
      setForm(hcCase?.BWForm || null);
    }
  };

  const BreadcrumbsOptions = [
    { title: '驗樓個案', path: '/housecheck' },
    { title: '驗樓列表', path: '/housecheck' },
    { title: `驗樓表格`, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>驗樓詳情 | Easy Living</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="驗樓詳情" breadcrumbItems={BreadcrumbsOptions} />
          <Card>
            <CardBody>
              <Media>
                <Media body className="align-self-center">
                  <div className="text-muted p-2">
                    <div className="d-flex">
                      <h5>
                        {hcCase && hcCase.clientName ? hcCase.clientName : '-'}
                      </h5>
                    </div>
                    <p className="mb-1">
                      驗樓單位: {hcCase?.estate} {hcCase?.buildingAndRoom}
                    </p>
                    <p className="mb-1">
                      開始日期:{' '}
                      {hcCase && hcCase.startTime
                        ? moment(hcCase.startTime).format('DD-MM-YYYY')
                        : '-'}
                    </p>
                    {hcCase?.BSForm ? (
                      <p className="mb-1">
                        BS 驗樓員: {hcCase?.BSForm?.Admin?.displayName || '-'}
                      </p>
                    ) : null}
                    {hcCase?.BWForm ? (
                      <p className="mb-1">
                        BW 驗樓員: {hcCase?.BWForm?.Admin?.displayName || '-'}
                      </p>
                    ) : null}
                  </div>
                </Media>
              </Media>
            </CardBody>
          </Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={formType === 'bs' ? 'active' : ''}
                onClick={() => setHCForm('bs')}
              >
                BS 表格
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={formType === 'bw' ? 'active' : ''}
                onClick={() => setHCForm('bw')}
              >
                BW 表格
              </NavLink>
            </NavItem>
          </Nav>
          {!isPreloader ? (
            <Card>
              <CardBody>
                <Nav pills className="navtab-bg nav-justified">
                  {form && form.rooms && form.rooms.length > 0
                    ? form.rooms.map((room, index) => (
                        <NavItem key={index}>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={activeTab === index ? 'active' : ''}
                            onClick={() => setActiveTab(index)}
                          >
                            {room.roomName}
                          </NavLink>
                        </NavItem>
                      ))
                    : null}
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  {form && form.rooms && form.rooms.length > 0 ? (
                    form.rooms.map((room, index) => (
                      <TabPane tabId={index} key={index}>
                        <Row>
                          <Col sm="12">
                            {room.sections && room.sections.length > 0
                              ? room.sections.map((section, i) => (
                                  <div className="mt-3 pb-3 border-bottom">
                                    <CardTitle>{`${i + 1}. ${
                                      section.name
                                    }`}</CardTitle>
                                    {section.remark ? (
                                      <CardSubtitle className="mb-3 mt-2">
                                        {section.remark}
                                      </CardSubtitle>
                                    ) : null}
                                    <div className="table-responsive">
                                      <table className="table table-bordered table-striped table-nowrap mb-0">
                                        <thead>
                                          <tr>
                                            <th className="text-center">
                                              檢驗項目
                                            </th>
                                            <th className="text-center">
                                              發現問題
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {section.items.map(item => {
                                            return (
                                              <tr>
                                                <th
                                                  className="text-nowrap"
                                                  scope="row"
                                                >
                                                  {item.name}
                                                </th>
                                                <td
                                                  className="text-center"
                                                  style={{ width: 100 }}
                                                >
                                                  {item.isChecked ? (
                                                    <i
                                                      className="bx bx-check-circle"
                                                      style={{
                                                        color: '#2ecc71',
                                                        fontSize: 20,
                                                      }}
                                                    />
                                                  ) : null}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="popup-gallery d-flex flex-wrap">
                                      {section.images &&
                                      section.images.length > 0
                                        ? section.images.map((image, j) => (
                                            <div className="img-fluid float-left mt-3">
                                              <img
                                                src={getImageURL(image)}
                                                onClick={() => {
                                                  setIsGallery(true);
                                                  setGalleryIndexes({
                                                    ri: index,
                                                    si: i,
                                                    ii: j,
                                                  });
                                                }}
                                                alt=""
                                                width="120"
                                                height="70"
                                                style={{ objectFit: 'cover' }}
                                              />
                                            </div>
                                          ))
                                        : null}
                                    </div>
                                  </div>
                                ))
                              : null}
                          </Col>
                        </Row>
                      </TabPane>
                    ))
                  ) : (
                    <div>表格尚未完成</div>
                  )}
                </TabContent>
              </CardBody>
            </Card>
          ) : null}
        </Container>
      </div>

      {form && isGallery && gi ? (
        <Lightbox
          mainSrc={getImageURL(form.rooms[gi.ri].sections[gi.si].images[gi.ii])}
          nextSrc={getImageURL(
            form.rooms[gi.ri].sections[gi.si].images[
              (gi.ii + 1) % form.rooms[gi.ri].sections[gi.si].images.length
            ],
          )}
          prevSrc={getImageURL(
            form.rooms[gi.ri].sections[gi.si].images[
              (gi.ii - 1) % form.rooms[gi.ri].sections[gi.si].images.length
            ],
          )}
          enableZoom={true}
          onCloseRequest={() => {
            setIsGallery(false);
          }}
          onMovePrevRequest={() => {
            let newGI = { ...gi };
            newGI.ii =
              (newGI.ii + form.rooms[gi.ri].sections[gi.si].images.length - 1) %
              form.rooms[gi.ri].sections[gi.si].images.length;
            setGalleryIndexes(newGI);
          }}
          onMoveNextRequest={() => {
            let newGI = { ...gi };
            newGI.ii =
              (newGI.ii + form.rooms[gi.ri].sections[gi.si].images.length + 1) %
              form.rooms[gi.ri].sections[gi.si].images.length;
            setGalleryIndexes(newGI);
          }}
        />
      ) : null}
    </>
  );
}
