import { IMAGE_URL } from 'app/config';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const getFileURL = (hash: string) => {
  if (hash && hash.includes('https')) {
    return hash;
  }
  return `${IMAGE_URL}/original/${hash}`;
};
