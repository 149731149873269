import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';

import Select from 'react-select';

import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: () => void;
  isClearable?: boolean;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  isMulti?: boolean;
}

const MultiSelectField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    onChange,
    isClearable,
    disabled,
    menuPlacement,
    isMulti,
  } = props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Select
        // value={selectedMulti}
        //defaultValue={rendervalue()}
        isMulti={isMulti ? isMulti : false}
        placeholder={placeholder}
        options={options}
        // formatOptionLabel={option => option.label}
        classNamePrefix="select2-selection"
        {...field}
        //value={rendervalue()}
        onChange={e => {
          if (e) {
            helpers.setValue(e);
          } else {
            helpers.setValue('');
          }
          if (onChange) {
            onChange();
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default MultiSelectField;
