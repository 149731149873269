import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { updateOneAdminPassword } from 'app/services/AdminServices';
// users
import user1 from 'app/assets/images/users/avatar.png';
import Dialog from 'app/components/Modal/Modal';
import { toast } from 'react-toastify';
import { InputField } from 'app/components/Form';
import { useHistory } from 'react-router-dom';

const passwordFormSchema = Yup.object().shape({
  oldPassword: Yup.string().required('必填項目'),
  password: Yup.string().min(8, '密碼最少要8個位').required('必填項目'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], '密碼不相同')
    .required('必填項目'),
});

export interface PasswordForm {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}

const ProfileMenu = props => {
  const history = useHistory();

  const [menu, setMenu] = useState<boolean>(false);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { auth } = useSelector((rootState: RootState) => rootState);
  const { profile } = auth;

  const passwordFormOnSubmit = async (
    values: PasswordForm,
    action: FormikHelpers<PasswordForm>,
  ) => {
    setLoading(true);
    const { password, oldPassword } = values;
    try {
      const params = {
        password,
        oldPassword,
      };
      await updateOneAdminPassword(params);
      action.resetForm();
      setLoading(false);
      toast.success('更新密碼成功。');
      history.push('/logout');
    } catch (err) {
      setLoading(false);
      action.setErrors({ oldPassword: '舊密碼錯誤' });
      toast.warning('更新密碼失敗，請重試。');
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {profile?.displayName || 'Admin'}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div
            className="dropdown-item cursor-pointer"
            onClick={() => setFormVisible(true)}
          >
            <i className="bx bx-lock font-size-16 align-middle me-1 mb-1 text-primary" />
            <span>更改密碼</span>
          </div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 mb-1 text-danger" />
            <span>登出</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Dialog
        title="更改密碼"
        visible={formVisible}
        onClose={() => setFormVisible(false)}
      >
        <Formik
          initialValues={{ password: '', oldPassword: '', passwordConfirm: '' }}
          validationSchema={passwordFormSchema}
          onSubmit={passwordFormOnSubmit}
        >
          {() => (
            <Form className="form-horizontal">
              <p className="text-muted">
                *請注意: 成功更改密碼後，你會被強制登出。請使用新密碼重新登入。
              </p>
              <div className="mb-3">
                <InputField
                  name="oldPassword"
                  label="現時密碼"
                  placeholder="請輸入現時密碼"
                  type="password"
                />
              </div>
              <div className="mb-3">
                <InputField
                  name="password"
                  label="新密碼"
                  placeholder="請輸入新密碼"
                  type="password"
                />
              </div>
              <div className="mb-3">
                <InputField
                  name="passwordConfirm"
                  label="確認新密碼"
                  placeholder="再次請輸入新密碼"
                  type="password"
                />
              </div>

              <div className="mt-3 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  color="primary"
                  className="ms-1"
                  disabled={loading}
                >
                  {loading ? (
                    <i className="bx bx-loader-circle bx-spin" />
                  ) : (
                    '提交'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

export default ProfileMenu;
