import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Link } from 'react-router-dom';

// Import menuDropdown
import ProfileMenu from 'app/components/CommonForBoth/TopbarDropdown/ProfileMenu';

// images
import {
  default as logoDark,
  default as logoLightPng,
  default as logoLightSvg,
} from 'app/assets/images/logo.svg';
import logo from 'app/assets/images/logo_sq.png';

import { toggleLeftMenu, changeLeftSidebarType } from 'store/Layout';

function Header() {
  const dispatch = useDispatch();
  const { leftMenu, leftSideBarType } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document['mozFullScreenElement'] &&
      !document['webkitFullscreenElement']
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement['mozRequestFullScreen']) {
        document.documentElement['mozRequestFullScreen']();
      } else if (document.documentElement['webkitRequestFullscreen']) {
        document.documentElement['webkitRequestFullscreen'](
          Element['ALLOW_KEYBOARD_INPUT'],
        );
      }
    } else {
      if (document['cancelFullScreen']) {
        document['cancelFullScreen']();
      } else if (document['mozCancelFullScreen']) {
        document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        document['webkitCancelFullScreen']();
      }
    }
  };

  const tToggle = () => {
    dispatch(toggleLeftMenu(!leftMenu));
    if (leftSideBarType === 'default') {
      dispatch(changeLeftSidebarType({ sidebarType: 'condensed', isMobile }));
    } else if (leftSideBarType === 'condensed') {
      dispatch(changeLeftSidebarType({ sidebarType: 'default', isMobile }));
    }
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="30" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="30" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="50" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
