import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';

import { Label, Card, Row, Col, Button } from 'reactstrap';
import { LocalFile } from 'app/models';

interface OtherProps {
  files: LocalFile[] | null;
  onDrop: (file) => void;
  label?: string;
  multiple?: boolean;
}

const PDFUploadField = ({
  onDrop,
  label,
  files,
  multiple,
  ...props
}: OtherProps & FieldHookConfig<LocalFile[]>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);
  const onDelete = (original, index) => {
    const temp = [...original];
    temp.splice(index, 1);
    helper.setValue(temp);
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      {label ? <Label>{label}</Label> : null}
      <Dropzone
        multiple={multiple}
        onDrop={acceptedFiles => {
          onDrop(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} placeholder="file" />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>點擊或拉動PDF到此處</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {files
          ? files.map((file, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + '-file'}
                >
                  <div className="p-2">
                    <Row className="align-items-start">
                      <Col>
                        <div className="text-muted font-weight-bold">
                          {file.name}
                        </div>
                        <p className="mb-0">
                          <strong>{file.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button
                          color="danger"
                          onClick={() => onDelete(files, i)}
                        >
                          <i className="bx bx-trash-alt" />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })
          : null}
      </div>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default PDFUploadField;
