import { TimeStamp } from './Common';
import { ServiceProvider } from './ServiceProvider';
export interface Commission extends TimeStamp, CommissionParam {
  commissionid: number | null;
}
export interface CommissionParam {
  providerCompanyid: number | null;
  ProviderCompany: ServiceProvider | null;
  numberOfCases: number;
  totalAmount: number;
  commission: number;
  startDate: string;
  endDate: string;
  remarks: string;
  status: string;
  invoicePdf: string | null;
}

export const COMMISSION_STATUS = {
  Unsettled: {
    label: '未結算',
    value: 'Unsettled',
    color: 'secondary',
  },
  Settled: {
    label: '已結算',
    value: 'Settled',
    color: 'success',
  },
};
export const COMMISSION_STATUS_FILTER = {
  '': {
    label: '全部',
    value: '',
    color: '',
  },
  Unsettled: {
    label: '未結算',
    value: 'Unsettled',
    color: 'secondary',
  },
  Settled: {
    label: '已結算',
    value: 'Settled',
    color: 'success',
  },
};
