import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Row, Col, Card, CardBody, Button, Container, Media } from 'reactstrap';

import { Formik, Form, FormikHelpers } from 'formik';

import { InputField, ImageUploadField, SelectField } from 'app/components/Form';
import { LocalFile, Admin, ServiceProvider } from 'app/models';
import { formatBytes } from 'app/helpers/FormHelper';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import {
  getSingleAdmin,
  updateAdmin,
  resetAdminPassword,
  deleteAdmin,
  updateAdminAvatar,
} from 'app/services/AdminServices';
import { changePreloader } from 'store/Layout';
import Dialog from 'app/components/Modal/Modal';
import { uploadImage } from 'app/services/CommonService';
import { getImageURL } from 'app/helpers/CommonHelper';
import {
  getOneServiceProvider,
  updateOneServiceProviderStaff,
} from 'app/services/ServiceProviderService';
import { OptionItems } from 'app/models';

const STAFF_TYPE: OptionItems[] = [
  { label: '員工', value: 'Staff' },
  { label: '管理員', value: 'Master' },
];

export type FormItem = {
  displayName: string;
};

export const FORM_ITEM = {
  displayName: '',
};

export type AvatarFormItem = {
  avatar: LocalFile[] | null | any;
};

export interface PasswordForm {
  password: string;
  passwordConfirm: string;
}

export const AvatarSchema = Yup.object().shape({
  avatar: Yup.mixed().nullable().required('必填項目'),
});

export const NameSchema = Yup.object().shape({
  displayName: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  type: Yup.string().required('必填項目'),
});

export const passwordFormSchema = Yup.object().shape({
  password: Yup.string().min(8, '密碼最少要8個位').required('必填項目'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], '密碼不相同')
    .required('必填項目'),
});

export const AVATAR_ITEM = {
  avatar: null,
};

export interface AdminFormQuery {
  adminid?: string;
  providerCompanyid: string;
}

export const ServiceProviderStaffEditFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<AdminFormQuery>();
  const [adminid, setAdminid] = useState<number | null>(null);
  const [avatarForm, setAvatarForm] = useState<AvatarFormItem>(AVATAR_ITEM);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [avatarVisible, setAvatarVisible] = useState<boolean>(false);
  const [nameForm, setNameForm] = useState({ displayName: '', type: 'Staff' });
  const [staff, setStaff] = useState<Admin | null>(null);
  const [
    providerCompany,
    setProviderCompany,
  ] = useState<ServiceProvider | null>(null);
  const [passwordForm] = useState<PasswordForm>({
    password: '',
    passwordConfirm: '',
  });

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { adminid, providerCompanyid } = params;
    try {
      if (providerCompanyid) {
        const spRes = await getOneServiceProvider(providerCompanyid);
        setProviderCompany(spRes);
      }
      if (adminid) {
        const adminRes = await getSingleAdmin(adminid);
        setStaff(adminRes);
        const {
          adminid: adminID,
          displayName,
          avatar,
          ServiceProviderRole,
        } = adminRes;

        setNameForm({
          displayName,
          type: ServiceProviderRole ? ServiceProviderRole.type : 'Staff',
        });
        setAvatarForm({
          avatar: avatar
            ? [
                {
                  preview: avatar,
                  name: `Avatar Image`,
                },
              ]
            : null,
        });
        setAdminid(adminID);
        dispatch(changePreloader(false));
      } else {
        dispatch(changePreloader(false));
      }
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const avatarFormSubmit = async (
    values: AvatarFormItem,
    actions: FormikHelpers<AvatarFormItem>,
  ) => {
    const { avatar } = values;
    setModalLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', avatar[0]);
      const imageRes = await uploadImage(formData);
      const params = {
        avatar: avatarForm.avatar?.[0]?.preview || '',
      };
      if (imageRes) {
        params.avatar = imageRes.fileName;
      }
      await updateAdminAvatar(adminid!, params);
      toast.success('成功更換頭像');
      initForm();
      setModalLoading(false);
      setAvatarVisible(false);
    } catch (err) {
      toast.warning('更換頭像失敗，請重試。');
      setModalLoading(false);
    }
  };

  const deleteConfirm = async () => {
    setModalLoading(true);
    try {
      await deleteAdmin(adminid!);
      toast.success('成功刪員工');
      setModalLoading(false);
      setDeleteVisible(false);
      history.replace(
        `/serviceprovider/edit/${providerCompany?.providerCompanyid}`,
      );
    } catch (err) {
      toast.warning('刪除員工，請重試。');
      setModalLoading(false);
      setDeleteVisible(false);
    }
  };

  const deleteOnClose = () => {
    setDeleteVisible(false);
  };

  const avatarOnClose = () => {
    setModalLoading(false);
    setAvatarVisible(false);
  };

  const avataronDrop = (file, setFieldValue) => {
    if (file[0].size > 1024 * 10000) {
      toast.warning('圖片不可大於10MB');
      return;
    }
    if (
      file[0].type === 'image/jpeg' ||
      file[0].type === 'image/jpg' ||
      file[0].type === 'image/png'
    ) {
      file[0]['preview'] = URL.createObjectURL(file[0]);
      file[0]['formattedSize'] = formatBytes(file[0].size);
      setFieldValue('avatar', [file[0]]);
    } else {
      toast.warning('圖片只接受.jpg / .png檔案');
      return;
    }
  };

  const nameFormSubmit = async (values: {
    displayName: string;
    type: string;
  }) => {
    setLoading(true);
    const { displayName, type } = values;
    try {
      const params = {
        displayName,
      };
      await updateAdmin(adminid!, params);
      const staffParam = {
        displayName,
        type,
        providerCompanyid: providerCompany?.providerCompanyid!,
      };
      await updateOneServiceProviderStaff(staff?.adminid!, staffParam);
      setLoading(false);
      toast.success('編輯管理員成功。');
      initForm();
    } catch (err) {
      setLoading(false);
      toast.warning('編輯管理員失敗，請重試。');
      console.log(err);
    }
  };

  const passwordFormOnSubmit = async (
    values: PasswordForm,
    action: FormikHelpers<PasswordForm>,
  ) => {
    setLoading(true);
    const { password } = values;
    try {
      const params = {
        password,
      };
      await resetAdminPassword(adminid!, params);
      action.resetForm();
      setLoading(false);
      toast.success('更新密碼成功。');
    } catch (err) {
      setLoading(false);
      toast.warning('更新密碼失敗，請重試。');
      console.log(err);
    }
  };

  const TITLE = `編輯服務供應商員工`;
  const BreadcrumbsOptions = [
    { title: '服務供應商', path: '/serviceprovider' },
    { title: '服務供應商列表', path: '/serviceprovider' },
    {
      title: '編輯服務供應商',
      path: `${
        providerCompany
          ? `/serviceprovider/edit/${providerCompany.providerCompanyid}`
          : '#'
      }`,
    },
    {
      title: TITLE,
      path: '#',
    },
  ];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{TITLE} | Easy Living</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title="服務供應商員工"
            breadcrumbItems={BreadcrumbsOptions}
          />
          {!isPreloader ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Media>
                      <div className="ms-3">
                        <div className="avatar-md">
                          {avatarForm.avatar ? (
                            <img
                              src={getImageURL(
                                avatarForm.avatar[0].preview,
                                320,
                              )}
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          ) : (
                            <div className="avatar-md">
                              <span className="avatar-title rounded-circle bg-info">
                                {staff?.displayName ? (
                                  staff.displayName.charAt(0)
                                ) : (
                                  <i className="bx bx-user" />
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <Media body className="align-self-center">
                        <div className="text-muted p-2">
                          <div className="d-flex">
                            <h5>{staff?.displayName || '-'}</h5>
                          </div>
                          <p className="mb-1">
                            {providerCompany?.companyName || ''}
                          </p>
                        </div>
                      </Media>
                      <Media className="align-self-start">
                        <Button
                          type="button"
                          color="info"
                          className="ms-1"
                          onClick={() => setAvatarVisible(true)}
                        >
                          更換管理員頭像
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          className="ms-1"
                          onClick={() => setDeleteVisible(true)}
                        >
                          刪除
                        </Button>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}

          {!isPreloader ? (
            <Row className="d-flex">
              <Col lg={6} sm={12} className="d-flex flex-column">
                <Card style={{ flex: 1 }}>
                  <CardBody>
                    <h4 className="card-title mb-4">更改名稱</h4>
                    {!isPreloader ? (
                      <Formik
                        initialValues={nameForm}
                        validationSchema={NameSchema}
                        onSubmit={nameFormSubmit}
                      >
                        {() => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <InputField
                                name="displayName"
                                label="員工名稱"
                                placeholder="員工名稱"
                                type="text"
                              />
                            </div>
                            <div className="mb-3">
                              <SelectField
                                name="type"
                                label="員工類別"
                                placeholder="員工類別"
                                options={STAFF_TYPE}
                              />
                            </div>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} sm={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">更改密碼</h4>
                    {!isPreloader ? (
                      <Formik
                        initialValues={passwordForm}
                        validationSchema={passwordFormSchema}
                        onSubmit={passwordFormOnSubmit}
                      >
                        {() => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <InputField
                                name="password"
                                label="密碼"
                                placeholder="請輸入密碼"
                                type="password"
                              />
                            </div>
                            <div className="mb-3">
                              <InputField
                                name="passwordConfirm"
                                label="確認密碼"
                                placeholder="再次請輸入密碼"
                                type="password"
                              />
                            </div>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
        <Dialog
          visible={deleteVisible}
          title="刪除員工"
          onClose={deleteOnClose}
          loading={modalLoading}
          onConfirm={deleteConfirm}
        >
          <p>{`確定要刪除員工 ${staff?.displayName}?`}</p>
        </Dialog>

        <Dialog
          visible={avatarVisible}
          title="更換頭像"
          onClose={avatarOnClose}
          loading={modalLoading}
        >
          <div className="p-2">
            {!isPreloader ? (
              <Formik
                initialValues={avatarForm}
                validationSchema={AvatarSchema}
                onSubmit={avatarFormSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form className="form-horizontal">
                    <div className="mb-3">
                      <ImageUploadField
                        name="avatar"
                        label="頭像"
                        files={values.avatar}
                        onDrop={file => avataronDrop(file, setFieldValue)}
                      />
                    </div>
                    <div className="mt-3 d-flex flex-row-reverse">
                      <Button
                        type="submit"
                        color="primary"
                        className="ms-1"
                        disabled={modalLoading || !values.avatar?.[0]?.size}
                      >
                        {modalLoading ? (
                          <i className="bx bx-loader-circle bx-spin" />
                        ) : (
                          '提交'
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : null}
          </div>
        </Dialog>
      </div>
    </>
  );
};
