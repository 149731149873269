import { User } from './User';
import firebase from 'firebase/app';
import { Building, Estate } from './Estate';
import { ServiceProvider } from './ServiceProvider';

// export interface FirestoreUserData {
//   displayName: string;
//   avatar: string;
//   permissionids: string[];
// }

export type Roomtype =
  | 'cs'
  | 'selllease'
  | 'housecheck'
  | 'renovation'
  | 'serviceMatching';

export interface CreatedAt {
  seconds: number;
  nanoseconds: number;
}

// export interface ChatRoom extends ChatRoomDoc {
//   User: User | null;
//   id: string;
//   lastMsgUser: string;
// }

export interface ChatRoomDoc {
  createdAt: firebase.firestore.Timestamp;
  lastMessage: FirestoreMessageData;
  members: string[];
  type: Roomtype;
  userid: number;
  userRef?: firebase.firestore.DocumentReference;
}

export interface FirestoreMessageData {
  text: string;
  createdAt: firebase.firestore.Timestamp;
  sentBy?: string;
  sentByRef?: firebase.firestore.DocumentReference;
  extra?: string;
  video?: string;
  image?: string;
  system?: string;
  pendingUUID: string;
  readBy?: string[];
  systemText?: string;
  sellleaseFormid?: string;
  document?: CustomGiftedChatDocument;
}

export interface Message {
  pendingUUID?: string;
  text: string;
}

export interface ChatMessage {
  id?: string;
  text: string;
  createdAt: string | number;
  sentBy?: string;
  extra?: string;
  video?: string;
  image?: string;
  system?: string;
  readBy?: string[];
  systemText?: string;
  sellleaseFormid?: string;
  pending?: boolean;
  pendingUUID?: string;
  direction?: string;
  senderName: string;
}

export type FirestoreUserType =
  | 'house_check'
  | 'property_agent'
  | 'property_manager'
  | 'renovation_staff'
  | 'end_user';

export interface FirestoreUser {
  adminid?: number;
  userid?: number;
  avatar: string;
  displayName: string;
  fcm: string;
  permissionids?: string[];
  type: FirestoreUserType[];
}

export enum ChatroomType {
  'cs' = 'cs',
  'renovation' = 'renovation',
  'property' = 'property',
  'selllease' = 'selllease',
  'housecheck' = 'housecheck',
  'serviceMatching' = 'serviceMatching',
}

export enum ChatroomTypeOption {
  'cs' = '客服',
  'renovation' = '裝修',
  'property' = '物管',
  'selllease' = '買賣',
  'housecheck' = '驗樓',
  'serviceMatching' = '家居維修',
}

export interface FirebaseQuerySnapshot
  extends firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> {}

export interface FirebaseDocumentSnapshot
  extends firebase.firestore.DocumentSnapshot<
    firebase.firestore.DocumentData
  > {}

export interface FirestoreChatroomData {
  createdAt: firebase.firestore.Timestamp;
  lastMessage: FirestoreMessageData;
  members: string[];
  type: ChatroomType;
  userid: number;
  userRef?: firebase.firestore.DocumentReference;
  serviceRef?: firebase.firestore.DocumentReference;
  serviceMatchingCaseId?: number;
}

export interface Chatroom {
  id: string;
  createdAt: string;
  lastMessage: LastMessage;
  // members: CustomGiftedChatUser[]
  type: ChatroomType;
  read: boolean;
  userid: number;
  estateResponse?: Estate<Building>;
  providerCompany?: ServiceProvider;
  serviceMatchingCase?: any;
  customerUser?: any;
  User?: User;
  serviceMatchingCaseId?: number;
}

export interface LastMessage {
  user?: CustomGiftedChatUser;
  text: string;
  image?: string;
  video?: string;
  document?: CustomGiftedChatDocument;
  createdAt: number;
  systemText?: string;
}

export interface CustomGiftedChatUser {
  adminid?: number;
  userid?: number;
  _id: string;
  name: string;
  avatar?: string;
}

export interface FirestoreUserData {
  displayName: string;
  avatar: string;
  permissionids: string[];
  adminid?: number;
  userid?: number;
}

export interface CustomGiftedChatDocument {
  filename: string;
  path: string;
}

export interface CustomGiftedChatMessage {
  _id: string | number;
  text: string;
  createdAt: Date | number;
  user?: FirestoreUserData;
  image?: string;
  video?: string;
  audio?: string;
  system?: boolean;
  sent?: boolean;
  received?: boolean;
  pending?: boolean;
  sellleaseFormid?: string;
  systemText?: string;
  document?: CustomGiftedChatDocument | null;
  direction: 'outgoing' | 'incoming';
}
declare type renderFunction = (x: any) => JSX.Element;
export interface ChatUser {
  _id: string | number;
  name?: string;
  avatar?: string | renderFunction;
}

// export interface FirestoreUserData {
//   displayName: string;
//   avatar: string;
//   permissionids: string[];
//   adminid?: number;
//   userid?: number;
// }
