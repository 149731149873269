import { Filter, Admin } from 'app/models';
import { TimeStamp } from './Common';

export interface HouseCheckParams extends Filter {
  q?: string;
}

export interface HouseCheck extends TimeStamp {
  BSForm: BSForm | null;
  BWForm: BWForm | null;
  buildingAndRoom: string;
  clientName: string;
  endTime: null | string;
  estate: string;
  houseCheckid: number;
  signedBy: null | string;
  startTime: string;
}

export interface BSForm extends PI_FORM_DATA {
  Admin: Admin;
  adminid: number;
  bsFormid: number;
  createdAt: string;
  deletedAt: string;
  houseCheckid: number;
  updatedAt: string;
}

export interface BWForm extends PI_FORM_DATA {
  Admin: Admin;
  adminid: number;
  bwFormid: number;
  createdAt: string;
  deletedAt: string;
  houseCheckid: number;
  updatedAt: string;
}

// export interface HouseCheckRole {
//   adminid: number;
//   createdAt: string;
//   updatedAt: string;
//   signatureImage: string; //base64 image
//   displayName: string;
//   houseCheckRoleid: number;
// }

export enum PI_FORM_TYPE {
  BW,
  BS,
}

export enum ROOM_TYPE {
  dinningRoom = '客飯廳',
  room = '房',
  kitchen = '廚房',
  toliet = '廁所',
  balcony = '露台',
}

interface RoomTypeContents {
  [key: string]: CheckItem[];
}

export const BS_ROOM_TYPE_CONTENTS: RoomTypeContents = {
  [ROOM_TYPE.dinningRoom]: [
    {
      title: '冷氣機/分體散熱器/風管',
      items: [
        '機殼崩花/污漬/裂/脫色',
        '隔塵網損壞/欠缺',
        '遙控失靈',
        '不夠凍',
        '電線外露',
        '去水喉漏水',
        '去水喉塞',
      ],
    },
    {
      title: '電燈掣',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '燈掣太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
    {
      title: '插蘇位/電訊蘇位',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '蘇頭位太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '電訊蘇位信號不足',
      ],
    },
    {
      title: '電箱',
      items: [
        '有污漬/鬆脫/有損壞/收口',
        '用錯保護裝置',
        '電壓不足',
        '電線尺寸錯配',
        'MCB/RCD 唔識跳',
        '接地故障環路阻抗',
        '電線帶電部份外露',
      ],
    },
  ],
  [ROOM_TYPE.room]: [
    {
      title: '冷氣機/分體散熱器/風管',
      items: [
        '機殼崩花/污漬/裂/脫色',
        '隔塵網損壞/欠缺',
        '遙控失靈',
        '不夠凍',
        '電線外露',
        '去水喉漏水',
        '去水喉塞',
      ],
    },
    {
      title: '電燈掣',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '燈掣太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
    {
      title: '插蘇位/電訊蘇位',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '蘇頭位太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
  ],
  [ROOM_TYPE.kitchen]: [
    {
      title: '電燈/電燈掣',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '燈掣太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
    {
      title: '插蘇位',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '蘇頭位太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
    {
      title: '抽氣扇/抽油煙機/洗衣機/雪櫃/微波爐/煮食爐/煤氣爐/洗碗碟機',
      items: [
        '有污漬',
        '抽油煙機花/鬆脫/欠平水收邊/失靈/污漬',
        '抽氣扇花/鬆脫/欠平水收邊/失靈/污漬',
        '抽氣扇邊漏水',
        '洗衣機花/鬆脫/欠平水收邊/失靈/污漬',
        '雪櫃花/鬆脫/欠平水收邊/失靈/污漬',
        '微波爐花/鬆脫/欠平水收邊/失靈/污漬',
        '煮食爐花/鬆脫/欠平水收邊/失靈/污漬',
        '煤氣爐花/鬆脫/欠平水收邊/失靈/污漬',
        '洗碗碟機花/鬆脫/欠平水收邊/失靈/污漬',
        '去水喉漏水',
        '去水喉塞',
      ],
    },
    {
      title: '熱水爐掣',
      items: [
        '有污漬/鬆脫失靈/有損壞',
        '蘇頭位 / 掣太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
    {
      title: '煙霧探測頭',
      items: ['有污漬/鬆脫失靈/有損壞', '縫隙欠掃口/平水/收口/手工不一'],
    },
    {
      title: '消防花灑頭',
      items: [
        '有污漬/鬆脫失靈/有損壞',
        '欠面冚',
        '縫隙欠掃口/平水/收口/手工不一',
      ],
    },
  ],
  [ROOM_TYPE.toliet]: [
    {
      title: '電燈/電燈掣',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '燈掣太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
    {
      title: '插蘇位/熱水爐掣/煤氣爐',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '蘇頭位 / 掣太緊',
        '縫隙欠掃口/平水/收口/手工不一',
      ],
    },
    {
      title: '抽風扇/暖風機',
      items: [
        '抽氣扇花/花/鬆脫/欠平水收邊/失靈/污漬',
        '蘇頭位太緊',
        '暖風機花/花/鬆脫/欠平水收邊/失靈/污漬',
      ],
    },
  ],
  [ROOM_TYPE.balcony]: [
    {
      title: '電燈/電燈掣',
      items: [
        '有污漬/鬆脫/失靈/有損壞',
        '燈掣太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
    {
      title: '防水插蘇位',
      items: [
        '有污漬/鬆脫失靈/有損壞',
        '蘇頭位太緊',
        '縫隙欠掃口/平水/收口/手工不一',
        '無電到',
      ],
    },
  ],
};

export const BW_ROOM_TYPE_CONTENTS: RoomTypeContents = {
  [ROOM_TYPE.dinningRoom]: [
    {
      title: '門、門框及小五金',
      items: [
        '崩花 / 污漬 / 裂 / 脫色',
        '門鎖不順暢',
        '門關不貼',
        '氣鼓力度不足 / 過大',
      ],
    },
    {
      title: '牆身及天花',
      items: [
        '石屎剝落',
        '油漆鬆脫 / 剝落 / 花痕',
        '油漆或批盪空鼓',
        '牆身或天花不平',
      ],
    },
    {
      title: '地台飾面及地腳線',
      items: ['崩花 / 污漬', '磚罅不平均 / 欠掃口', '不平坦', '空鼓'],
    },
    {
      title: '窗',
      items: [
        '崩花 / 污漬',
        '窗頁膠邊鬆脫',
        '窗框邊膠鬆脫',
        '關不貼有入風情況',
        '拉手 / 窗較過鬆或過緊',
      ],
    },
  ],
  [ROOM_TYPE.room]: [
    {
      title: '門、門框及小五金',
      items: ['崩花 / 污漬 / 裂 / 脫色', '門鎖不順暢', '門關不貼'],
    },
    {
      title: '牆身及天花',
      items: [
        '石屎剝落',
        '油漆鬆脫 / 剝落 / 花痕',
        '油漆或批盪空鼓',
        '牆身或天花不平',
      ],
    },
    {
      title: '地台飾面及地腳線',
      items: ['崩花 / 污漬', '磚罅不平均 / 欠掃口', '不平坦', '空鼓'],
    },
    {
      title: '窗',
      items: [
        '崩花 / 污漬',
        '窗頁膠邊鬆脫',
        '窗框邊膠鬆脫',
        '關不貼有入風情況',
        '拉手 / 窗較過鬆或過緊',
      ],
    },
  ],
  [ROOM_TYPE.kitchen]: [
    {
      title: '門、門框及小五金',
      items: ['崩花 / 污漬 / 裂 / 脫色', '門鎖不順暢', '門關不貼'],
    },
    {
      title: '牆身及地台飾面',
      items: [
        '崩花 / 污漬',
        '磚罅不平均 / 欠掃口',
        '不平坦 / 地台斜水不足',
        '空鼓',
      ],
    },
    {
      title: '星盆及星盆檯',
      items: [
        '崩花 / 污漬 / 裂',
        '去水喉 / 隔氣漏水',
        '星盤漏水',
        '食水喉漏水',
        '水龍頭鬆脫 / 漏水',
        '去水不暢順',
      ],
    },
    {
      title: '地台去水',
      items: ['去水不暢順 / 去水喉漏水'],
    },
    {
      title: '假天花',
      items: ['崩花 / 污漬 / 鬆脫 / 損壞'],
    },
    {
      title: '櫃(櫃門及抽屉)',
      items: ['崩花 / 污漬 / 鬆脫 / 損壞', '櫃門關不貼 / 過鬆 / 過緊'],
    },
    {
      title: '窗',
      items: [
        '崩花 / 污漬',
        '窗頁膠邊鬆脫',
        '窗框邊膠鬆脫',
        '關不貼有入風情況',
        '拉手 / 窗較過鬆或過緊',
      ],
    },
  ],
  [ROOM_TYPE.toliet]: [
    {
      title: '門、門框及小五金',
      items: ['崩花 / 污漬 / 裂 / 脫色', '門鎖不順暢', '門關不貼'],
    },
    {
      title: '牆身及地台飾面',
      items: [
        '崩花 / 污漬',
        '磚罅不平均 / 欠掃口',
        '不平坦 / 地台斜水不足',
        '空鼓',
      ],
    },
    {
      title: '面盆及面盆檯',
      items: [
        '崩花 / 污漬 / 裂',
        '去水喉 / 隔氣漏水',
        '面盤漏水',
        '食水喉漏水',
        '水龍頭鬆脫 / 漏水',
        '去水不暢順',
      ],
    },
    {
      title: '地台去水',
      items: ['去水不暢順 / 去水喉漏水'],
    },
    {
      title: '浴缸 / 沐浴間',
      items: [
        '浴缸 / 浴屏 崩花 / 污漬',
        '膠邊鬆脫 / 手工不一',
        '浴屏有損壞 / 關不貼 / 漏水',
        '水龍頭鬆脫 / 漏水',
        '去水不暢順',
      ],
    },
    {
      title: '假天花',
      items: ['崩花 / 污漬 / 鬆脫 / 損壞'],
    },
    {
      title: '櫃(櫃門及抽屉)',
      items: ['崩花 / 污漬 / 鬆脫 / 損壞', '櫃門關不貼 / 過鬆 / 過緊'],
    },
    {
      title: '坐廁及水箱',
      items: ['崩花 / 污漬 / 裂', '坐廁 / 水箱漏水', '坐廁沖水不暢順'],
    },
    {
      title: '窗',
      items: [
        '崩花 / 污漬',
        '窗頁膠邊鬆脫',
        '窗框邊膠鬆脫',
        '關不貼有入風情況',
        '拉手 / 窗較過鬆或過緊',
      ],
    },
  ],
  [ROOM_TYPE.balcony]: [
    {
      title: '門、門框及小五金',
      items: ['崩花 / 污漬 / 裂 / 脫色', '門鎖不順暢', '門關不貼有入風情況'],
    },
    {
      title: '牆身及地台飾面',
      items: [
        '崩花 / 污漬',
        '磚罅不平均 / 欠掃口',
        '不平坦 / 地台斜水不足',
        '空鼓',
      ],
    },
    {
      title: '地台去水',
      items: ['去水不暢順 / 養水 / 去水喉漏水'],
    },
    {
      title: '圍欄 / 玻璃欄河',
      items: ['崩花 / 污漬 / 裂'],
    },
  ],
};

export interface CheckItem {
  title: string;
  items: string[];
}

export interface PI_FORM_TAB {
  roomName: string;
  type: ROOM_TYPE;
}

export const DEFAULT_FORM_TABS: PI_FORM_TAB[] = [
  {
    roomName: ROOM_TYPE.dinningRoom,
    type: ROOM_TYPE.dinningRoom,
  },
  {
    roomName: ROOM_TYPE.room,
    type: ROOM_TYPE.room,
  },
  {
    roomName: `${ROOM_TYPE.room} (1)`,
    type: ROOM_TYPE.room,
  },
  {
    roomName: ROOM_TYPE.kitchen,
    type: ROOM_TYPE.kitchen,
  },
  {
    roomName: ROOM_TYPE.toliet,
    type: ROOM_TYPE.toliet,
  },
  {
    roomName: ROOM_TYPE.balcony,
    type: ROOM_TYPE.balcony,
  },
];

export interface PI_FORM_DATA {
  status: 'Pending' | 'Completed';
  rooms: PI_FORM_ROOM_DATA[];
}

export interface PI_FORM_ROOM_DATA {
  key: string;
  roomName: string;
  type: string;
  sections: PI_FORM_SECTION[];
}

export interface PI_FORM_SECTION {
  name: string;
  items: {
    name: string;
    isChecked: boolean;
  }[];
  remark: string;
  images: string[];
}
