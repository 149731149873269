import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import { Input } from 'reactstrap';
import { Label } from 'reactstrap';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  label: string;
  type?: InputType;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  colorType?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'white';
  frontAddOntext?: string;
  backAddOntext?: string;
}

const InputGroupField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const {
    label,
    type,
    placeholder,
    rows,
    disabled,
    colorType,
    frontAddOntext,
    backAddOntext,
  } = props;
  return (
    <div className={clsx(meta.touched && meta.error && 'text-danger', 'mb-3')}>
      {label ? <Label>{label}</Label> : null}
      <div className="input-group ">
        {frontAddOntext && (
          <span className={`input-group-text btn-${colorType}`}>
            {frontAddOntext}
          </span>
        )}
        <Input
          className="form-control"
          {...field}
          placeholder={disabled ? '' : placeholder}
          type={type}
          autoComplete="new-password"
          rows={rows}
          disabled={disabled}
        />
        {backAddOntext && (
          <span className={`input-group-text btn-${colorType}`}>
            {backAddOntext}
          </span>
        )}
      </div>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default InputGroupField;
