import { updateAdminPropertyAgebtRoleParams } from 'app/services/AdminServices';
import { Estate } from '.';
import { TimeStamp } from './Common';

export interface AdminLoginPayload {
  loginname: string;
  password: string;
}

export interface LoginResponse extends Admin {
  firebase_email: string;
  token: string;
  uid: string;
}

export interface Admin extends TimeStamp {
  EventManagerRole?: EventManagerRole;
  ServiceProviderRole?: ServiceProviderRole;
  RenovationStaffRole?: RenovationStaf;
  HouseCheckRole?: HouseCheckRole;
  SuperPropertyManagerRole?: SuperPropertyManagerRole;
  PropertyManagerRoles?: PropertyManagerRole[];
  SuperAdminRole?: SuperAdminRole;
  CSRole?: CSRole;
  PropertyAgentRole?: PropertyAgentRole;
  adminid: number;
  hash: string | null;
  loginname: string;
  nick?: string;
  displayName: string;
  role: 'admin' | 'appuser';
  avatar: string | null;
  uid: string;
  isAvaliable: boolean;
}

export interface AdminPara {
  nick?: string;
  loginname?: string;
  password?: string;
  displayName: string;
  avatar: string | null;
  role: 'admin';
  RenovationStaffRole?: {
    displayName: string;
  };
  HouseCheckRole?: {
    signatureImage: string;
    displayName: string;
  };
  PropertyManagerRoles?: PropertyManagerRolePara[] | null;
  PropertyAgentRole?: updateAdminPropertyAgebtRoleParams;
}

export interface PropertyManagerRolePara {
  displayName: string;
  estateid: number;
  isEdit?: boolean;
  propertyName?: string;
  type: '物業主任' | '物業經理';
}

export interface PropertyManagerRole extends PropertyManagerRolePara {
  Estate: Estate<any>;
}

export interface SuperPropertyManagerRole extends TimeStamp {
  adminid: number;
  superPropertyManagerRoleid: number;
}

export interface PropertyManagerRole
  extends TimeStamp,
    PropertyManagerRolePara {
  propertyManagerRoleid: number;
  adminid: number;
}

export interface HouseCheckRole extends TimeStamp {
  adminid?: number;
  houseCheckRoleid?: number;
  signatureImage: string | null;
  displayName: string;
}

export interface RenovationStaf extends TimeStamp {
  displayName: string;
  renovationStaffRoleid: number;
}

export interface SuperAdminRole extends TimeStamp {
  adminid: number;
  superAdminRoleid: number;
}

export interface PropertyAgentRole extends TimeStamp {
  adminid: number;
  propertyAgentRoleid: number;
  displayName: string;
  signatureImage: string | null;
  estateid: number;
}

export interface CSRole extends TimeStamp {
  adminid: number;
  cSRoleid: number;
}

export interface ServiceProviderRole extends TimeStamp {
  adminid: number;
  displayName: string;
  providerCompanyid: number;
  serviceProviderRoleid: number;
  type: 'Staff' | 'Master';
}

export interface EventManagerRole extends TimeStamp {
  adminid: number;
  eventManagerRoleid: number;
}

export enum AdminRolesMap {
  'cs' = 'CSRole',
  'event-manager' = 'EventManagerRole',
  'house-check' = 'HouseCheckRole',
  'property-agent' = 'PropertyAgentRole',
  'property-manager-主任' = 'PropertyManagerRoles',
  'property-manager-經理' = 'PropertyManagerRoles',
  'renovation-staff' = 'RenovationStaffRole',
  'service-provider' = 'ServiceProviderRole',
  'super-admin' = 'SuperAdminRole',
  'super-property-manager' = 'SuperPropertyManagerRole',
}

export type AdminRolesType =
  | 'cs'
  | 'event-manager'
  | 'house-check'
  | 'property-agent'
  | 'property-manager-主任'
  | 'property-manager-經理'
  | 'renovation-staff'
  | 'service-provider'
  | 'super-admin'
  | 'super-property-manager';
