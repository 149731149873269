import { MediaResponse, MediaTypes } from 'app/models';
import Axios from './';

/**
* * get latest media (https://easy-living-dev.appicidea.com/doc/#/Media/get_v1_media_)
* * 
+*/

export const getLatestMedia = async () => {
  const { data } = await Axios.get<MediaResponse>('/media');
  return data;
};

/**
* * create media (https://easy-living-dev.appicidea.com/doc/#/Media/patch_v1_media__mediaid_)
* * 
+*/

export interface CreateMediaParams {
  category: MediaTypes;
  type: string;
  url: string;
}

export const createOneMedia = async (parmas: CreateMediaParams) => {
  const { data } = await Axios.post<any>('/media/', parmas);
  return data;
};

/**
* * update media (https://easy-living-dev.appicidea.com/doc/#/Media/patch_v1_media__mediaid_)
* * 
+*/

export const updateOneMedia = async (
  mediaid: string | number,
  parmas: CreateMediaParams,
) => {
  const { data } = await Axios.patch<any>(`/media/${mediaid}`, parmas);
  return data;
};
