import { Filter, TimeStamp } from '../models';

export enum LocationType {
  hk = '香港',
  kln = '九龍',
  nt = '新界',
}

export enum LocationTypeOption {
  all = '所有地域',
  hk = '香港',
  kln = '九龍',
  nt = '新界',
}

export enum HKIslandType {
  'Central and Western' = '中西區',
  'Eastern' = '東區',
  'Southern' = '南區',
  'Wan Chai' = '灣仔區',
}

export enum KowloonType {
  'Sham Shui Po' = '深水埗區',
  'Kowloon City' = '九龍城區',
  'Kwun Tong' = '觀塘區',
  'Wong Tai Sin' = '黃大仙區',
  'Yau Tsim Mong' = '油尖旺區',
}

export enum NTType {
  'Islands' = '離島區',
  'Kwai Tsing' = '葵青區',
  'North' = '北區',
  'Sai Kung' = '西貢區',
  'Sha Tin' = '沙田區',
  'Tai Po' = '大埔區',
  'Tsuen Wan' = '荃灣區',
  'Tuen Mun' = '屯門區',
  'Yuen Long' = '元朗區',
}

export enum AllDistricts {
  'Central and Western' = '中西區',
  'Eastern' = '東區',
  'Southern' = '南區',
  'Wan Chai' = '灣仔區',
  'Sham Shui Po' = '深水埗區',
  'Kowloon City' = '九龍城區',
  'Kwun Tong' = '觀塘區',
  'Wong Tai Sin' = '黃大仙區',
  'Yau Tsim Mong' = '油尖旺區',
  'Islands' = '離島區',
  'Kwai Tsing' = '葵青區',
  'North' = '北區',
  'Sai Kung' = '西貢區',
  'Sha Tin' = '沙田區',
  'Tai Po' = '大埔區',
  'Tsuen Wan' = '荃灣區',
  'Tuen Mun' = '屯門區',
  'Yuen Long' = '元朗區',
}

export interface EstateFilter extends Filter {
  region?: string;
  district?: string;
  name?: string;
  estateids?: string;
}

export interface Building extends TimeStamp, BuildingParams {
  estateBuildingid: number;
  estateid: number;
  Estate: Estate<Building>;
}

export interface BuildingParams {
  extra: null | any;
  name: string;
  nameEng: string | null;
  contactPhoneNum: string;
}

export interface Estate<T> extends TimeStamp, EstateParams<T> {
  estateid: number;
}

export type EstateType = '公' | '私';

export interface EstateParams<T> {
  region: string;
  district: string;
  name: string;
  extra: any;
  contactPhoneNum: string;
  EstateBuildings: T[];
  code: string;
  type: EstateType;
  nameEng: string;
  officeNameEng: string;
  officeNameChi: string;
  officeAddressChi: string;
  officeAddressEng: string;
  email: string;
  fax: string;
  isValidForAutoChecking: boolean;
}
