import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { toast } from 'react-toastify';

import { Row, Col, Card, CardBody, Button, Container } from 'reactstrap';

import { MediaOptions, MediaTypes } from '../../../models';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'app/components/Form';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import { changePreloader } from 'store/Layout';
import {
  createOneMedia,
  getLatestMedia,
  updateOneMedia,
} from 'app/services/MediaService';

interface MideaSettingItem {
  type: string;
  url: string;
  category: MediaTypes;
  mediaid?: number | string;
}

interface FormItem {
  media: MideaSettingItem[];
}

const FORM_ITEM: FormItem = {
  media: [
    { type: 'video', url: '', category: 'Service Matching' },
    { type: 'video', url: '', category: 'House Renovation' },
    { type: 'video', url: '', category: 'Estate Management' },
    { type: 'video', url: '', category: 'Property' },
    { type: 'video', url: '', category: 'House Check' },
  ],
};

const Schema = Yup.object().shape({
  media: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().nullable().required('必填項目'),
    }),
  ),
});

export function MediaSettingFormPage() {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const [form, setForm] = useState<FormItem>({ ...FORM_ITEM });
  const [loading, setLoading] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    try {
      const mediaRes = await getLatestMedia();
      const tempForm = Object.keys(mediaRes).map(key => {
        const { category, mediaid, url } = mediaRes[key];
        const temp: MideaSettingItem = {
          category: category ? category : key,
          type: 'video',
          url: url ? url : '',
        };
        if (mediaid) {
          temp.mediaid = mediaid;
        }
        return temp;
      });
      setForm({ media: tempForm });
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onSubmit = async (values: FormItem, actions: FormikHelpers<any>) => {
    setLoading(true);
    try {
      const { media } = values;
      const PromiseArray: any[] = [];
      media.forEach(item => {
        const { url, category, type } = item;
        if (item.mediaid) {
          PromiseArray.push(
            updateOneMedia(item.mediaid, { url, type, category }),
          );
        } else {
          PromiseArray.push(createOneMedia({ url, type, category }));
        }
      });
      await Promise.all(PromiseArray);
      setLoading(false);
      toast.success('編輯媒體成功。');
    } catch (err) {
      console.log(err);
      toast.warning('編輯媒體失敗，請重試。');
      setLoading(false);
    }
  };

  const TITLE = `媒體管理`;
  const BreadcrumbsOptions = [{ title: '編輯媒體', path: '#' }];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{TITLE} | Easy Living</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="媒體管理" breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({ values }) => (
                          <Form className="form-horizontal">
                            {values.media.map((item, index) => (
                              <Row
                                className="mb-3"
                                key={index.toString() + new Date().toString()}
                              >
                                <Col lg="12">
                                  <InputField
                                    name={`media.[${index}].url`}
                                    placeholder="輸入YouTube ID"
                                    label={`${
                                      MediaOptions[item.category]
                                    } YouTube ID`}
                                  />
                                </Col>
                              </Row>
                            ))}
                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
