import React from 'react';
import moment from 'moment';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Commission, COMMISSION_STATUS } from 'app/models';
import clsx from 'clsx';
import { API_URL, IMAGE_URL } from 'app/config';

const CommissionListColumns = () => [
  {
    text: '#',
    dataField: 'commissionid',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">{commission.commissionid}</div>
        </h5>
      </>
    ),
  },
  {
    text: '服務供應商名稱',
    dataField: 'commissionStartDate',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {commission.ProviderCompany &&
            commission.ProviderCompany.companyName
              ? commission.ProviderCompany.companyName
              : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '佣金日期',
    dataField: 'startDate,endDate',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {`${moment(commission.startDate).format('DD/MM/YYYY')} - ${moment(
              commission.endDate,
            ).format('DD/MM/YYYY')}`}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '案例數',
    dataField: 'numberOfCases',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{commission.numberOfCases}</div>
        </h5>
      </>
    ),
  },
  {
    text: '總金額',
    dataField: 'totalAmount',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">${commission.totalAmount}</div>
        </h5>
      </>
    ),
  },
  {
    text: '佣金',
    dataField: 'commission',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">${commission.commission}</div>
        </h5>
      </>
    ),
  },
  {
    text: '狀態',
    dataField: 'status',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {commission.status ? (
              <Badge
                pill
                className={clsx(
                  `badge-soft-${COMMISSION_STATUS[commission.status].color}`,
                  ' ms-1',
                  ' p-1 ',
                  'font-size-12',
                )}
              >
                {COMMISSION_STATUS[commission.status].label}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(commission.createdAt).format('DD/MM/YYYY HH:mm:SS')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '最後更新',
    dataField: 'updatedAt',
    sort: false,
    formatter: (commission: Commission) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(commission.updatedAt).format('DD/MM/YYYY HH:mm:SS')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (commission: Commission) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <a
            href={
              commission.invoicePdf
                ? `${IMAGE_URL}/original/${commission.invoicePdf}`
                : `${API_URL}/v1/servicematching/company/commission/${commission.commissionid}/invoice`
            }
            id={'download' + commission.commissionid}
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bx-download" />
            <UncontrolledTooltip
              placement="top"
              target={'download' + commission.commissionid}
            >
              下載Invoice
            </UncontrolledTooltip>
          </a>
        </li>
        <li className="list-inline-item px-2">
          <Link
            to={`/commission/edit/${commission.commissionid}`}
            id={'edit' + commission.commissionid}
          >
            <i className="bx bx-edit-alt" />
            <UncontrolledTooltip
              placement="top"
              target={'edit' + commission.commissionid}
            >
              編輯佣金
            </UncontrolledTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default CommissionListColumns;
