import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store';
import {
  ChatroomType,
  FirebaseQuerySnapshot,
  FirebaseDocumentSnapshot,
  FirestoreChatroomData,
  Chatroom,
  CustomGiftedChatUser,
  FirestoreUserData,
  Estate,
  Building,
} from 'app/models';
import { db } from '../firebase';
import _ from 'lodash';
import { getOneEstate, getEstateByIds } from 'app/services/EstateService';
import { getCaseByIds } from 'app/services/ServiceMatchingServices';
import {
  markRoomAsRead,
  RoomRequest,
  searchRoomByUsername,
} from 'app/services/ChatService';
import { getAdminChatroomRoles } from 'app/helpers/ChatRoomHelper';
import { getUserByIds } from 'app/services/UserService';

const CHATROOM_FETCH_LIMIT = 20;
type UnreadEstateChatroomMap = { [estateid: number]: boolean };

interface ChatRoomState {
  chatroomTypeFilter: ChatroomType | '';
  chatroomList: Chatroom[];
  searchChatroomList: Chatroom[];
  refreshing: boolean;
  appending: boolean;
  hasUnreadChatroom: boolean;
  selectedEstate: Estate<Building> | null;
  unreadEstateChatroomMap: UnreadEstateChatroomMap;
  isListEnd: boolean;
}

const initialState = {
  chatroomTypeFilter: '',
  chatroomList: [],
  searchChatroomList: [],
  refreshing: false,
  appending: false,
  hasUnreadChatroom: false,
  selectedEstate: null,
  unreadEstateChatroomMap: {},
  isListEnd: false,
} as ChatRoomState;

const chatRoomSlice = createSlice({
  name: 'chatRoomList',
  initialState,
  reducers: {
    refreshListStart(state) {
      state.refreshing = true;
    },
    refreshListSuccess(state, action: PayloadAction<Chatroom[]>) {
      state.chatroomList = action.payload;
      state.refreshing = false;
    },
    refreshListFail(state) {
      state.refreshing = false;
    },
    appendListStart(state) {
      state.appending = true;
    },
    appendListSuccess(state, action: PayloadAction<Chatroom[]>) {
      state.chatroomList = [...state.chatroomList, ...action.payload];
      state.appending = false;
    },
    appendListFail(state) {
      state.appending = false;
    },
    setChatroomList(state, action: PayloadAction<Chatroom[]>) {
      state.chatroomList = action.payload;
      state.refreshing = false;
    },
    setChatroomTypeFilter(state, action: PayloadAction<ChatroomType | ''>) {
      state.chatroomTypeFilter = action.payload;
    },
    setListRefreshing(state, action: PayloadAction<boolean>) {
      state.refreshing = action.payload;
    },
    setSearchChatroomList(state, action: PayloadAction<Chatroom[]>) {
      state.searchChatroomList = action.payload;
      state.refreshing = false;
    },
    setHasUnreadChatroom(state, action: PayloadAction<boolean>) {
      state.hasUnreadChatroom = action.payload;
    },
    setSelectedEstate(state, action: PayloadAction<Estate<Building>>) {
      state.selectedEstate = action.payload;
    },
    setUnreadEstateChatroomMap(
      state,
      action: PayloadAction<{
        estateid: number;
        read: boolean;
      }>,
    ) {
      state.unreadEstateChatroomMap[action.payload.estateid] =
        action.payload.read;
    },
    setIsListEnd(state, action: PayloadAction<boolean>) {
      state.isListEnd = action.payload;
    },
  },
});

export const {
  refreshListStart,
  refreshListSuccess,
  refreshListFail,
  appendListStart,
  appendListSuccess,
  appendListFail,
  setChatroomList,
  setChatroomTypeFilter,
  setListRefreshing,
  setSearchChatroomList,
  setHasUnreadChatroom,
  setSelectedEstate,
  setUnreadEstateChatroomMap,
  setIsListEnd,
} = chatRoomSlice.actions;

export default chatRoomSlice.reducer;

export const getChatroomInformationByID = async (
  uid: string,
  roomid: string,
) => {
  const documentSnapshot = await db.collection('rooms').doc(roomid).get();
  const chatrooms = await convertFirestoreChatroomToChatroom(uid, [
    documentSnapshot,
  ]);
  const chatroom = chatrooms[0];
  let estateBuilding: Estate<Building> | undefined;
  if (chatroom.type === ChatroomType.property) {
    const data = documentSnapshot.data() as FirestoreChatroomData;
    const found = _.find(data.members, member => {
      return member.match(/^(e\d+)/);
    }) as string;
    if (found) {
      const response = await getOneEstate(parseInt(found.substring(1), 10));
      estateBuilding = response;
    }
  }
  return {
    chatroom,
    estateBuilding,
  };
};

const convertFirestoreChatroomToChatroom = async (
  uid: string,
  docs: FirebaseDocumentSnapshot[],
) => {
  let chatrooms: Chatroom[] = [];
  let serviceMatchingIds: number[] = [];
  let estateids: number[] = [];
  let userids: number[] = [];

  for (let x = 0; x < docs.length; x += 1) {
    const doc = docs[x];
    const data = doc.data() as FirestoreChatroomData;
    let sentByUser: CustomGiftedChatUser | null = null;
    if (data.lastMessage.sentBy) {
      try {
        const userDocument = await db
          .collection('users')
          .doc(data.lastMessage.sentBy)
          .get();
        const userData = userDocument.data() as FirestoreUserData;
        if (userData) {
          sentByUser = {
            _id: data.lastMessage.sentBy,
            name: userData.displayName,
            avatar: userData.avatar,
            adminid: userData.adminid,
            userid: userData.userid,
          };
        }
      } catch (error) {
        console.log(error);
      }
    }

    userids.push(data.userid);

    // if service case exists
    if (data.serviceMatchingCaseId) {
      serviceMatchingIds.push(data.serviceMatchingCaseId);
    }

    // if chatroom type = propety
    if (data.type === ChatroomType.property) {
      const found = _.find(data.members, member => {
        return member.match(/^(e\d+)/);
      }) as string;
      if (found) {
        estateids.push(parseInt(found.substring(1), 10));
      }
    }

    const chatroom: Chatroom = {
      id: doc.id,
      createdAt: data.createdAt.toDate().toISOString(),
      lastMessage: {
        createdAt: data?.lastMessage?.createdAt.toMillis() || 0,
        text: data.lastMessage.text,
        user: sentByUser ?? undefined,
        systemText: data.lastMessage.systemText,
        image: data.lastMessage.image,
        video: data.lastMessage.video,
        document: data.lastMessage.document,
      },
      read:
        //@ts-ignore
        data?.lastMessage?.readBy?.[uid] === undefined ||
        //@ts-ignore
        data?.lastMessage?.readBy?.[uid] === true ||
        false,
      type: data.type,
      userid: data.userid,
    };
    chatrooms.push(chatroom);
  }

  if (serviceMatchingIds.length > 0) {
    const cases = await getCaseByIds(serviceMatchingIds);
    for (let x = 0; x < docs.length; x += 1) {
      const data = docs[x].data() as FirestoreChatroomData;
      if (data.serviceMatchingCaseId) {
        const caseInformation = _.find(cases, {
          serviceMatchingCaseId: data.serviceMatchingCaseId,
        });
        chatrooms[x].serviceMatchingCase = caseInformation;
        chatrooms[x].providerCompany = caseInformation?.ProviderCompany;
      }
    }
  }

  if (estateids.length > 0) {
    const estates = await getEstateByIds(estateids);
    for (let x = 0; x < docs.length; x += 1) {
      const data = docs[x].data() as FirestoreChatroomData;
      const found = _.find(data.members, member => {
        return member.match(/^(e\d+)/);
      }) as string;
      if (found) {
        chatrooms[x].estateResponse = _.find(estates, {
          estateid: parseInt(found.substring(1), 10),
        });
      }
    }
  }

  if (userids.length > 0) {
    const users = await getUserByIds(userids);
    for (let x = 0; x < docs.length; x += 1) {
      const data = docs[x].data() as FirestoreChatroomData;
      const foundUser = _.find(users, {
        userid: data.userid,
      });
      if (foundUser) {
        chatrooms[x].customerUser = {
          _id: foundUser.uid,
          name: foundUser.name,
          avatar: foundUser.avatar ?? '',
          userid: foundUser.userid,
          phoneNumber: foundUser.phoneNumber || '',
        };
      }
    }
  }

  return chatrooms;
};

const formatChatroomList = async (
  uid: string,
  querySnapshot: FirebaseQuerySnapshot,
) => {
  try {
    return convertFirestoreChatroomToChatroom(uid, querySnapshot.docs);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useInitAdminMessageListener = () => {
  const dispatch = useDispatch();
  const { chatRoom, auth } = useSelector((rootState: RootState) => rootState);
  const { chatroomTypeFilter, selectedEstate } = chatRoom;
  const { profile } = auth;

  useEffect(() => {
    if (profile) {
      let subscriber: () => void;
      let unreadChatroomCountSubscribers: (() => void)[] = [];
      const onResult = (snapshot: FirebaseQuerySnapshot) => {
        dispatch(handleNewChatrooms(profile.uid, snapshot));
      };
      const onError = (error: Error) => {
        console.error(error);
      };
      dispatch(setListRefreshing(true));
      // property manager
      if (
        profile &&
        profile.PropertyManagerRoles &&
        profile.PropertyManagerRoles.length > 0
      ) {
        for (let role of profile.PropertyManagerRoles) {
          unreadChatroomCountSubscribers.push(
            db
              .collection('rooms')
              .where('members', 'array-contains-any', [
                profile.uid,
                `e${role.estateid}`,
              ])
              .where(`lastMessage.readBy.${profile.uid}`, '==', false)
              .limit(1)
              .onSnapshot((snapshot: FirebaseQuerySnapshot) => {
                dispatch(
                  setUnreadEstateChatroomMap({
                    estateid: role.estateid!,
                    read: snapshot.size > 0 ? true : false,
                  }),
                );
              }, onError),
          );
        }

        if (!selectedEstate) {
          dispatch(setSelectedEstate(profile.PropertyManagerRoles[0].Estate));
        }
        subscriber = db
          .collection('rooms')
          .where('members', 'array-contains-any', [
            profile.uid,
            `e${
              selectedEstate
                ? selectedEstate?.estateid
                : profile.PropertyManagerRoles[0].estateid
            }`,
          ])
          .orderBy('lastMessage.createdAt', 'desc')
          .limit(CHATROOM_FETCH_LIMIT)
          .onSnapshot(onResult, onError);
      } else {
        // cs || admin
        unreadChatroomCountSubscribers.push(
          db
            .collection('rooms')
            .where('members', 'array-contains-any', [
              profile.uid,
              ...getAdminChatroomRoles(profile),
            ])
            .where(`lastMessage.readBy.${profile.uid}`, '==', false)
            .limit(1)
            .onSnapshot((snapshot: FirebaseQuerySnapshot) => {
              dispatch(setHasUnreadChatroom(snapshot.size > 0 ? true : false));
            }, onError),
        );

        if (chatroomTypeFilter) {
          subscriber = db
            .collection('rooms')
            .where('members', 'array-contains-any', [
              profile.uid,
              ...getAdminChatroomRoles(profile),
            ])
            .where('lastMessage.system', '==', '')
            .where('type', '==', chatroomTypeFilter)
            .orderBy('lastMessage.createdAt', 'desc')
            .limit(CHATROOM_FETCH_LIMIT)
            .onSnapshot(onResult, onError);
        } else {
          subscriber = db
            .collection('rooms')
            .where('lastMessage.system', '==', '')
            .where('members', 'array-contains-any', [
              profile.uid,
              ...getAdminChatroomRoles(profile),
            ])
            .orderBy('lastMessage.createdAt', 'desc')
            .limit(CHATROOM_FETCH_LIMIT)
            .onSnapshot(onResult, onError);
        }
      }

      return () => {
        subscriber();
        for (let unsub of unreadChatroomCountSubscribers) {
          unsub();
        }
      };
    }
  }, [dispatch, chatroomTypeFilter, profile, selectedEstate]);
};

export const refreshChatroomList = (): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setIsListEnd(false));
    const { chatRoom, auth } = getState();
    const { chatroomTypeFilter, selectedEstate } = chatRoom;
    const { profile } = auth;
    let querySnapshot;
    if (
      profile!.PropertyManagerRoles &&
      profile!.PropertyManagerRoles.length > 0 &&
      selectedEstate
    ) {
      querySnapshot = await db
        .collection('rooms')
        .where('members', 'array-contains-any', [
          profile!.uid,
          `e${
            selectedEstate
              ? selectedEstate?.estateid
              : profile!.PropertyManagerRoles[0].estateid
          }`,
        ])
        .where('lastMessage.system', '==', '')
        .orderBy('lastMessage.createdAt', 'desc')
        .limit(CHATROOM_FETCH_LIMIT)
        .get();
    } else if (chatroomTypeFilter) {
      querySnapshot = await db
        .collection('rooms')
        .where('members', 'array-contains-any', [
          profile!.uid,
          ...getAdminChatroomRoles(profile!),
        ])
        .where('lastMessage.system', '==', '')
        .where('type', '==', chatroomTypeFilter)
        .orderBy('lastMessage.createdAt', 'desc')
        .limit(CHATROOM_FETCH_LIMIT)
        .get();
    } else {
      querySnapshot = await db
        .collection('rooms')
        .where('members', 'array-contains-any', [
          profile!.uid,
          ...getAdminChatroomRoles(profile!),
        ])
        .where('lastMessage.system', '==', '')
        .orderBy('lastMessage.createdAt', 'desc')
        .limit(CHATROOM_FETCH_LIMIT)
        .get();
    }

    const incomingChatrooms = await formatChatroomList(
      profile!.uid,
      querySnapshot,
    );
    if (incomingChatrooms.length < CHATROOM_FETCH_LIMIT) {
      dispatch(setIsListEnd(true));
    }
    dispatch(refreshListSuccess(incomingChatrooms));
  } catch (error) {
    console.log(error);
    dispatch(refreshListFail());
  }
};

export const appendChatroomList = (scrollBar): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    // scroll a little bit before fetching more
    scrollBar.scrollTop = scrollBar.scrollTop - 50;
    dispatch(appendListStart());
    const {
      chatroomList,
      selectedEstate,
      chatroomTypeFilter,
    } = getState().chatRoom;
    const { profile } = getState().auth;
    const lastChatroom = await db
      .collection('rooms')
      .doc(chatroomList[chatroomList.length - 1].id)
      .get();
    let querySnapshot;
    if (
      profile!.PropertyManagerRoles &&
      profile!.PropertyManagerRoles.length > 0 &&
      selectedEstate
    ) {
      querySnapshot = await db
        .collection('rooms')
        .where('members', 'array-contains-any', [
          profile!.uid,
          `e${
            selectedEstate
              ? selectedEstate?.estateid
              : profile!.PropertyManagerRoles[0].estateid
          }`,
        ])
        .where('lastMessage.system', '==', '')
        .orderBy('lastMessage.createdAt', 'desc')
        .limit(CHATROOM_FETCH_LIMIT)
        .startAfter(lastChatroom)
        .get();
    } else if (chatroomTypeFilter) {
      querySnapshot = await db
        .collection('rooms')
        .where('members', 'array-contains-any', [
          profile!.uid,
          ...getAdminChatroomRoles(profile!),
        ])
        .where('lastMessage.system', '==', '')
        .where('type', '==', chatroomTypeFilter)
        .orderBy('lastMessage.createdAt', 'desc')
        .startAfter(lastChatroom)
        .limit(CHATROOM_FETCH_LIMIT)
        .get();
    } else {
      querySnapshot = await db
        .collection('rooms')
        .where('members', 'array-contains-any', [
          profile!.uid,
          ...getAdminChatroomRoles(profile!),
        ])
        .where('lastMessage.system', '==', '')
        .orderBy('lastMessage.createdAt', 'desc')
        .limit(CHATROOM_FETCH_LIMIT)
        .startAfter(lastChatroom)
        .get();
    }

    const incomingChatrooms = await formatChatroomList(
      profile!.uid,
      querySnapshot,
    );
    if (incomingChatrooms.length < CHATROOM_FETCH_LIMIT) {
      dispatch(setIsListEnd(true));
    }
    if (incomingChatrooms.length === 0) {
      dispatch(setIsListEnd(true));
    }
    dispatch(appendListSuccess(incomingChatrooms));
  } catch (error) {
    dispatch(appendListFail());
  }
};

export const handleNewChatrooms = (
  uid: string,
  querySnapshot: FirebaseQuerySnapshot,
): AppThunk => async dispatch => {
  try {
    const incomingChatrooms = await formatChatroomList(uid, querySnapshot);
    if (incomingChatrooms.length < CHATROOM_FETCH_LIMIT) {
      dispatch(setIsListEnd(true));
    }
    dispatch(setChatroomList(incomingChatrooms));
  } catch (error) {
    console.log(error);
  }
};

export const setChatroomAsRead = (roomid: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const { chatroomList } = getState().chatRoom;
  const foundIndex = _.findIndex(chatroomList, { id: roomid });
  if (foundIndex !== -1) {
    const temp = chatroomList.slice();
    temp[foundIndex] = {
      ...temp[foundIndex],
      read: true,
    };
    dispatch(setChatroomList(temp));
  }
  await markRoomAsRead(roomid);
};

export const dispatchSearchChatByUsername = (
  keyword: string,
  selectedEstate?: Estate<Building> | null,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setSearchChatroomList([]));
    dispatch(setListRefreshing(true));
    const { auth } = getState();
    const { profile } = auth;
    const RoomPara: RoomRequest = {
      q: keyword,
    };
    if (selectedEstate) {
      RoomPara.estateid = selectedEstate.estateid;
    } else {
      delete RoomPara.estateid;
    }
    const roomUids = await searchRoomByUsername(RoomPara);
    let combinedChatrooms: Chatroom[] = [];
    if (roomUids.length > 0) {
      var i,
        j,
        temporary,
        chunk = 10;
      for (i = 0, j = roomUids.length; i < j; i += chunk) {
        temporary = roomUids.slice(i, i + chunk);
        const querySnapshot = await db
          .collection('rooms')
          .where('__name__', 'in', temporary)
          .limit(CHATROOM_FETCH_LIMIT)
          .get();
        const incomingChatrooms = await formatChatroomList(
          profile!.uid,
          querySnapshot,
        );
        combinedChatrooms = [...combinedChatrooms, ...incomingChatrooms];
      }
      dispatch(setSearchChatroomList(combinedChatrooms));
      dispatch(setListRefreshing(false));
    } else {
      dispatch(setSearchChatroomList([]));
      dispatch(setListRefreshing(false));
    }
  } catch (error) {
    dispatch(setListRefreshing(false));
    console.log(error);
  }
};
