import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
  Media,
} from 'reactstrap';
import { Table, Pagination } from 'app/components/Table';
import CaseListColumn from './CaseListColumn';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { InputField, InputGroupField, SelectField } from 'app/components/Form';
import moment from 'moment';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import {
  getOneCommission,
  updateOneCommissionByProviderCompanyid,
} from 'app/services/CommissionServices';

import {
  getServiceMatcingCaseListByCompanyid,
  GetServiceMatcingCaseListByCompanyidParams,
} from 'app/services/ServiceMatchingServices';

import { changePreloader } from 'store/Layout';
import Dialog from 'app/components/Modal/Modal';
import { getImageURL } from 'app/helpers/CommonHelper';
import {
  ServiceProvider,
  COMMISSION_STATUS,
  ServiceMatchingCase,
} from 'app/models';
import { API_URL, PAGE_SIZE } from 'app/config';

interface CommissionForm {
  commissionid?: number | null;
  providerCompanyid: number | null;
  ProviderCompany: ServiceProvider | null;
  numberOfCases: number;
  totalAmount: number;
  commission: number;
  startDate: string;
  endDate: string;
  remarks: string;
  status: string;
}

const FORM_ITEM = {
  providerCompanyid: null,
  ProviderCompany: null,
  numberOfCases: 0,
  totalAmount: 0,
  commission: 0,
  startDate: '',
  endDate: '',
  remarks: '',
  status: '',
};

const Schema = Yup.object().shape({
  status: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  remarks: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .nullable(),
});

interface CommissionFormQuery {
  providerCompanyid?: string;
  commissionid?: string;
}

export function CommissionFormPage() {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<CommissionFormQuery>();
  const [form, setForm] = useState<CommissionForm>(FORM_ITEM);
  const [commissionid, setCommissionid] = useState<number | null>(null);
  const [caseList, setCaseList] = useState<ServiceMatchingCase[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isDisable] = useState<boolean>(true);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { commissionid: commissionID } = params;

    try {
      if (commissionID) {
        const commissionRes = await getOneCommission(commissionID);
        const {
          commissionid,
          providerCompanyid,
          ProviderCompany,
          numberOfCases,
          totalAmount,
          commission,
          startDate,
          endDate,
          remarks,
          status,
          createdAt,
          updatedAt,
        } = commissionRes;
        const form = {
          commissionid,
          providerCompanyid,
          ProviderCompany,
          numberOfCases,
          totalAmount,
          commission,
          startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
          endDate: moment(endDate).format('YYYY-MM-DD'),
          remarks,
          status,
          createdAt: createdAt
            ? moment(createdAt).format('YYYY-MM-DDTHH:mm:ss')
            : '',
          updatedAt: updatedAt
            ? moment(updatedAt).format('YYYY-MM-DDTHH:mm:ss')
            : '',
        };
        if (providerCompanyid) {
          const tmpFilter: GetServiceMatcingCaseListByCompanyidParams = {
            limit: PAGE_SIZE,
            offset: 0,
            startDate: startDate,
            endDate: endDate,
            status: 'Completed',
          };
          const caseRes = await getServiceMatcingCaseListByCompanyid(
            providerCompanyid,
            tmpFilter,
          );
          setCaseList(caseRes.rows);
          setCount(caseRes.count);
          setStartDate(startDate);
          setEndDate(endDate);
          setIsCompleted(status === 'Settled');
        }

        setCommissionid(commissionRes.commissionid);
        setForm(form);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const pageUpdate = useCallback(async () => {
    if (form && form.providerCompanyid) {
      const tmpFilter: GetServiceMatcingCaseListByCompanyidParams = {
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        startDate: startDate,
        endDate: endDate,
        status: 'Completed',
      };
      const caseRes = await getServiceMatcingCaseListByCompanyid(
        form.providerCompanyid,
        tmpFilter,
      );
      setCaseList(caseRes.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, page]);

  useEffect(() => {
    pageUpdate();
  }, [page, pageUpdate]);

  const onSubmit = async (values: CommissionForm) => {
    setLoading(true);
    const {
      providerCompanyid,
      ProviderCompany,
      numberOfCases,
      totalAmount,
      commission,
      startDate,
      endDate,
      remarks,
      status,
    } = values;
    const params = {
      providerCompanyid,
      ProviderCompany,
      numberOfCases,
      totalAmount,
      commission,
      startDate,
      endDate,
      remarks,
      status,
    };
    try {
      if (commissionid) {
        await updateOneCommissionByProviderCompanyid(
          providerCompanyid!.toString(),
          commissionid!.toString(),
          params,
        );
        setLoading(false);
        toast.success('編輯佣金成功。');
        history.go(0);
      } else {
        // await createOneCommission(params);
        setLoading(false);
        toast.success('新增佣金成功。');
        // history.push('/commission');
      }
    } catch (err) {
      setLoading(false);
      toast.warning(`${commissionid ? '編輯' : '新增'}佣金失敗，請重試。`);
      console.log(err);
    }
  };

  const deleteOnClose = () => {
    setDeleteVisible(false);
  };

  const deleteConfirm = async () => {
    setDeleteLoading(false);
    try {
      // await deleteOneCommission(commissionid!);
      toast.success('成功刪除佣金');
      setDeleteLoading(false);
      setDeleteVisible(false);
      history.replace('/commission');
    } catch (err) {
      toast.warning('刪除佣金失敗，請重試。');
      setDeleteLoading(false);
      setDeleteVisible(false);
    }
  };

  const TITLE = `${commissionid ? '編輯' : '新增'}佣金`;
  const BreadcrumbsOptions = [
    { title: '管理佣金', path: '/commission' },
    { title: '佣金列表', path: '/commission' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{TITLE} | Easy Living</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="管理佣金" breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              {!isPreloader ? (
                <div className="p-2">
                  <Formik
                    initialValues={form}
                    validationSchema={Schema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form className="form-horizontal">
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <Row className="mb-3">
                                  <Col xs={6}>
                                    <CardTitle>公司資料</CardTitle>
                                  </Col>
                                </Row>
                                {values.ProviderCompany ? (
                                  <>
                                    <Media>
                                      <div className="ms-3 align-self-center">
                                        {!values.ProviderCompany.avatar ? (
                                          <div className="avatar-lg">
                                            <span className="avatar-title rounded-circle bg-info">
                                              {values.ProviderCompany
                                                .companyName ? (
                                                values.ProviderCompany.companyName.charAt(
                                                  0,
                                                )
                                              ) : (
                                                <i className="bx bx-user" />
                                              )}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="">
                                            <img
                                              className="rounded-circle avatar-lg"
                                              src={getImageURL(
                                                values.ProviderCompany.avatar,
                                                320,
                                              )}
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <Media
                                        body
                                        className="align-self-center ms-3"
                                      >
                                        <div className="text-muted p-2">
                                          <div className="d-flex">
                                            <h5>
                                              {values.ProviderCompany
                                                .companyName || '-'}{' '}
                                              <span className="text-info ms-2">
                                                {' '}
                                                {values.ProviderCompany
                                                  .providerCompanyid
                                                  ? `#${values.ProviderCompany.providerCompanyid} `
                                                  : '-'}
                                              </span>
                                            </h5>
                                          </div>
                                          <p className="mb-1">
                                            關聯公司:{' '}
                                            {values.ProviderCompany
                                              .isEasyLivingChildCompany
                                              ? '是'
                                              : '否'}
                                          </p>
                                          <p className="mb-1">
                                            聯絡電話:{' '}
                                            {values.ProviderCompany.phone
                                              ? values.ProviderCompany.phone
                                              : '-'}
                                          </p>
                                          <p className="mb-1">
                                            地址:{' '}
                                            {values.ProviderCompany.address
                                              ? values.ProviderCompany.address
                                              : '-'}
                                          </p>
                                        </div>
                                      </Media>
                                      <Media
                                        body
                                        className="align-self-center align-items-end ms-3"
                                      >
                                        <div className="d-flex flex-row-reverse">
                                          <Link
                                            className="ms-1 btn btn-warning font-size-14  py-1"
                                            to={`/serviceprovider/edit/${values.ProviderCompany.providerCompanyid}`}
                                          >
                                            查看
                                          </Link>
                                        </div>
                                      </Media>
                                    </Media>
                                  </>
                                ) : null}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="d-flex">
                          <Col lg={12} className="d-flex flex-column">
                            <Card style={{ flex: 1 }}>
                              <CardBody>
                                <Row>
                                  <Col xs={6}>
                                    <CardTitle>佣金資料</CardTitle>
                                  </Col>

                                  <Col
                                    xs={6}
                                    className="d-flex flex-row-reverse"
                                  >
                                    {isCompleted ? (
                                      <a
                                        className="ms-1 btn btn-outline-warning font-size-14  py-1"
                                        href={`${API_URL}/v1/servicematching/company/commission/${commissionid}/Invoice`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Invoice
                                      </a>
                                    ) : null}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <InputGroupField
                                      name="commissionid"
                                      label="佣金編號"
                                      placeholder=""
                                      type="number"
                                      colorType="primary"
                                      frontAddOntext="#"
                                      disabled={isDisable}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <InputField
                                      name="startDate"
                                      label="佣金開始日期"
                                      placeholder=""
                                      type="date"
                                      disabled={isDisable}
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <InputField
                                      name="endDate"
                                      label="佣金結束日期"
                                      placeholder=""
                                      type="date"
                                      disabled={isDisable}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <InputGroupField
                                      name="numberOfCases"
                                      label="案例數"
                                      placeholder=""
                                      type="number"
                                      colorType="primary"
                                      frontAddOntext=""
                                      disabled={isDisable}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <InputGroupField
                                      name="totalAmount"
                                      label="總金額"
                                      placeholder=""
                                      colorType="primary"
                                      frontAddOntext="$"
                                      disabled={isDisable}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <InputGroupField
                                      name="commission"
                                      label="佣金"
                                      placeholder=""
                                      type="number"
                                      colorType="primary"
                                      frontAddOntext="$"
                                      disabled={isDisable}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <SelectField
                                        name="status"
                                        label="狀態"
                                        placeholder="狀態"
                                        options={Object.keys(
                                          COMMISSION_STATUS,
                                        ).map(status => ({
                                          value:
                                            COMMISSION_STATUS[status].value,
                                          label:
                                            COMMISSION_STATUS[status].label,
                                        }))}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <InputField
                                        name="remarks"
                                        label="備註"
                                        placeholder="備註..."
                                        type="textarea"
                                        rows={5}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <InputField
                                      name="createdAt"
                                      label="創建時間"
                                      placeholder=""
                                      type="datetime-local"
                                      disabled={isDisable}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <InputField
                                      name="updatedAt"
                                      label="最後更新"
                                      placeholder=""
                                      type="datetime-local"
                                      disabled={isDisable}
                                    />
                                  </Col>
                                </Row>
                                <div className="mt-4 d-flex flex-row-reverse">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="ms-1"
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <i className="bx bx-loader-circle bx-spin" />
                                    ) : (
                                      '提交'
                                    )}
                                  </Button>
                                  {/* {commissionid ? (
                                    <Button
                                      type="button"
                                      color="danger"
                                      className="ms-1"
                                      onClick={() => setDeleteVisible(true)}
                                    >
                                      刪除
                                    </Button>
                                  ) : null} */}
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="d-flex">
                          <Col lg={12} className="d-flex flex-column">
                            <Card style={{ flex: 1 }}>
                              <CardBody>
                                <Row>
                                  <Col xs={6}>
                                    <CardTitle>佣金交易資料</CardTitle>
                                  </Col>
                                </Row>{' '}
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <Table
                                        columns={CaseListColumn()}
                                        keyField="serviceMatchingCaseId"
                                        data={caseList || []}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-3">
                                  <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                    <Pagination
                                      count={count}
                                      current={page}
                                      onChange={page => setPage(page)}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>

        <Dialog
          visible={deleteVisible}
          title="刪除佣金"
          onClose={deleteOnClose}
          loading={deleteLoading}
          onConfirm={deleteConfirm}
        >
          <div>確定要刪除佣金(編號{commissionid})?</div>
        </Dialog>
      </div>
    </>
  );
}
