import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';

import {
  BuildingParams,
  EstateParams,
  EstateType,
  LocationType,
} from '../../../models';

import { Formik, Form, FormikHelpers, FieldArray } from 'formik';
import * as Yup from 'yup';
import { InputField, SelectField, SwitchField } from 'app/components/Form';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import {
  createEstate,
  deleteOneEstate,
  getOneEstate,
  updateOneEstate,
} from 'app/services/EstateService';

import { changePreloader } from 'store/Layout';
import { getDistrictOption } from 'app/helpers/EstateHelper';
import Dialog from 'app/components/Modal/Modal';

const BUILDING_ITEM = {
  name: '',
  nameEng: '',
  extra: {},
  contactPhoneNum: '',
};
const FORM_ITEM = {
  region: 'hk',
  district: '',
  name: '',
  extra: {},
  EstateBuildings: [],
  code: '',
  type: '公' as EstateType,
  nameEng: '',
  officeNameChi: '',
  officeNameEng: '',
  officeAddressChi: '',
  officeAddressEng: '',
  contactPhoneNum: '',
  email: '',
  fax: '',
  isValidForAutoChecking: false,
};

const Schema = Yup.object().shape({
  region: Yup.string()
    .oneOf(['hk', 'kln', 'nt'], '地域有誤')
    .required('必填項目'),
  district: Yup.string().required('必填項目'),
  name: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .nullable()
    .required('必填項目'),
  code: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .nullable(),
  nameEng: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .nullable(),
  officeNameChi: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .nullable(),
  officeNameEng: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .nullable(),
  officeAddressChi: Yup.string()
    .min(2, '最少要2個字元')
    .max(200, '不可多於200個字元')
    .nullable(),
  officeAddressEng: Yup.string()
    .min(2, '最少要2個字元')
    .max(200, '不可多於200個字元')
    .nullable(),
  contactPhoneNum: Yup.string()
    .matches(/^[0-9]{8}$/, {
      message: '錯誤的電話格式。',
      excludeEmptyString: true,
    })
    .nullable(),
  fax: Yup.string()
    .matches(/^[0-9]{8}$/, {
      message: '錯誤的電話格式。',
      excludeEmptyString: true,
    })
    .nullable(),
  email: Yup.string()
    .email()
    .nullable()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元'),
  EstateBuildings: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .min(2, '最少要2個字元')
        .max(100, '不可多於100個字元')
        .nullable()
        .required('必填項目'),
      nameEng: Yup.string()
        .min(2, '最少要2個字元')
        .max(100, '不可多於100個字元')
        .nullable(),
      contactPhoneNum: Yup.string()
        .matches(
          /^(\+\852)?[\s.-]?[0-9]{4}[\s.-]?[0-9]{4}$|^(\+\86)[\s.-][0-9]{0,3}[\s.-]?[0-9]{4}[\s.-]?[0-9]{4}$/,
          {
            message: '錯誤的電話格式。',
            excludeEmptyString: true,
          },
        )
        .nullable(),
    }),
  ),
});

interface EstateFormQuery {
  estateid?: string;
}

export function EstateFormPage() {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<EstateFormQuery>();
  const [form, setForm] = useState<EstateParams<BuildingParams>>(FORM_ITEM);
  const [estateid, setEstateid] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { estateid: estateID } = params;
    try {
      if (estateID) {
        const estateRes = await getOneEstate(estateID);
        setEstateid(estateRes.estateid);
        const temp: EstateParams<BuildingParams> = {
          contactPhoneNum: estateRes.contactPhoneNum,
          district: estateRes.district,
          extra: estateRes.extra,
          name: estateRes.name,
          region: estateRes.region,
          code: estateRes.code,
          type: estateRes.type,
          nameEng: estateRes.nameEng,
          officeNameChi: estateRes.officeNameChi,
          officeNameEng: estateRes.officeNameEng,
          officeAddressChi: estateRes.officeAddressChi,
          officeAddressEng: estateRes.officeAddressEng,
          email: estateRes.email,
          fax: estateRes.fax,
          isValidForAutoChecking: estateRes.isValidForAutoChecking,
          EstateBuildings: estateRes.EstateBuildings.map(building => {
            return {
              contactPhoneNum: building.contactPhoneNum,
              extra: building.extra,
              name: building.name,
              nameEng: building.nameEng || '',
              estateBuildingid: building.estateBuildingid,
            };
          }),
        };
        setForm(temp);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const createNewEstate = async values => {
    setLoading(true);
    try {
      await createEstate(values);
      setLoading(false);
      toast.success('新增物業成功。');
      history.push('/estates');
    } catch (err) {
      setLoading(false);
      toast.warning('新增物業失敗，請重試。');
      console.log(err);
    }
  };

  const updateEstateInfo = async values => {
    setLoading(true);
    try {
      await updateOneEstate(estateid!, values);
      setLoading(false);
      toast.success('編輯物業成功。');
    } catch (err) {
      setLoading(false);
      toast.warning('編輯物業失敗，請重試。');
      console.log(err);
    }
  };

  const onSubmit = async (
    values: EstateParams<BuildingParams>,
    actions: FormikHelpers<EstateParams<BuildingParams>>,
  ) => {
    if (estateid) {
      updateEstateInfo(values);
    } else {
      createNewEstate(values);
    }
  };

  const deleteOnClose = () => {
    setDeleteVisible(false);
  };

  const deleteConfirm = async () => {
    setDeleteLoading(false);
    try {
      await deleteOneEstate(estateid!);
      toast.success('成功刪除物業');
      setDeleteLoading(false);
      setDeleteVisible(false);
      history.replace('/estates');
    } catch (err) {
      toast.warning('刪除物業失敗，請重試。');
      setDeleteLoading(false);
      setDeleteVisible(false);
    }
  };

  const TITLE = `${estateid ? '編輯' : '新增'}物業`;
  const BreadcrumbsOptions = [
    { title: '管理物業', path: '/estates' },
    { title: '物業列表', path: '/estates' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{TITLE} | Easy Living</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="管理物業" breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({
                          values,
                          handleBlur,
                          handleChange,
                          touched,
                          errors,
                          setFieldValue,
                        }) => (
                          <Form className="form-horizontal">
                            <CardTitle>物業資料</CardTitle>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <InputField
                                    name="name"
                                    label="物業名稱"
                                    placeholder="物業名稱"
                                    type="text"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <InputField
                                    name="nameEng"
                                    label="Property Name (選填)"
                                    placeholder="Property Name"
                                    type="text"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <InputField
                                    name="code"
                                    label="物業代號 (選填)"
                                    placeholder="物業代號"
                                    type="text"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <SelectField
                                    name="type"
                                    label="物業類型"
                                    placeholder="物業類型"
                                    type="text"
                                    options={[
                                      { label: '公營', value: '公' },
                                      { label: '私營', value: '私' },
                                    ]}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <SelectField
                                    name="region"
                                    label="地域"
                                    placeholder="地域"
                                    onChange={e => {
                                      setFieldValue('region', e.value);
                                      setFieldValue('district', '');
                                    }}
                                    options={Object.keys(LocationType).map(
                                      key => {
                                        return {
                                          value: key,
                                          label: LocationType[key],
                                        };
                                      },
                                    )}
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <SelectField
                                    name="district"
                                    label="地區"
                                    placeholder="地區"
                                    options={getDistrictOption(values.region)}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <SwitchField
                                    name="isValidForAutoChecking"
                                    label="可查詢裝修服務"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <hr />
                            <CardTitle>管理處資料</CardTitle>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <InputField
                                    name="officeNameChi"
                                    label="管理處名稱 (選填)"
                                    placeholder="管理處名稱"
                                    type="text"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <InputField
                                    name="officeNameEng"
                                    label="Name of Estate Office (選填)"
                                    placeholder="Name of Estate Office"
                                    type="text"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <InputField
                                    name="officeAddressChi"
                                    label="管理處地址 (選填)"
                                    placeholder="管理處地址"
                                    type="textarea"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <InputField
                                    name="officeAddressEng"
                                    label="Estate Office Address (選填)"
                                    placeholder="Estate Office Address"
                                    type="textarea"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  <InputField
                                    name="contactPhoneNum"
                                    label="聯絡電話(選填)"
                                    placeholder="聯絡電話"
                                    type="text"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <InputField
                                    name="email"
                                    label="屋苑電郵(選填)"
                                    placeholder="屋苑電郵"
                                    type="text"
                                  />
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <InputField
                                    name="fax"
                                    label="屋苑傳真號碼(選填)"
                                    placeholder="屋苑傳真號碼"
                                    type="text"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <hr />
                            <CardTitle>大廈資料</CardTitle>
                            <FieldArray
                              name="EstateBuildings"
                              render={arrayHelpers => (
                                <>
                                  {values.EstateBuildings &&
                                  values.EstateBuildings.length > 0 ? (
                                    <>
                                      {values.EstateBuildings.map(
                                        (building, index) => {
                                          return (
                                            <Row key={index}>
                                              <Col lg="4" xs="12">
                                                <InputField
                                                  name={`EstateBuildings.${index}.name`}
                                                  label="大廈名稱"
                                                  placeholder="大廈名稱"
                                                  type="text"
                                                />
                                              </Col>
                                              <Col lg="4" xs="12">
                                                <InputField
                                                  name={`EstateBuildings.${index}.nameEng`}
                                                  label="Building Name (選填)"
                                                  placeholder="Building Name"
                                                  type="text"
                                                />
                                              </Col>

                                              <Col lg="2" xs="12">
                                                <InputField
                                                  name={`EstateBuildings.${index}.contactPhoneNum`}
                                                  label="大廈聯絡電話(選填)"
                                                  placeholder="大廈聯絡電話"
                                                  type="text"
                                                />
                                              </Col>
                                              <Col
                                                lg="2"
                                                xs="12"
                                                className="d-flex align-items-end mb-2"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-light btn-circle"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                  aria-label="delete"
                                                >
                                                  <i className="bx bx-trash-alt" />
                                                </button>
                                              </Col>
                                            </Row>
                                          );
                                        },
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div className="h5">物業暫無大廈</div>
                                    </>
                                  )}
                                  <Button
                                    type="button"
                                    color="warning"
                                    className="ms-1 mt-3"
                                    onClick={() =>
                                      arrayHelpers.push(BUILDING_ITEM)
                                    }
                                  >
                                    <i className="bx bx-plus" />
                                    湊加大廈
                                  </Button>
                                </>
                              )}
                            />
                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                              {estateid ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  刪除
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Dialog
          visible={deleteVisible}
          title="刪除物業"
          onClose={deleteOnClose}
          loading={deleteLoading}
          onConfirm={deleteConfirm}
        >
          <div>確定要刪除物業(編號{estateid})?</div>
        </Dialog>
      </div>
    </>
  );
}
