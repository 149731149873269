import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BraftEditor, { EditorState } from 'braft-editor';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  InputField,
  ImageUploadField,
  DateField,
  HTMLField,
  SwitchField,
  SelectField,
} from 'app/components/Form';
import moment from 'moment';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import {
  createActivity,
  getOneActivity,
  deleteActivity,
  updateActivity,
  sendActivityNotification,
  getActivityCategory,
} from 'app/services/ActivityServices';

import { changePreloader } from 'store/Layout';
import Dialog from 'app/components/Modal/Modal';
import { formatBytes } from 'app/helpers/FormHelper';
import { uploadImage } from 'app/services/CommonService';
import { OptionItems } from 'app/models';

interface ActivityForm {
  title: string;
  coverImage: any;
  start: Date | Date[] | null;
  end: Date | Date[] | null;
  category: string | null;
  content: EditorState;
  phone: string;
  activityOpeningTime: string | null;
  activityClosingTime: string | null;
  isBanner: boolean;
  status: boolean;
}

const FORM_ITEM = {
  title: '',
  coverImage: null,
  start: null,
  end: null,
  category: null,
  content: null,
  phone: '',
  activityOpeningTime: null,
  activityClosingTime: null,
  isBanner: false,
  status: false,
};

const Schema = Yup.object().shape({
  title: Yup.string()
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  coverImage: Yup.mixed().required('請上載封面圖片'),
  start: Yup.date().nullable().required('必填項目'),
  end: Yup.date().nullable().required('必填項目'),
  category: Yup.string()
    .min(2, '最少要2個字元')
    .max(50, '不可多於50個字元')
    .nullable()
    .required('必填項目'),
  content: Yup.object().nullable().required('必填項目'),
  phone: Yup.string()
    .nullable()
    .matches(/^[0-9]{8}$/, {
      message: '錯誤的電話格式。',
      excludeEmptyString: true,
    }),
  activityOpeningTime: Yup.string()
    .nullable()
    .matches(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/, {
      message: '錯誤的時格格式。例如: 18:00',
      excludeEmptyString: true,
    }),
  activityClosingTime: Yup.string()
    .nullable()
    .test('compare-start-time', '結束時間有誤', (value, context) => {
      const { parent } = context;
      if (parent && parent.activityOpeningTime) {
        const tempDate = '01/01/1970';
        const startTitme = new Date(
          `${tempDate} ${parent.activityOpeningTime}`,
        );
        const endTime = new Date(`${tempDate} ${value}`);
        return endTime > startTitme;
      }
      return true;
    })
    .matches(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/, {
      message: '錯誤的時格格式。例如: 18:00',
      excludeEmptyString: true,
    }),
  isBanner: Yup.boolean().required('必填項目'),
});

interface EstateFormQuery {
  activityid?: string;
}

export function ActivityFormPage() {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<EstateFormQuery>();
  const [form, setForm] = useState<ActivityForm>(FORM_ITEM);
  const [activityid, setActivityid] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [notiVisible, setNotiVisible] = useState<boolean>(false);
  const [category, setCategory] = useState<OptionItems[]>([]);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { activityid: activityID } = params;
    try {
      const categoryRes = await getActivityCategory();
      setCategory(
        categoryRes.map(cat => ({
          value: cat,
          label: cat,
        })),
      );
      if (activityID) {
        const activityRes = await getOneActivity(activityID);
        const {
          title,
          activityEndDate,
          activityStartDate,
          category,
          content,
          contact,
          image,
          activityOpeningTime,
          activityClosingTime,
          isBanner,
          status,
        } = activityRes;
        const form = {
          title: title,
          coverImage: [
            {
              preview: image,
              name: `${title} image`,
            },
          ],
          start: [moment(activityStartDate).toDate()],
          end: [moment(activityEndDate).toDate()],
          activityOpeningTime: handleTimeFormat(activityOpeningTime),
          activityClosingTime: handleTimeFormat(activityClosingTime),
          category: category,
          content: content ? BraftEditor.createEditorState(content) : null,
          phone: contact.phone,
          isBanner,
          status: status === 'Published' ? true : false,
        };
        setActivityid(activityRes.activityid);
        setForm(form);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const handleTimeFormat = (time: string | null) => {
    if (time) {
      let str = time.split(':');
      return `${str[0]}:${str[1]}`;
    }
    return null;
  };

  const createNewActivity = async (values: ActivityForm) => {
    setLoading(true);
    const {
      title,
      start,
      end,
      category,
      content,
      phone,
      coverImage,
      activityOpeningTime,
      activityClosingTime,
      isBanner,
      status,
    } = values;
    const params = {
      title: title,
      image: '',
      activityStartDate: start ? moment(start[0]).format('YYYY-MM-DD') : null,
      activityEndDate: end ? moment(end[0]).format('YYYY-MM-DD') : null,
      category: category ? category : '',
      content: content ? content.toHTML() : null,
      contact: { phone },
      activityOpeningTime: activityOpeningTime ? activityOpeningTime : null,
      activityClosingTime: activityClosingTime ? activityClosingTime : null,
      isBanner,
      status: status ? 'Published' : 'Unpublished',
    };
    try {
      const formData = new FormData();
      if (coverImage) {
        formData.append('file', coverImage[0]);
      }
      const imageRes = await uploadImage(formData);
      if (imageRes) {
        params.image = imageRes.fileName;
      }
      await createActivity(params);
      setLoading(false);
      toast.success('新增活動成功。');
      history.push('/activities');
    } catch (err) {
      setLoading(false);
      toast.warning('新增活動失敗，請重試。');
      console.log(err);
    }
  };

  const updateActivityInfo = async (values: ActivityForm) => {
    setLoading(true);
    const {
      title,
      start,
      end,
      category,
      content,
      phone,
      coverImage,
      activityClosingTime,
      activityOpeningTime,
      isBanner,
      status,
    } = values;
    const params = {
      title: title,
      image: '',
      activityStartDate: start ? moment(start[0]).format('YYYY-MM-DD') : null,
      activityEndDate: end ? moment(end[0]).format('YYYY-MM-DD') : null,
      category: category ? category : '',
      content: content ? content.toHTML() : null,
      contact: { phone },
      activityOpeningTime: activityOpeningTime ? activityOpeningTime : null,
      activityClosingTime: activityClosingTime ? activityClosingTime : null,
      isBanner,
      status: status ? 'Published' : 'Unpublished',
    };
    try {
      if (coverImage && coverImage[0] && coverImage[0].size) {
        const formData = new FormData();
        formData.append('file', coverImage[0]);
        const imageRes = await uploadImage(formData);
        params.image = imageRes.fileName;
      } else {
        params.image = coverImage[0].preview;
      }
      await updateActivity(activityid!, params);
      setLoading(false);
      toast.success('編輯活動成功。');
    } catch (err) {
      setLoading(false);
      toast.warning('編輯活動失敗，請重試。');
      console.log(err);
    }
  };

  const onSubmit = async (values: ActivityForm) => {
    if (activityid) {
      updateActivityInfo(values);
    } else {
      createNewActivity(values);
    }
  };

  const deleteOnClose = () => {
    setDeleteVisible(false);
  };

  const deleteConfirm = async () => {
    setDeleteLoading(false);
    try {
      await deleteActivity(activityid!);
      toast.success('成功刪除活動');
      setDeleteLoading(false);
      setDeleteVisible(false);
      history.replace('/activities');
    } catch (err) {
      toast.warning('刪除活動失敗，請重試。');
      setDeleteLoading(false);
      setDeleteVisible(false);
    }
  };

  const onDrop = (file, setFieldValue) => {
    if (file[0].size > 1024 * 10000) {
      toast.warning('活動圖片不可大於10MB');
      return;
    }
    if (
      file[0].type === 'image/jpeg' ||
      file[0].type === 'image/jpg' ||
      file[0].type === 'image/png'
    ) {
      file[0]['preview'] = URL.createObjectURL(file[0]);
      file[0]['formattedSize'] = formatBytes(file[0].size);
      setFieldValue('coverImage', [file[0]]);
    } else {
      toast.warning('活動圖片只接受.jpg / .png檔案');
      return;
    }
  };

  const sendNotiConfirm = async () => {
    setLoading(true);
    if (activityid) {
      try {
        await sendActivityNotification(activityid);
        toast.success('成功推送廣告');
      } catch (err) {
        toast.warning('推送廣告失敗，請重試。');
      }
    }
    setLoading(false);
    setNotiVisible(false);
  };

  const TITLE = `${activityid ? '編輯' : '新增'}活動`;
  const BreadcrumbsOptions = [
    { title: '管理活動', path: '/activities' },
    { title: '活動列表', path: '/activities' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{TITLE} | Easy Living</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="管理活動" breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({ values, setFieldValue }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col xs={6}>
                                <CardTitle>活動資料</CardTitle>
                              </Col>
                              {activityid ? (
                                <Col
                                  xs={6}
                                  className="d-flex flex-row justify-content-end"
                                >
                                  <Button
                                    type="button"
                                    color="success"
                                    onClick={() => setNotiVisible(true)}
                                  >
                                    推送活動
                                  </Button>
                                </Col>
                              ) : null}
                            </Row>

                            <Row>
                              <Col lg="12">
                                <InputField
                                  name="title"
                                  label="活動名稱"
                                  placeholder="活動名稱"
                                  type="text"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <ImageUploadField
                                  name="coverImage"
                                  label="活動圖片"
                                  files={values.coverImage}
                                  onDrop={file => onDrop(file, setFieldValue)}
                                />
                              </Col>
                              <Col lg="12">
                                <div className="text-muted mb-3">
                                  圖片最佳顯示比例為16:9
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <DateField
                                  name="start"
                                  label="活動開始日期"
                                  placeholder="活動開始日期"
                                />
                              </Col>
                              <Col lg="6">
                                <DateField
                                  name="end"
                                  label="活動結束日期"
                                  placeholder="活動結束日期"
                                  minDate={values.start}
                                />
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <InputField
                                    name="activityOpeningTime"
                                    label="活動開始時間"
                                    placeholder="10:00"
                                    type="text"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <InputField
                                    name="activityClosingTime"
                                    label="活動結束時間"
                                    placeholder="22:00"
                                    type="text"
                                  />
                                </div>
                              </Col>
                            </Row> */}
                            <Row>
                              <Col lg="6" xs="12">
                                <div className="mb-3">
                                  <SelectField
                                    name="category"
                                    label="類別"
                                    placeholder="類別"
                                    options={category}
                                  />
                                </div>
                              </Col>
                              <Col lg="6" xs="12">
                                <div className="mb-3">
                                  <InputField
                                    name="phone"
                                    label="活動聯絡電話"
                                    placeholder="活動聯絡電話"
                                    type="text"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <SwitchField
                                name="isBanner"
                                label="置頂橫額"
                                placeholder="置頂橫額"
                                horizontal
                              />
                            </Row>
                            <Row className="mb-3">
                              <SwitchField
                                name="status"
                                label="公佈活動"
                                placeholder="公佈活動"
                                horizontal
                              />
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <HTMLField
                                    name="content"
                                    label="活動內容"
                                    placeholder="活動內容"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                              {activityid ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  刪除
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Dialog
          visible={notiVisible}
          title="推送廣告"
          onClose={() => setNotiVisible(false)}
          loading={loading}
          onConfirm={sendNotiConfirm}
          btnType="success"
        >
          <p>{`確定要推送廣告 ? 所有已安裝手機應用程式的用戶都會收到推送廣告。`}</p>
        </Dialog>
        <Dialog
          visible={deleteVisible}
          title="刪除活動"
          onClose={deleteOnClose}
          loading={deleteLoading}
          onConfirm={deleteConfirm}
        >
          <div>確定要刪除活動(編號{activityid})?</div>
        </Dialog>
      </div>
    </>
  );
}
