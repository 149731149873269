import Axios from './';
import { ListResult, User, UserFilter } from '../models';

export const getUserListData = async (params: UserFilter) => {
  const { data } = await Axios.get<ListResult<User>>('/user', { params });
  return data;
};

export const getOneUser = async (userid: string | number) => {
  const { data } = await Axios.get<User>(`/user/${userid}`);
  return data;
};

/**
 * Update one User (https://easy-living-dev.appicidea.com/doc/#/User/patch_v1_user__userid_)
 */

export const updateOneUser = async (
  userid: string | number,
  params: { phoneNumber: string },
) => {
  const { data } = await Axios.patch<string>(`/user/${userid}`, params);
  return data;
};

export const getUserByIds = async (userIds: number[]) => {
  const { data } = await Axios.get<User[]>('/user/getByIds', {
    params: {
      ids: userIds.join(','),
    },
  });
  return data;
};
