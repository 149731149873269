import Axios from './';
import {
  AdminLoginPayload,
  ListResult,
  Admin,
  AdminPara,
  Filter,
  PropertyManagerRolePara,
  LoginResponse,
} from '../models';

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<LoginResponse>('/admin/login', para);
  return data;
};

export interface GetAdminListParams extends Filter {
  displayName?: string;
  loginname?: string;
}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin', {
    params,
  });
  return data;
};

export const createNewAdmin = async (para: AdminPara) => {
  const { data } = await Axios.post<Admin>('/admin', para);
  return data;
};

export const getSingleAdmin = async (adminid: string) => {
  const { data } = await Axios.get<Admin>(`/admin/${adminid}`);
  return data;
};

export const updateAdmin = async (
  adminid: number,
  para: { displayName: string },
) => {
  const { data } = await Axios.patch<null>(`/admin/${adminid}`, para);
  return data;
};

export const updateAdminAvatar = async (
  adminid: number,
  para: { avatar: string },
) => {
  const { data } = await Axios.patch<null>(`/admin/${adminid}`, para);
  return data;
};

export const deleteAdmin = async (adminid: number) => {
  const { data } = await Axios.delete<string>(`/admin/${adminid}`);
  return data;
};

export const getProfile = async () => {
  const { data } = await Axios.get<Admin>('/admin/profile');
  return data;
};

export const resetAdminPassword = async (
  adminid: number,
  para: { password?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admin/${adminid}/resetPassword`,
    para,
  );
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/logout');
  return data;
};

export const updateOneManagementRole = async (
  adminid: number,
  params: PropertyManagerRolePara[],
) => {
  const { data } = await Axios.put<string>(
    `/admin/${adminid}/propertyManagerRoles`,
    params,
  );
  return data;
};

export const removeManagementRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/propertyManagerRoles`,
  );
  return data;
};

export const getOneAdminhouseCheckRole = async (adminid: number) => {
  const { data } = await Axios.get<string>(`/admin/${adminid}/HouseCheckRole`);
  return data;
};

export const updateOneAdminHouseCheckRole = async (
  adminid: number,
  params: { signatureImage: string; displayName: string },
) => {
  const { data } = await Axios.put<string>(
    `/admin/${adminid}/HouseCheckRole`,
    params,
  );
  return data;
};

export const deleteAdminHouseCheckRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/HouseCheckRole`,
  );
  return data;
};

export const getOneAdminRenovationRole = async (adminid: number) => {
  const { data } = await Axios.get<string>(
    `/admin/${adminid}/RenovationStaffRole`,
  );
  return data;
};

export const updateOneAdminRenovationRole = async (
  adminid: number,
  params: { displayName: string },
) => {
  const { data } = await Axios.put<string>(
    `/admin/${adminid}/RenovationStaffRole`,
    params,
  );
  return data;
};

export const deleteAdminRenovationRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/RenovationStaffRole`,
  );
  return data;
};

export const deleteSuperAdminRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/SuperAdminRole`,
  );
  return data;
};

export const deleteCSRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(`/admin/${adminid}/CSRole`);
  return data;
};

export const createCSRole = async (adminid: number) => {
  const { data } = await Axios.put<string>(`/admin/${adminid}/CSRole`);
  return data;
};

export const createSuperAdminRole = async (adminid: number) => {
  const { data } = await Axios.put<string>(`/admin/${adminid}/SuperAdminRole`);
  return data;
};

export const deleteAdminPropertyAgebtRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/propertyAgentRole`,
  );
  return data;
};

export const createEventManagerRole = async (adminid: number) => {
  const { data } = await Axios.put<string>(
    `/admin/${adminid}/EventManagerRole`,
  );
  return data;
};

export const deleteAdminEventManagerRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/EventManagerRole`,
  );
  return data;
};

// Update/Create One Admin Property Agent Role

export interface updateAdminPropertyAgebtRoleParams {
  signatureImage: string;
  displayName: string;
}

export const createAdminPropertyAgentRole = async (
  adminid: number,
  params: updateAdminPropertyAgebtRoleParams,
) => {
  const { data } = await Axios.put<string>(
    `/admin/${adminid}/propertyAgentRole`,
    params,
  );
  return data;
};
export const createSuperManagementRole = async (adminid: number) => {
  const { data } = await Axios.put<string>(
    `/admin/${adminid}/SuperPropertyManagerRole`,
  );
  return data;
};

export const deleteSuperManagementRole = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/SuperPropertyManagerRole`,
  );
  return data;
};

export const updateOneAdminPassword = async (para: {
  password?: string;
  oldPassword?: string;
}) => {
  const { data } = await Axios.post<string>(`/admin/changePassword`, para);
  return data;
};
