import Axios from './';
import {
  BSForm,
  BWForm,
  HouseCheck,
  HouseCheckParams,
  ListResult,
} from 'app/models';

export const getHouseCheckList = async (params: HouseCheckParams) => {
  const { data } = await Axios.get<ListResult<any>>('/housecheck', {
    params,
  });
  return data;
};

export const getBSForm = async (houseCheckid: string) => {
  const { data } = await Axios.get<BSForm>(
    `/housecheck/${houseCheckid}/bsform`,
  );
  return data;
};

export const getBWForm = async (houseCheckid: string) => {
  const { data } = await Axios.get<BWForm>(
    `/housecheck/${houseCheckid}/bwform`,
  );
  return data;
};

export const getOneHouseCheckCase = async (houseCheckid: string) => {
  const { data } = await Axios.get<HouseCheck>(`/housecheck/${houseCheckid}`);
  return data;
};
