import Axios from './index';
import { FileResponse } from 'app/models';

export const uploadImage = async (fd: any, config?: any) => {
  const { data } = await Axios.post<FileResponse>('/file/image', fd, {
    ...config,
    // 15s for image
    timeout: 15000,
  });
  return data;
};

export const uploadFile = async (fd: any, config?: any) => {
  const { data } = await Axios.post<FileResponse>('/file/file', fd, {
    ...config,
    // 15s for image
    timeout: 15000,
  });
  return data;
};
