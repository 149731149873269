import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';

import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface Props {
  label?: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange: (e) => void;
  isClearable?: boolean;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  value: OptionItems;
}

const PureSelectFilter = ({
  label,
  placeholder,
  options,
  onChange,
  isClearable,
  disabled,
  menuPlacement,
  value,
}: Props) => {
  return (
    <div className={clsx('mb-2', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <Select
        defaultValue={value}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        value={value}
        onChange={e => onChange(e)}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
      />
    </div>
  );
};

export default PureSelectFilter;
