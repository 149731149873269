import Axios from './index';
import {
  ListResult,
  ActivityFilter,
  ActivityParams,
  Activity,
} from '../models';

export const getActivityList = async (params: ActivityFilter) => {
  const { data } = await Axios.get<ListResult<Activity>>('/activity', {
    params,
  });
  return data;
};

export const createActivity = async (params: ActivityParams) => {
  const { data } = await Axios.post<string>('/activity', params);
  return data;
};

export const getOneActivity = async (activityid: string) => {
  const { data } = await Axios.get<Activity>(`/activity/${activityid}`);
  return data;
};

export const deleteActivity = async (activityid: number) => {
  const { data } = await Axios.delete(`/activity/${activityid}`);
  return data;
};

export const updateActivity = async (activityid: number, params) => {
  const { data } = await Axios.patch<string>(`/activity/${activityid}`, params);
  return data;
};

/**
 * Send Activity Noti by id (https://easy-living-dev.appicidea.com/doc/#/Activity/post_v1_activity__activityid__sendNotification)
 */

export const sendActivityNotification = async (activityid: number) => {
  const { data } = await Axios.post<string>(
    `/activity/${activityid}/sendNotification`,
  );
  return data;
};

/**
 * Get Activity Category (https://easy-living-dev.appicidea.com/doc/#/Activity/get_v1_activity_category)
 */

export const getActivityCategory = async () => {
  const { data } = await Axios.get<string[]>(`/activity/category`);
  return data;
};
