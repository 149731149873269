import React from 'react';
import moment from 'moment';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  ServiceMatchingCase,
  LocationType,
  HKIslandType,
  KowloonType,
  NTType,
  SerType,
  SERVICE_MATCHING_CASE_ROOM_TYPE,
  SERVICE_MATCHING_CASE_STATUS_COLOR,
} from 'app/models';
import clsx from 'clsx';

export const CaseListColumn = () => [
  {
    text: '個案編號',
    dataField: 'serviceMatchingCaseId',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {serviceMatcingCase.serviceMatchingCaseId}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '	地域及地區',
    dataField: 'district',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {LocationType[serviceMatcingCase.region] || ''}{' '}
            {serviceMatcingCase.region && serviceMatcingCase.district
              ? serviceMatcingCase.region === 'hk'
                ? HKIslandType[serviceMatcingCase.district]
                : serviceMatcingCase.region === 'kln'
                ? KowloonType[serviceMatcingCase.district]
                : NTType[serviceMatcingCase.district]
              : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '客戶名稱',
    dataField: 'name',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {serviceMatcingCase.User
              ? serviceMatcingCase.User.name
              : serviceMatcingCase.customerName
              ? serviceMatcingCase.customerName
              : '-'}
          </div>
        </h5>
      </>
    ),
  },

  {
    text: '服務類型',
    dataField: 'ServiceMatchingReport.service',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {serviceMatcingCase.ServiceMatchingReport &&
            serviceMatcingCase.ServiceMatchingReport.service
              ? SERVICE_MATCHING_CASE_ROOM_TYPE[
                  serviceMatcingCase.ServiceMatchingReport.room
                ] +
                ' ' +
                SerType[serviceMatcingCase.ServiceMatchingReport.service]
              : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '服務開始日期',
    dataField: 'caseStartTime',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {serviceMatcingCase.caseStartTime
              ? moment(serviceMatcingCase.caseStartTime).format(
                  'DD-MM-YYYY HH:mm:ss',
                )
              : '-'}{' '}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '服務完成日期',
    dataField: 'caseEndTime',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {serviceMatcingCase.caseEndTime
              ? moment(serviceMatcingCase.caseEndTime).format(
                  'DD-MM-YYYY HH:mm:ss',
                )
              : '-'}{' '}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '收費',
    dataField: 'ServiceMatchingReport.price',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <div className="text-dark">
          {serviceMatcingCase.ServiceMatchingReport &&
          serviceMatcingCase.ServiceMatchingReport.price ? (
            <Badge
              pill
              className={clsx(
                `badge-soft-${
                  SERVICE_MATCHING_CASE_STATUS_COLOR[serviceMatcingCase.status]
                }`,
                ' ms-1',
                ' p-1 ',
                'font-size-12',
              )}
            >
              ${serviceMatcingCase.ServiceMatchingReport.price}
            </Badge>
          ) : (
            '-'
          )}
        </div>
      </>
    ),
  },
  {
    text: '顧客輸入價錢',
    dataField: 'clientInputPrice',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {serviceMatcingCase.clientInputPrice || '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '評分',
    dataField: 'rating',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {serviceMatcingCase.ServiceMatchingReport &&
            serviceMatcingCase.ServiceMatchingReport.rating
              ? serviceMatcingCase.ServiceMatchingReport.rating
              : '-'}
          </div>
        </h5>
      </>
    ),
  },

  {
    text: '創建時間',
    dataField: 'createdAt',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(serviceMatcingCase.createdAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '最後更新',
    dataField: 'updatedAt',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(serviceMatcingCase.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  // {
  //   text: '工作報告創建時間',
  //   dataField: 'createdAt',
  //   sort: false,
  //   formatter: (serviceMatcingCase: ServiceMatchingCase) => (
  //     <>
  //       <h5 className="font-size-14 mb-1">
  //         <div className="text-dark">
  //           {serviceMatcingCase.ServiceMatchingReport &&
  //           serviceMatcingCase.ServiceMatchingReport.createdAt
  //             ? moment(
  //                 serviceMatcingCase.ServiceMatchingReport.createdAt,
  //               ).format('DD-MM-YYYY HH:mm:ss')
  //             : '-'}
  //         </div>
  //       </h5>
  //     </>
  //   ),
  // },
  // {
  //   text: '工作報告更新時間',
  //   dataField: 'updatedAt',
  //   sort: false,
  //   formatter: (serviceMatcingCase: ServiceMatchingCase) => (
  //     <>
  //       <h5 className="font-size-14 mb-1">
  //         <div className="text-dark">
  //           {serviceMatcingCase.ServiceMatchingReport &&
  //           serviceMatcingCase.ServiceMatchingReport.updatedAt
  //             ? moment(
  //                 serviceMatcingCase.ServiceMatchingReport.updatedAt,
  //               ).format('DD-MM-YYYY HH:mm:ss')
  //             : '-'}
  //         </div>
  //       </h5>
  //     </>
  //   ),
  // },

  {
    text: '',
    dataField: 'menu',
    sort: false,
    formatter: (serviceMatcingCase: ServiceMatchingCase) => (
      <>
        <div>
          <ul className="list-inline font-size-20 contact-links mb-0">
            <li className="list-inline-item px-2">
              <Link
                to={`/servicematching/detail/${serviceMatcingCase.serviceMatchingCaseId}`}
                id={'detail' + serviceMatcingCase.serviceMatchingCaseId}
              >
                <i className="bx bx-spreadsheet" />
                <UncontrolledTooltip
                  placement="top"
                  target={'detail' + serviceMatcingCase.serviceMatchingCaseId}
                >
                  查看服務配對個案
                </UncontrolledTooltip>
              </Link>
            </li>
          </ul>
        </div>
      </>
    ),
  },
];

export default CaseListColumn;
