import React, { useEffect, useState, useCallback } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb';
import { Table, Pagination } from 'app/components/Table';

import CommissionListColumns from './CommissionListColumns';
import {
  generateCommision,
  getCommissionList,
  GetCommissionListFilter,
} from 'app/services/CommissionServices';
import { getServiceProviderList } from 'app/services/ServiceProviderService';
import { API_URL, PAGE_SIZE } from 'app/config';
import { Commission, OptionItems, COMMISSION_STATUS_FILTER } from 'app/models';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AutoCompleteSelect,
  DatePickerFilter,
  SelectFilter,
} from 'app/components/Filter';
import Dialog from 'app/components/Modal/Modal';
import { Formik, Form } from 'formik';
import { SelectField } from 'app/components/Form';
import { toast } from 'react-toastify';

const MONTH = [
  { value: 0, label: '1月' },
  { value: 1, label: '2月' },
  { value: 2, label: '3月' },
  { value: 3, label: '4月' },
  { value: 4, label: '5月' },
  { value: 5, label: '6月' },
  { value: 6, label: '7月' },
  { value: 7, label: '8月' },
  { value: 8, label: '9月' },
  { value: 9, label: '10月' },
  { value: 10, label: '11月' },
  { value: 11, label: '12月' },
];

export const CommissionListPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [commissionList, setCommissionList] = useState<Commission[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const [companyList, setCompanyList] = useState<OptionItems[]>([]);
  const [companyText, setCompanyText] = useState<string | null>(null);
  const [filter, setFilter] = useState<GetCommissionListFilter | null>(null);

  const [generateModalVisible, setGenerateModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const getCommissions = useCallback(async () => {
    try {
      if (filter) {
        const res = await getCommissionList(filter);
        setCommissionList(res.rows);
        setCount(res.count);
      }
    } catch (err) {
      console.log(err);
    }
  }, [filter]);

  const getCompanyList = useCallback(async () => {
    try {
      const companyRes = await getServiceProviderList({
        limit: PAGE_SIZE,
        offset: 0,
        q: companyText ? companyText : '',
      });
      setCompanyList(
        companyRes.rows.map(company => {
          return {
            label: `#${company.providerCompanyid} | ${company.companyName}`,
            value: company.providerCompanyid,
          };
        }),
      );
    } catch (err) {
      console.error(err);
    }
  }, [companyText]);

  useEffect(() => {
    getCommissions();
    getCompanyList();
  }, [getCommissions, getCompanyList]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const p = params.get('page');
    let tempFilter: GetCommissionListFilter;
    if (filter) {
      tempFilter = { ...filter };
    } else {
      tempFilter = {
        limit: PAGE_SIZE,
        offset: 0,
      };
    }
    if (p) {
      tempFilter.offset = (parseInt(p) - 1) * PAGE_SIZE;
      setPage(parseInt(p));
    }
    const providerCompanyid = params.get('providerCompanyid');
    tempFilter.providerCompanyid = providerCompanyid
      ? providerCompanyid
      : undefined;
    const status = params.get('status');
    tempFilter.status = status ? status : undefined;
    const startDate = params.get('startDate');
    tempFilter.startDate = startDate ? startDate : undefined;
    const endDate = params.get('endDate');
    tempFilter.endDate = endDate ? endDate : undefined;
    setFilter(tempFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const companyOnChange = (option: OptionItems | null) => {
    const query = new URLSearchParams(location.search);
    if (option) {
      query.set('providerCompanyid', option.value as string);
      history.push({ search: query.toString() });
    } else {
      query.delete('providerCompanyid');
      history.push({ search: query.toString() });
    }
    setPage(1);
  };

  const companyScrollToBottom = async () => {
    let offset = 0;
    if (companyList.length === 0) {
      offset = 0;
    } else {
      offset = Math.ceil(companyList.length / PAGE_SIZE) * PAGE_SIZE;
    }
    try {
      const companyRes = await getServiceProviderList({
        limit: PAGE_SIZE,
        offset: offset,
        q: companyText ? companyText : '',
      });
      setCompanyList(prev => {
        return [
          ...prev.concat(
            companyRes.rows.map(company => {
              return {
                label: `#${company.providerCompanyid} | ${company.companyName}`,
                value: company.providerCompanyid,
              };
            }),
          ),
        ];
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getYears = () => {
    const yearOptions: OptionItems[] = [];
    for (let i = 2021; i <= new Date().getFullYear(); i++) {
      yearOptions.push({
        label: `${i}年`,
        value: i,
      });
    }
    return yearOptions;
  };

  const onSubmit = async values => {
    setLoading(true);
    try {
      await generateCommision(values);
      getCommissions();
      setGenerateModalVisible(false);
      setLoading(false);
      toast.success('成功計算佣金。');
    } catch (err) {
      console.log(err);
      toast.warning('計算佣金失敗，請重試。');
      setLoading(false);
    }
  };

  const getExportParams = () => {
    const tempParams = {
      ...filter,
    };
    const object: any = Object.keys(tempParams).reduce((map, key) => {
      if (tempParams[key]) {
        map[key] = tempParams[key];
      }
      return map;
    }, {});
    delete object.limit;
    delete object.offset;
    if (Object.keys(object).length > 0) {
      return `?${new URLSearchParams(object).toString()}`;
    } else {
      return '';
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>佣金列表 | Easy Living</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="佣金管理" breadcrumbItem="佣金列表" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <>
                    <Row className="mb-2">
                      <Col sm={3}>
                        <AutoCompleteSelect
                          label="服務供應商"
                          name="providerCompanyid"
                          placeholder="搜尋服務供應商..."
                          options={companyList}
                          onChange={companyOnChange}
                          onMenuScrollToBottom={companyScrollToBottom}
                          onInputChange={text => setCompanyText(text)}
                          isClearable
                        />
                      </Col>
                      <Col lg={3}>
                        <SelectFilter
                          label="狀態"
                          placeholder="狀態..."
                          name="status"
                          options={Object.keys(COMMISSION_STATUS_FILTER).map(
                            status => ({
                              value: COMMISSION_STATUS_FILTER[status].value,
                              label: COMMISSION_STATUS_FILTER[status].label,
                            }),
                          )}
                        />
                      </Col>
                      <Col sm={2}>
                        <DatePickerFilter
                          label="佣金開始日期"
                          name="startDate"
                          placeholder="佣金開始日期"
                        />
                      </Col>
                      <Col lg={2}>
                        <DatePickerFilter
                          label="佣金結束日期"
                          placeholder="佣金結束日期"
                          name="endDate"
                        />
                      </Col>
                      <Col
                        lg={2}
                        className="d-flex flex-row align-items-end justify-content-end mb-2"
                      >
                        <a
                          className="btn btn-warning me-2"
                          href={`${API_URL}/v1/servicematching/company/commission/export${getExportParams()}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          下載佣金列表
                        </a>
                        <button
                          className="btn btn-info"
                          onClick={() => {
                            setGenerateModalVisible(true);
                          }}
                        >
                          計算佣金
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={CommissionListColumns()}
                            keyField="commissionid"
                            data={commissionList || []}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination count={count} current={page} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {generateModalVisible ? (
          <Dialog
            visible={generateModalVisible}
            title="計算佣金"
            onClose={() => {
              setGenerateModalVisible(false);
            }}
            size="lg"
          >
            <Formik
              initialValues={form}
              validationSchema={null}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form
                  className="form-horizontal"
                  style={{ paddingBottom: 200 }}
                >
                  <SelectField
                    name="month"
                    label="月份"
                    placeholder="選擇月份"
                    options={MONTH}
                  />

                  <SelectField
                    name="year"
                    label="年份"
                    placeholder="選擇年份"
                    options={getYears()}
                  />

                  <div className="mt-3 d-flex flex-row-reverse">
                    <Button
                      type="submit"
                      color="primary"
                      className="ms-1"
                      disabled={loading}
                    >
                      {loading ? (
                        <i className="bx bx-loader-circle bx-spin" />
                      ) : (
                        '提交'
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dialog>
        ) : null}
      </div>
    </>
  );
};
