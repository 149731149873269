import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface Props {
  label?: string;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (value: OptionItems) => void;
  onMenuScrollToBottom?: () => void;
  onMenuClose?: () => void;
  onInputChange: (e) => void;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
  isClearable?: boolean;
}

const AutoCompleteSelect = ({
  label,
  placeholder,
  options,
  onChange,
  onMenuScrollToBottom,
  onMenuClose,
  onInputChange,
  disabled,
  menuPlacement,
  name,
  isClearable,
}: Props) => {
  const location = useLocation();
  const [value, setValue] = useState<OptionItems | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    // eslint-disable-next-line eqeqeq
    const index = _.findIndex(options, option => option.value == key);
    if (index > -1) {
      setValue(options[index]);
    } else {
      setValue(null);
    }
  }, [location.search, name, options]);

  const searchOnChange = text => {
    setInputValue(text);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(_.debounce(onInputChange, 500), []);

  useEffect(() => {
    debounceSearch(inputValue);
  }, [inputValue, debounceSearch]);

  return (
    <div className={clsx('mb-2', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <Select
        defaultValue={value}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        value={value}
        onChange={e => {
          if (onChange) {
            onChange(e);
          }
        }}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onMenuClose={onMenuClose}
        captureMenuScroll={true}
        onInputChange={searchOnChange}
        inputValue={inputValue}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
        isClearable={isClearable}
      />
    </div>
  );
};

export default AutoCompleteSelect;
