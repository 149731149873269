import Axios from './index';
import { ListResult, Commission, Filter } from '../models';
export interface GetCommissionListFilter extends Filter {
  status?: string;
  providerCompanyid?: string;
  startDate?: string;
  endDate?: string;
}
export const getCommissionList = async (params: GetCommissionListFilter) => {
  const { data } = await Axios.get<ListResult<Commission>>(
    '/servicematching/company/commission',
    {
      params,
    },
  );
  return data;
};

export const getOneCommission = async (commissionid: string) => {
  const { data } = await Axios.get<Commission>(
    `/servicematching/company/commission/${commissionid}`,
  );
  return data;
};

// export const deleteOneCommission = async (commissionid: number) => {
//   const { data } = await Axios.delete(`/commission/${commissionid}`);
//   return data;
// };

export interface UpdateCommissionParam {
  status?: string;
  remarks?: string;
}
export const updateOneCommission = async (
  commissionid: number,
  params: UpdateCommissionParam,
) => {
  const { data } = await Axios.patch<string>(
    `/servicematching/company/commission/${commissionid}`,
    params,
  );
  return data;
};

export const getCommissionListByProviderCompanyid = async (
  providerCompanyid: string,
  params: GetCommissionListFilter,
) => {
  const { data } = await Axios.get<Commission>(
    `/servicematching/company/${providerCompanyid}/commission`,
  );
  return data;
};

export const getOneCommissionByProviderCompanyid = async (
  providerCompanyid: string,
  commissionid: string,
) => {
  const { data } = await Axios.get<Commission>(
    `/servicematching/company/${providerCompanyid}/commission/${commissionid}`,
  );
  return data;
};
export const updateOneCommissionByProviderCompanyid = async (
  providerCompanyid: string,
  commissionid: string,
  params: UpdateCommissionParam,
) => {
  const { data } = await Axios.patch<string>(
    `/servicematching/company/${providerCompanyid}/commission/${commissionid}`,
    params,
  );
  return data;
};

/**
 * Generate Commission, Super Admin/ CS Only (https://easy-living-dev.appicidea.com/doc/#/Service%20Matching/post_v1_servicematching_company_commission_generate)
 */

export const generateCommision = async (params: {
  year: number;
  month: number;
}) => {
  const { data } = await Axios.post<any>(
    '/servicematching/company/commission/generate',
    params,
  );
  return data;
};
