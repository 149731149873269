import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import { Input } from 'reactstrap';

import { Label } from 'reactstrap';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  prefix?: string;
  allowToViewPassword?: boolean;
}

const InputField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const {
    label,
    type,
    placeholder,
    rows,
    horizontal,
    disabled,
    prefix,
    allowToViewPassword,
  } = props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
        horizontal && 'float-start',
      )}
    >
      {label ? <Label>{props.label}</Label> : null}
      <div className="d-flex flex-row">
        {prefix ? (
          <div className="d-flex align-items-center text-nowrap me-2">
            {prefix}
          </div>
        ) : null}
        <Input
          // className="form-control"
          {...field}
          placeholder={disabled ? '' : placeholder}
          type={passwordVisible ? 'text' : type}
          autoComplete="new-password"
          rows={rows}
          disabled={disabled}
        />
        {allowToViewPassword ? (
          <button
            className="btn btn-light ms-1"
            type="button"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? (
              <i className="mdi mdi-eye-off-outline" />
            ) : (
              <i className="mdi mdi-eye-outline" />
            )}
          </button>
        ) : null}
      </div>

      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default InputField;
