import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import { Input, Row, Col } from 'reactstrap';
import { Table, Pagination } from 'app/components/Table';
import { Label } from 'reactstrap';

type InputType = 'number' | 'text';

interface OtherProps {
  name: string;
  label: string;
  columns: any[];
  data: any[];
  keyField: string;
  keyFieldForTable: string;
  displayField: string;
  returnType?: InputType;
  placeholder?: string;
  disabled?: boolean;
  paginationCount: number;
  paginationPage: number;
  paginationOnChange: (page: any) => void;
}

const TableSelectField = (props: OtherProps & FieldHookConfig<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);
  const {
    label,
    name,
    disabled,
    columns,
    data,
    keyField,
    paginationCount,
    paginationPage,
    paginationOnChange,
  } = props;
  const processedColumns = [
    {
      text: '選取',
      dataField: 'menu',
      sort: false,
      formatter: (data: any) => (
        <>
          <div>
            <ul className="list-inline font-size-20 contact-links mb-0">
              <li className="list-inline-item">
                <Input
                  name={name}
                  type="radio"
                  value={data[keyField]}
                  style={{ height: '1.1em', width: '1.1em' }}
                  disabled={disabled}
                  onChange={e => {
                    helper.setValue(e.target.value);
                  }}
                />
              </li>
            </ul>
          </div>
        </>
      ),
    },
    ...columns,
  ];

  return (
    <>
      <Row className={clsx(meta.touched && meta.error && 'text-danger')}>
        <div>{label ? <Label>{props.label}</Label> : null}</div>
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <Table
                columns={processedColumns}
                keyField={keyField}
                data={data || []}
              />
            </div>
          </Col>
        </Row>
        <Row className="align-items-md-center my-3">
          <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
            <Pagination
              count={paginationCount}
              current={paginationPage}
              onChange={paginationOnChange}
            />
          </Col>
        </Row>
        {meta.touched && meta.error && (
          <div className="text-danger">{meta.error}</div>
        )}
      </Row>
    </>
  );
};

export default TableSelectField;
