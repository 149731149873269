import React from 'react';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Activity } from 'app/models';
import { renderActivityStatusBadge } from 'app/helpers/ActivityHelper';

const ActivityListColumns = () => [
  {
    text: '活動名稱',
    dataField: 'title',
    sort: false,
    formatter: (activity: Activity) => (
      <>
        <div style={{ minWidth: 200 }}>
          <h5 className="font-size-14 mb-1 d-flex flex-row text-wrap">
            <div className="text-dark">
              {activity.title}
              {activity.isBanner ? (
                <div className="ms-1 badge-soft-warning badge">
                  <div className="mx-1 font-size-12">置頂橫額</div>
                </div>
              ) : null}
            </div>
          </h5>
        </div>
      </>
    ),
  },
  {
    text: '狀態',
    dataField: 'status',
    sort: false,
    formatter: (activity: Activity) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {renderActivityStatusBadge(activity.status)}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '活動日期',
    dataField: 'activityStartDate',
    sort: false,
    formatter: (activity: Activity) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {`${moment(activity.activityStartDate).format(
              'DD/MM/YY',
            )} - ${moment(activity.activityEndDate).format('DD/MM/YY')}`}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '活動類別',
    dataField: 'category',
    sort: false,
    formatter: (activity: Activity) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{activity.category}</div>
        </h5>
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    sort: false,
    formatter: (activity: Activity) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(activity.createdAt).format('DD-MM-YYYY')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (activity: Activity) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <Link
            to={`/activities/edit/${activity.activityid}`}
            id={'edit' + activity.activityid}
          >
            <i className="bx bx-edit-alt" />
            <UncontrolledTooltip
              placement="top"
              target={'edit' + activity.activityid}
            >
              編輯活動
            </UncontrolledTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default ActivityListColumns;
