import { Admin, ChatroomType } from 'app/models/';
import { Badge } from 'reactstrap';

export const renderChatRoomTypeBadge = (type: ChatroomType) => {
  switch (type) {
    case 'cs':
      return <Badge className="font-size-11 badge-soft-success">客服</Badge>;
    case 'selllease':
      return <Badge className="font-size-11 badge-soft-primary">買賣</Badge>;
    case 'housecheck':
      return <Badge className="font-size-11 badge-soft-danger">驗樓</Badge>;
    case 'property':
      return <Badge className="font-size-11 badge-soft-secondary">物管</Badge>;
    case 'renovation':
      return <Badge className="font-size-11 badge-soft-info">裝修</Badge>;
    case 'serviceMatching':
      return (
        <Badge className="font-size-11 badge-soft-warning">家居維修</Badge>
      );
    default:
      return type;
  }
};

export const getAdminChatroomRoles = (admin: Admin): string[] => {
  const roles: string[] = [];
  if (admin.ServiceProviderRole && admin.ServiceProviderRole.type !== 'Staff') {
    roles.push('s' + admin.ServiceProviderRole.providerCompanyid);
  }
  if (admin.PropertyAgentRole) {
    roles.push('agent');
  }
  if (admin.SuperAdminRole) {
    roles.push('admin');
  }
  if (admin.CSRole) {
    roles.push('cs');
  }
  if (admin.HouseCheckRole) {
    roles.push('hc');
  }
  if (admin.SuperPropertyManagerRole) {
    roles.push('spm');
  }
  if (admin.PropertyManagerRoles && admin.PropertyManagerRoles.length > 0) {
    roles.push(`e${admin.PropertyManagerRoles[0].estateid}`);
  }
  if (admin.RenovationStaffRole) {
    roles.push('renovation');
  }
  return roles;
};
