import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AdminRolesType, AdminRolesMap } from 'app/models';

interface PermissionState {}

const initialState = {} as PermissionState;

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
});

export default permissionSlice.reducer;

export const useAdminPermission = () => {
  const { auth } = useSelector((rootState: RootState) => rootState);

  const { profile } = auth;

  const hasPermission = (permissions: AdminRolesType[]): boolean => {
    let access = false;
    if (profile) {
      permissions.forEach(permission => {
        if (
          permission === 'property-manager-主任' ||
          permission === 'property-manager-經理'
        ) {
          const { PropertyManagerRoles } = profile;
          if (PropertyManagerRoles && PropertyManagerRoles.length > 0) {
            const { type } = PropertyManagerRoles[0];
            if (permission === 'property-manager-主任' && type === '物業主任') {
              access = true;
            }
            if (permission === 'property-manager-經理' && type === '物業經理') {
              access = true;
            }
          }
        } else {
          if (profile[AdminRolesMap[permission]]) {
            access = true;
          }
        }
      });
    }
    return access;
  };

  return { hasPermission };
};
