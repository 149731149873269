import Axios from './index';
import {
  Filter,
  ListResult,
  RegionType,
  DistrictType,
  ServiceProvider,
  serviceType,
} from '../models';

/**
* * Get Service Provider (https://easy-living-dev.appicidea.com/doc/#/Admin/get_v1_servicematching_company)
+*/
export interface GetServiceProviderListParams extends Filter {
  companyName?: string;
  q?: string;
  isAvaliable?: boolean;
}

export const getServiceProviderList = async (
  params: GetServiceProviderListParams,
) => {
  const { data } = await Axios.get<ListResult<ServiceProvider>>(
    '/servicematching/company',
    {
      params,
    },
  );
  return data;
};

/**
* * Create Service Provider (https://easy-living-dev.appicidea.com/doc/#/Admin/post_v1_servicematching_company)
+*/

export interface CreateServiceProviderParams {
  region?: RegionType;
  district?: DistrictType;
  companyName: string;
  concurrentTaskNos?: number;
  isEasyLivingChildCompany: boolean;
  CompanyServices: { service: serviceType }[];
  avatar: string | null;
  phone: string;
  address: string;
  CompanyRegions: { region: string; district: string }[];
  weighting: number | null;
}

export const createServiceProvider = async (
  params: CreateServiceProviderParams,
) => {
  const { data } = await Axios.post<string>('/servicematching/company', params);
  return data;
};

/**
* * Get one Service Provider (https://easy-living-dev.appicidea.com/doc/#/Admin/get_v1_servicematching_company__providerCompanyid_)
+*/

export const getOneServiceProvider = async (providerCompanyid: string) => {
  const { data } = await Axios.get<ServiceProvider>(
    `/servicematching/company/${providerCompanyid}`,
  );
  return data;
};

/**
* * Delete one Service Provider (https://easy-living-dev.appicidea.com/doc/#/Admin/delete_v1_servicematching_company__providerCompanyid_)
+*/

export const deleteOneServiceProvider = async (providerCompanyid: number) => {
  const { data } = await Axios.delete<string>(
    `/servicematching/company/${providerCompanyid}`,
  );
  return data;
};

/**
* * Update one Service Provider (https://easy-living-dev.appicidea.com/doc/#/Admin/patch_v1_servicematching_company__providerCompanyid_)
+*/

export const updateOneServiceProvider = async (
  providerCompanyid: number,
  params: CreateServiceProviderParams,
) => {
  const { data } = await Axios.patch<string>(
    `/servicematching/company/${providerCompanyid}`,
    params,
  );
  return data;
};

/**
* * Get Service Provider Staff List (https://easy-living-dev.appicidea.com/doc/#/Admin/get_v1_servicematching_company__providerCompanyid__admins)
+*/

export interface GetServiceProviderAdminListParams extends Filter {}

export const getServiceProviderStaffList = async (
  providerCompanyid: number,
  params: GetServiceProviderAdminListParams,
) => {
  const { data } = await Axios.get<ListResult<any>>(
    `/servicematching/company/${providerCompanyid}/admins`,
    { params },
  );
  return data;
};

/**
* * Delete all Service Provider Staff (https://easy-living-dev.appicidea.com/doc/#/Admin/delete_v1_servicematching_company__providerCompanyid__admins)
+*/

export const deleteAllServiceProviderStaffList = async (
  providerCompanyid: number,
) => {
  const { data } = await Axios.delete<string>(
    `/servicematching/company/${providerCompanyid}/admins`,
  );
  return data;
};

/**
* * Create/Update One Service Provider Staff (https://easy-living-dev.appicidea.com/doc/#/Admin/put_v1_admin__adminid__ServiceProviderRole)
+*/

export const updateOneServiceProviderStaff = async (
  adminid: number,
  params: {
    displayName: string;
    providerCompanyid: number;
    type: string;
  },
) => {
  const { data } = await Axios.put<string>(
    `/admin/${adminid}/ServiceProviderRole`,
    params,
  );
  return data;
};

/**
* * Get One Service Provider Staff Detail (https://easy-living-dev.appicidea.com/doc/#/Admin/get_v1_admin__adminid__ServiceProviderRole)
+*/

export const getOneServiceProviderStaffDetail = async (adminid: number) => {
  const { data } = await Axios.get<any>(
    `/admin/${adminid}/ServiceProviderRole`,
  );
  return data;
};

/**
* * Delete One Service Provider Staff Detail (https://easy-living-dev.appicidea.com/doc/#/Admin/delete_v1_admin__adminid__ServiceProviderRole)
+*/

export const deleteOneServiceProviderStaff = async (adminid: number) => {
  const { data } = await Axios.delete<string>(
    `/admin/${adminid}/ServiceProviderRole`,
  );
  return data;
};
