import React from 'react';
import { Label } from 'reactstrap';
import { CheckBoxOption } from 'app/models';

export interface Props {
  value: CheckBoxOption;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function CheckBox({ value, onChange }: Props) {
  return (
    <div className="form-check mb-2">
      <input
        type="checkbox"
        className="form-check-input"
        id={value.value}
        value={value.value}
        checked={value.check}
        onChange={onChange}
      />
      <Label className="form-check-label" for={value.value}>
        {value.label}
      </Label>
    </div>
  );
}

export default CheckBox;
