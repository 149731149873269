import React, { useEffect, useState, useCallback } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb';
import { Table, Pagination } from 'app/components/Table';

import ActivityListColumns from './ActivityListColumns';
import { getActivityList } from 'app/services/ActivityServices';
import { PAGE_SIZE } from 'app/config';
import { Activity, ActivityFilter } from 'app/models';
import { useLocation } from 'react-router-dom';
import { SelectFilter } from 'app/components/Filter';

export const ActivityListPage = () => {
  const location = useLocation();
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<ActivityFilter | null>(null);

  const getActivity = useCallback(async () => {
    try {
      if (filter) {
        const res = await getActivityList(filter);
        setActivityList(res.rows);
        setCount(res.count);
      }
    } catch (err) {
      console.log(err);
    }
  }, [filter]);

  useEffect(() => {
    getActivity();
  }, [getActivity]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const p = params.get('page');
    const status = params.get('status');
    let tempFilter: ActivityFilter;
    if (filter) {
      tempFilter = { ...filter };
    } else {
      tempFilter = {
        limit: PAGE_SIZE,
        offset: 0,
      };
    }
    tempFilter.status = status ? status : undefined;
    if (p) {
      tempFilter.offset = (parseInt(p) - 1) * PAGE_SIZE;
      setPage(parseInt(p));
    }
    setFilter(tempFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>活動列表 | Easy Living</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="活動管理" breadcrumbItem="活動列表" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <>
                    <Row className="mb-2">
                      <Col sm={3}>
                        <SelectFilter
                          name="status"
                          placeholder="選擇狀態"
                          options={[
                            { value: '', label: '所有狀態' },
                            { value: 'Published', label: '已公佈' },
                            { value: 'Unpublished', label: '未公佈' },
                          ]}
                          label="狀態"
                        />
                      </Col>
                      <Col
                        sm={9}
                        className="d-flex align-items-end justify-content-end"
                      >
                        <div>
                          <a
                            href="/activities/new"
                            className="btn btn-primary mb-2"
                            role="button"
                            aria-pressed="true"
                          >
                            <i className="mdi mdi-plus" />
                            {` ${'新增活動'}`}
                          </a>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={ActivityListColumns()}
                            keyField="activityid"
                            data={activityList || []}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination count={count} current={page} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
