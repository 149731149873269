import { TimeStamp } from './Common';

export enum MediaOptions {
  'Service Matching' = '家居維修',
  'House Renovation' = '裝修服務',
  'Estate Management' = '物業管理',
  'Property' = '物業買賣',
  'House Check' = '專業驗樓',
}

export type MediaTypes = keyof typeof MediaOptions;

export interface MediaResponse {
  'Service Matching': MediaItemResponse;
  'House Renovation': MediaItemResponse;
  'Estate Management': MediaItemResponse;
  Property: MediaItemResponse;
  'House Check': MediaItemResponse;
}

export interface MediaItemResponse extends TimeStamp {
  mediaid: number;
  category: MediaTypes;
  type: 'video';
  url: string;
}
