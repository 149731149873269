import Axios from '.';
import {
  ListResult,
  Filter,
  ServiceMatchingCase,
  ServiceMatchingCaseBase,
  ServiceMatchingCaseRecord,
} from '../models';

/**
* * Get ServiceMatchingCase List (https://easy-living-dev.appicidea.com/doc/#/Service%20Matching/get_v1_servicematching_case_admin)
* * 
+*/

export interface GetServiceMatcingCaseListAdminParams extends Filter {
  status?: string;
  serviceMatchingCaseId?: string;
  userid?: string;
  service?: string;
  region?: string;
  district?: string;
  isEasyLivingCompany?: string;
  serviceEndDate_End?: string;
  serviceEndDate_Start?: string;
}

export const getServiceMatcingCaseListAdmin = async (
  params: GetServiceMatcingCaseListAdminParams,
) => {
  const { data } = await Axios.get<ListResult<ServiceMatchingCase>>(
    'servicematching/case/admin',
    {
      params,
    },
  );
  return data;
};

/**
* * Create ServiceMatchingCase (https://cmhk-wine-dev.appicidea.com/doc/#/ServiceMatchingCase/post_v1_floorplan_room)
* * 
+*/

export interface CreateOneServiceMatcingCaseParams
  extends ServiceMatchingCaseBase {}

export const createOneServiceMatcingCase = async (
  params: CreateOneServiceMatcingCaseParams,
) => {
  const { data } = await Axios.post<ServiceMatchingCase>(
    'servicematching/case/admin',
    params,
  );
  return data;
};

/**
* * Get one ServiceMatchingCase (https://cmhk-wine-dev.appicidea.com/doc/#/ServiceMatchingCase/get_v1_floorplan_room__roomid_)
* * 
+*/

export const getOneServiceMatchingCase = async (
  serviceMatchingCaseid: number | string,
) => {
  const { data } = await Axios.get<ServiceMatchingCase>(
    `servicematching/case/${serviceMatchingCaseid}`,
  );
  return data;
};

/**
* * Update one ServiceMatchingCase (https://cmhk-wine-dev.appicidea.com/doc/#/ServiceMatchingCase/patch_v1_floorplan_room__roomid_)
* * 
+*/

export interface updateOneServiceMatchingCaseParam
  extends Partial<ServiceMatchingCaseBase> {}

export const updateOneServiceMatchingCase = async (
  serviceMatchingCaseid: number,
  params: updateOneServiceMatchingCaseParam,
) => {
  const { data } = await Axios.patch<any>(
    `/servicematching/case/${serviceMatchingCaseid}`,
    params,
  );
  return data;
};

/**
* * delete one ServiceMatchingCase (https://cmhk-wine-dev.appicidea.com/doc/#/ServiceMatchingCase/delete_v1_floorplan_room__roomid_)
* * 
+*/

export const deleteOneServiceMatchingCase = async (
  serviceMatchingCaseid: number,
) => {
  const { data } = await Axios.delete<any>(
    `/servicematching/case/${serviceMatchingCaseid}`,
  );
  return data;
};

/**
* * Get ServiceMatchingCase List (https://easy-living-dev.appicidea.com/doc/#/Service%20Matching/get_v1_servicematching_company__providerCompanyid__cases)
* * 
+*/

export interface GetServiceMatcingCaseListByCompanyidParams extends Filter {
  status?: string;
  startDate?: string;
  endDate?: string;
}

export const getServiceMatcingCaseListByCompanyid = async (
  providerCompanyid: number,
  params: GetServiceMatcingCaseListByCompanyidParams,
) => {
  const { data } = await Axios.get<ListResult<ServiceMatchingCase>>(
    `servicematching/company/${providerCompanyid}/cases`,
    {
      params,
    },
  );
  return data;
};

/*
 * Get Cases by Ids (https://easy-living-dev.appicidea.com/doc/#/Service%20Matching/get_v1_servicematching_case_getByIds)
 */

export const getCaseByIds = async (serviceMatchingCaseIds: number[]) => {
  const { data } = await Axios.get<ServiceMatchingCaseRecord[]>(
    '/servicematching/case/getByIds',
    {
      params: {
        ids: serviceMatchingCaseIds.join(','),
      },
    },
  );
  return data;
};
