import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { InputField, SelectField, ImageUploadField } from 'app/components/Form';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import {
  getOneServiceProvider,
  updateOneServiceProviderStaff,
} from 'app/services/ServiceProviderService';
import { createNewAdmin } from 'app/services/AdminServices';

import { changePreloader } from 'store/Layout';
import { formatBytes } from 'app/helpers/FormHelper';
import { uploadImage } from 'app/services/CommonService';
import { OptionItems, ServiceProvider, AdminPara } from 'app/models';

const STAFF_TYPE: OptionItems[] = [
  { label: '員工', value: 'Staff' },
  { label: '管理員', value: 'Master' },
];

interface ServiceProviderStaffForm {
  loginname: string;
  password: string;
  passwordConfirm: string;
  displayName: string;
  avatar: any;
  role: string;
  type: string;
}

const FORM_ITEM: ServiceProviderStaffForm = {
  avatar: null,
  displayName: '',
  loginname: '',
  password: '',
  passwordConfirm: '',
  role: 'admin',
  type: 'Staff',
};

const Schema = Yup.object().shape({
  avatar: Yup.mixed().nullable().required('必填項目'),
  displayName: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  loginname: Yup.string()
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元')
    .required('必填項目'),
  password: Yup.string().min(8, '密碼最少要8個位').required('必填項目'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], '密碼不相同')
    .required('必填項目'),
});

interface ServiceProviderStaffFormQuery {
  providerCompanyid?: string;
}

export function ServiceProviderStaffFormPage() {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<ServiceProviderStaffFormQuery>();
  const [form] = useState<ServiceProviderStaffForm>(FORM_ITEM);
  const [
    providerCompany,
    setProviderCompany,
  ] = useState<ServiceProvider | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { providerCompanyid: providerCompanyID } = params;
    try {
      if (providerCompanyID) {
        const spRes = await getOneServiceProvider(providerCompanyID);
        setProviderCompany(spRes);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const createStaff = async (
    values: ServiceProviderStaffForm,
    action: FormikHelpers<ServiceProviderStaffForm>,
  ) => {
    setLoading(true);
    const { avatar, displayName, loginname, password, type } = values;
    const params: AdminPara = {
      avatar: '',
      displayName,
      loginname,
      password,
      role: 'admin',
    };
    const staffParam = {
      displayName,
      providerCompanyid: providerCompany
        ? providerCompany.providerCompanyid
        : 0,
      type,
    };
    try {
      if (avatar) {
        const formData = new FormData();
        formData.append('file', avatar[0]);
        const imageRes = await uploadImage(formData);
        if (imageRes) {
          params.avatar = imageRes.fileName;
        }
      }
      const adminRes = await createNewAdmin(params);
      await updateOneServiceProviderStaff(adminRes.adminid, staffParam);
      setLoading(false);
      toast.success('新增員工成功。');
      if (providerCompany) {
        history.push(
          `/serviceprovider/edit/${providerCompany.providerCompanyid}`,
        );
      }
    } catch (err) {
      if (err?.response?.status === 409) {
        action.setErrors({ loginname: '已被注冊' });
      }
      setLoading(false);
      toast.warning('新增員工失敗，請重試。');
    }
  };

  const onSubmit = async (
    values: ServiceProviderStaffForm,
    action: FormikHelpers<ServiceProviderStaffForm>,
  ) => {
    createStaff(values, action);
  };

  const onDrop = (file, setFieldValue, field) => {
    if (file[0].size > 1024 * 10000) {
      toast.warning('圖片不可大於10MB');
      return;
    }
    if (
      file[0].type === 'image/jpeg' ||
      file[0].type === 'image/jpg' ||
      file[0].type === 'image/png'
    ) {
      file[0]['preview'] = URL.createObjectURL(file[0]);
      file[0]['formattedSize'] = formatBytes(file[0].size);
      setFieldValue(field, [file[0]]);
    } else {
      toast.warning('員工頭像圖片只接受.jpg / .png檔案');
      return;
    }
  };

  const TITLE = `新增服務供應商員工`;
  const BreadcrumbsOptions = [
    { title: '服務供應商', path: '/serviceprovider' },
    { title: '服務供應商列表', path: '/serviceprovider' },
    {
      title: '編輯服務供應商',
      path: `${
        providerCompany
          ? `/serviceprovider/edit/${providerCompany.providerCompanyid}`
          : '#'
      }`,
    },
    {
      title: TITLE,
      path: '#',
    },
  ];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{TITLE} | Easy Living</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>服務供應商資料</CardTitle>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({ values, setFieldValue }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col>
                                <ImageUploadField
                                  name="avatar"
                                  label="員工頭像"
                                  files={values.avatar}
                                  onDrop={file =>
                                    onDrop(file, setFieldValue, 'avatar')
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <InputField
                                  name="loginname"
                                  label="登入名稱"
                                  placeholder="登入名稱"
                                  type="text"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <InputField
                                  name="displayName"
                                  label="管理員名稱"
                                  placeholder="管理員名稱"
                                  type="text"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <InputField
                                  name="password"
                                  label="密碼"
                                  placeholder="請輸入密碼"
                                  type="password"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <InputField
                                  name="passwordConfirm"
                                  label="確認密碼"
                                  placeholder="再次請輸入密碼"
                                  type="password"
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12">
                                <SelectField
                                  name="type"
                                  label="員工類別"
                                  placeholder="員工類別"
                                  options={STAFF_TYPE}
                                />
                              </Col>
                            </Row>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
