import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    'AIzaSyACmMCduviS0Hs5D9P_Nq-zUMMMPBtIX5E',
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    'easy-living-mobile-app-dev.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_ID || 'easy-living-mobile-app-dev',
  storageBucket:
    process.env.REACT_APP_STORAGE || 'easy-living-mobile-app-dev.appspot.com',
  messagingSenderId: process.env.REACT_APP_SENDER_ID || '717326802088',
  appId:
    process.env.REACT_APP_APP_ID || '1:717326802088:web:ae2476a3a05a992eb4b69d',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || 'G-CZGFKM54ZC',
};

firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();

export { auth, db, firebase };
