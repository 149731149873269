import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';
import ProtectedRoute from 'app/route/ProtectedRoute';
import { authRoutes, userRoutes } from 'app/route/allRoutes';

// layouts Format
import VerticalLayout from 'app/components/VerticalLayout';
import NonAuthLayout from 'app/components/NonAuthLayout';

import { TOAST_TIME } from 'app/config';

// Import scss
import 'app/assets/scss/theme.scss';
import { useInitAdminMessageListener } from 'store/ChatRoom';

import { getProfile } from './services/AdminServices';
import { updateProfile } from '../store/Auth';
import Bugsnag from '@bugsnag/js';

export function App() {
  const dispatch = useDispatch();
  const { layout, auth } = useSelector((rootState: RootState) => rootState);

  const { layoutType } = layout;
  const { profile } = auth;
  useInitAdminMessageListener();

  const getAdminProfile = useCallback(async () => {
    try {
      if (profile) {
        const res = await getProfile();
        if (res) {
          dispatch(updateProfile(res));
        }
        Bugsnag.addMetadata('admin', { adminid: res.adminid });
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getAdminProfile();
  }, [getAdminProfile]);

  const { i18n } = useTranslation();
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (layoutType) {
      case 'horizontal':
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout = getLayout();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="Easy Living"
        defaultTitle="Easy Living"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Easy Living" />
      </Helmet>

      <Switch>
        {authRoutes.map((route, idx) => (
          <ProtectedRoute
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact={route.exact}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <ProtectedRoute
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
            permissions={route.permissions}
          />
        ))}
        <Redirect from="*" to="/notFound" />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_TIME}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyle />
    </BrowserRouter>
  );
}
