import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { toast } from 'react-toastify';
import { logout } from 'app/services/AdminServices';
import { Admin } from 'app/models';
import { auth } from '../firebase';

interface AuthState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
  profile: Admin | null;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  loginError: '',
  profile: null,
} as AuthState;

const authSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    loginStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginSuccess(state) {
      state.loginLoading = false;
      state.loginError = '';
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    profileSuccess(state, action: PayloadAction<string>) {
      state.success = action.payload;
    },
    profileError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    resetProfileFlag(state, action: PayloadAction<string | null>) {
      state.success = null;
    },
    reset() {
      return initialState;
    },
    updateProfile(state, action: PayloadAction<Admin | null>) {
      state.profile = action.payload;
    },
  },
});

export const {
  profileSuccess,
  profileError,
  resetProfileFlag,
  loginStart,
  loginSuccess,
  loginFail,
  updateProfile,
} = authSlice.actions;
export default authSlice.reducer;

// dispatch actions
export const logoutUser = (history): AppThunk => async dispatch => {
  dispatch(updateProfile(null));
  try {
    await logout();
    auth
      .signOut()
      .then(res => {
        history.replace('/login');
      })
      .catch(err => {
        history.replace('/login');
      });
  } catch (err) {
    toast.warning('請檢查你的網絡。');
  }
};
