import { CreateServiceProviderParams } from 'app/services/ServiceProviderService';
import { TimeStamp } from './Common';

export const RegionStrs = ['hk', 'kln', 'nt'] as const;

export const DistrictTypeStr = [
  'Central and Western',
  'Eastern',
  'Southern',
  'Wan Chai',

  'Kowloon City',
  'Kwun Tong',
  'Sham Shui Po',
  'Wong Tai Sin',
  'Yau Tsim Mong',

  'Islands',
  'Kwai Tsing',
  'North',
  'Sai Kung',
  'Sha Tin',
  'Tai Po',
  'Tsuen Wan',
  'Tuen Mun',
  'Yuen Long',
] as const;

export type RegionType = typeof RegionStrs[number];

export type DistrictType = typeof DistrictTypeStr[number];

export enum SerType {
  'electrical_appliances' = '電力',
  'pipe' = '水喉',
  'door_lock' = '門鎖',
  'unlock' = '開鎖',
  'wooden_door' = '木門',
  'water_seepage' = '室內滲水',
  'bathroom_facilities' = '浴室設施',
  'aircon' = '冷氣',
  'drainage' = '通渠',
  'others' = '其他',
}

export const ServiceTypeStr = [
  'electrical_appliances',
  'pipe',
  'door_lock',
  'unlock',
  'wooden_door',
  'water_seepage',
  'bathroom_facilities',
  'aircon',
  'drainage',
  'others',
] as const;

export type serviceType = typeof ServiceTypeStr[number];

export interface CompanyServices extends TimeStamp {
  companyServiceid: number;
  providerCompanyid: number;
}

export interface ServiceProvider
  extends TimeStamp,
    CreateServiceProviderParams {
  providerCompanyid: number;
  CompanyRegions: { region: string; district: string }[];
  isAvaliable: boolean;
}
