import Axios from './';
import { db } from '../../firebase';

// models
import { ChatRoomDoc, FirestoreUserData } from 'app/models';

export const getUserById = async (
  uid: string,
): Promise<FirestoreUserData | undefined> => {
  const documentSnapshot = await db.collection('users').doc(uid).get();
  if (documentSnapshot.exists) {
    return documentSnapshot.data() as FirestoreUserData;
  }
  return undefined;
};

export const getRoomById = async (
  roomid: string,
): Promise<ChatRoomDoc | undefined> => {
  const documentSnapshot = await db.collection('rooms').doc(roomid).get();
  if (documentSnapshot.exists) {
    return documentSnapshot.data() as ChatRoomDoc;
  }
  return undefined;
};

export const markRoomAsRead = async (roomid: string) => {
  const { data } = await Axios.post(`room/${roomid}/read`);
  return data;
};

export interface RoomRequest {
  q?: string;
  estateid?: string | number;
}

export const searchRoomByUsername = async (params: RoomRequest) => {
  const { data } = await Axios.get<string[]>('room', { params });
  return data;
};
