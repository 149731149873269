import { TimeStamp } from './Common';

export interface SellLeaseFormStep1 {
  ownerName: string;
  hkid: string;
  phoneNumber: string;
  type: string;
  propertyAddress: string;
  monthlyRent?: number;
  sellingPrice?: number;
  isRateManagementFeeIncluded?: boolean;
}

export interface SellLeaseFormStep2 {
  isCompany: boolean;
  companyName?: string;
  signerName?: string;
  signerPosition?: string;
  companyBrNumber?: string;
  companyAddress?: string;
  companyPhoneNumber?: string;
  companyFax?: string;
}
export interface SellLeaseFormStep3 {
  agreementType: string;
  agreementStartDate: string | null;
  agreementEndDate: string | null;
  agentRelation: string;
  isAgentIncludedInVisitProperty: boolean;
  isBuyerIncludedInVisitProperty: boolean;
  allowVisitProperty: boolean;
  allowKeepPropertyKey: boolean;
  isOtherAgentsIncludedInShareKey: boolean;
  isOthersIncludedInShareKey: boolean;
  allowSharePropertyKey: boolean;
  allowRedistribution: boolean;
  allowPromotion: boolean;
  discloseInterest: boolean;
  extraAgreement: string;
}

export interface SellLeaseFormStep4 {
  commissionTimeType: string;
  commissionOneTimeAmount: number | null;
  commissionPercentage: number | null;
  isRefundCommissionIncludeInterest: boolean;
  propertyRight: string;
}

export interface SellLeaseFormStep5 {
  agentCompanyName: string;
  agentSignerName: string;
  agentBrNumber: string;
  agentBusinessStatementNumber: string;
  agentAddress: string;
  agentCompanyPhone: string;
  agentFax: string;
}

export interface SellLeaseForm
  extends SellLeaseFormStep1,
    SellLeaseFormStep2,
    SellLeaseFormStep3,
    SellLeaseFormStep4,
    SellLeaseFormStep5,
    TimeStamp {
  sellLeaseFormid?: number;
  status?: string | null;
  userid: number;
  agentSignature: string;
  userSignature: string;
}
export enum IS_COMPANY_TYPE {
  '個人' = 'false',
  '公司' = 'true',
}

export enum FORM_TYPE_TYPE {
  '代理出售' = '代理出售',
  '代理出租' = '代理出租',
}
export enum AGENT_RELATION_TYPE {
  '單邊代理' = '單邊代理',
  '雙邊代理' = '雙邊代理',
  '有可能代表雙方的代理' = '有可能代表雙方的代理',
}

export enum COMMISSION_TIME_TYPE_TYPE1 {
  '簽署買賣協議時' = '簽署買賣協議時',
  '買賣協議指明的物業交易完成時' = '買賣協議指明的物業交易完成時',
}
export enum COMMISSION_TIME_TYPE_TYPE2 {
  '簽署租契時' = '簽署買賣協議時',
  '租契指明的租賃期開始時' = '買賣協議指明的物業交易完成時',
}
export enum AGREEMENT_TYPE_TYPE {
  '獨家代理' = '獨家代理',
  '非獨家代理' = '非獨家代理',
}
