import { TimeStamp } from './Common';
import { User } from './User';
import { ServiceProvider } from './ServiceProvider';
import { Admin } from './Admin';
export interface ServiceMatchingCase
  extends ServiceMatchingCaseBase,
    TimeStamp {
  serviceMatchingCaseId: number;
  round: number;
  adminid: number | null;
  caseStartTime: string | null;
  caseEndTime: string | null;
  userid: number;
  rating: number;
  status:
    | 'Pending'
    | 'Assigned'
    | 'Completed'
    | 'Canceled'
    | 'CS'
    | 'InProgress';
  assignedTime: string;
  ProviderCompany: ServiceProvider | null;
  User: User | null;
  ServiceMatchingReport: ServiceMatchingReport | null;
  clientInputPrice: string | null;
  noOfCase: number | null;
}
export interface ServiceMatchingCaseBase {
  customerName?: string | null;
  phone?: string;
  region: string;
  district: string;
  room: string;
  service: string;
  remarks: string;
  providerCompanyid: number | null;
  repairItem: string;
}

export interface ServiceMatchingReport extends TimeStamp {
  serviceMatchingReportid: number;
  serviceMatchingCaseId: number;
  room: string;
  service: string;
  rating: number;
  price: number;
  images: string[];
  report: string;
}

export enum SERVICE_MATCHING_CASE_STATUS_FOR_FILTER {
  全部 = '',
  請求等待接受中 = 'Pending',
  服務配對成功 = 'Assigned',
  進行中 = 'InProgress',
  由客服處理 = 'CS',
  已完成 = 'Completed',
  已取消 = 'Canceled',
}

export enum SERVICE_MATCHING_CASE_STATUS {
  'Pending' = '請求等待接受中',
  'Assigned' = '服務配對成功',
  'InProgress' = '進行中',
  'CS' = '由客服處理',
  'Completed' = '已完成',
  'Canceled' = '已取消',
}
export enum SERVICE_MATCHING_CASE_STATUS_COLOR {
  'Pending' = 'secondary',
  'Assigned' = 'info',
  'InProgress' = 'primary',
  'CS' = 'warning',
  'Completed' = 'success',
  'Canceled' = 'danger',
}
export enum SERVICE_MATCHING_CASE_ROOM_TYPE {
  dinningRoom = '飯廳',
  bathroom = '浴室',
  bedroom = '臥室',
  kitchen = '廚房',
  toliet = '廁所',
  living_room = '客廳',
  balcony = '露台',
  other = '其他',
  others = '其他',
}

export interface ServiceMatchingCaseRecord extends ServiceMatchingCase {
  ProviderCompany: ServiceProvider;
  adminid: number | null;
  assignedTime: string;
  customerName?: string;
  phone?: string;
  Admin?: Admin;
}
