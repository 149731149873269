import Axios from './';
import {
  Building,
  BuildingParams,
  Estate,
  EstateFilter,
  EstateParams,
  ListResult,
} from '../models';

export const getEstateList = async (params: EstateFilter) => {
  const { data } = await Axios.get<ListResult<Estate<Building>>>('/estate', {
    params,
  });
  return data;
};

export const createEstate = async (params: EstateParams<BuildingParams>) => {
  const { data } = await Axios.post<string>('/estate', params);
  return data;
};

export const getEstateRegion = async () => {
  const { data } = await Axios.post('/estate/region');
  return data;
};

export const getEstateDistrict = async () => {
  const { data } = await Axios.post('/estate/district');
  return data;
};

export const getOneEstate = async (estateid: string | number) => {
  const { data } = await Axios.get<Estate<Building>>(`/estate/${estateid}`);
  return data;
};

export const deleteOneEstate = async (estateid: number) => {
  const { data } = await Axios.delete<null>(`/estate/${estateid}`);
  return data;
};

export const updateOneEstate = async (
  estateid: number,
  params: EstateParams<BuildingParams>,
) => {
  const { data } = await Axios.put<null>(`/estate/${estateid}`, params);
  return data;
};

export const getEstateByIds = async (estateids: number[]) => {
  const { data } = await Axios.get<Estate<Building>[]>('estate/getByIds', {
    params: {
      ids: estateids.join(','),
    },
  });
  return data;
};
