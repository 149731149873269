import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { UserFormPage, UserListPage } from 'app/pages/UserPage';
import { EstateListPage, EstateFormPage } from 'app/pages/EstatePage';
import { ActivityListPage, ActivityFormPage } from 'app/pages/ActivityPage';
import { NoticeListPage, NoticeFormPage } from 'app/pages/NoticePage';
import {
  PropertyListPage,
  PropertyFormPage,
  PropertySellLeaseFormListPage,
  PropertySellLeaseFormFormPage,
  PropertySellLeaseFormDetailPage,
} from 'app/pages/PropertyPage';
import {
  RenovationListPage,
  RenovationFormPage,
} from 'app/pages/RenovationPage';
import {
  ChatRoomListPage,
  ChatRoomDetailPage,
  TextTemplateListPage,
  TextTemplateFormPage,
  PropertyManagerChatRoomListPage,
} from 'app/pages/ChatRoomPage';
import { HouseCheckListPage, HouseCheckDetailPage } from 'app/pages/HouseCheck';
import {
  ServiceProviderListPage,
  ServiceProviderFormPage,
  ServiceProviderStaffFormPage,
  ServiceProviderStaffEditFormPage,
} from 'app/pages/ServiceProviderPage';
import {
  ServiceMatchingListPage,
  ServiceMatchingFormPage,
  ServiceMatchingDetailPage,
} from 'app/pages/ServiceMatchingPage';
import {
  CommissionListPage,
  CommissionFormPage,
} from 'app/pages/CommissionPage';

import { HomePage } from 'app/pages/HomePage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { MediaSettingFormPage } from 'app/pages/MediaSettingPage';
import { AdminRolesType } from 'app/models';
import PrivacyPolicyPage from 'app/pages/PrivacyPolicyPage';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
  permissions?: AdminRolesType[];
}
const userRoutes: Route[] = [
  // admin
  {
    path: '/admins',
    component: AdminListPage,
    permissions: ['super-admin'],
  },
  {
    path: '/admins/new',
    component: AdminFormPage,
    permissions: ['super-admin'],
  },
  {
    path: '/admins/edit/:adminid',
    component: AdminFormPage,
    permissions: ['super-admin'],
  },

  // user
  { path: '/users', component: UserListPage, permissions: ['super-admin'] },
  {
    path: '/users/edit/:userid',
    component: UserFormPage,
    permissions: ['super-admin'],
  },

  // activities
  {
    path: '/activities',
    component: ActivityListPage,
    permissions: ['super-admin', 'event-manager'],
  },
  {
    path: '/activities/new',
    component: ActivityFormPage,
    permissions: ['super-admin', 'event-manager'],
  },
  {
    path: '/activities/edit/:activityid',
    component: ActivityFormPage,
    permissions: ['super-admin', 'event-manager'],
  },

  // estate
  {
    path: '/estates',
    component: EstateListPage,
    permissions: [
      'super-admin',
      'super-property-manager',
      'property-manager-經理',
    ],
  },
  {
    path: '/estates/new',
    component: EstateFormPage,
    permissions: [
      'super-admin',
      'super-property-manager',
      'property-manager-經理',
    ],
  },
  {
    path: '/estates/edit/:estateid',
    component: EstateFormPage,
    permissions: [
      'super-admin',
      'super-property-manager',
      'property-manager-經理',
    ],
  },

  // property
  {
    path: '/properties',
    component: PropertyListPage,
    permissions: ['super-admin', 'property-agent'],
  },
  {
    path: '/properties/new',
    component: PropertyFormPage,
    permissions: ['super-admin', 'property-agent'],
  },
  {
    path: '/properties/edit/:propertyid',
    component: PropertyFormPage,
    permissions: ['super-admin', 'property-agent'],
  },
  {
    path: '/sellLeaseForm',
    component: PropertySellLeaseFormListPage,
    permissions: ['super-admin', 'property-agent'],
  },
  {
    path: '/sellLeaseForm/new',
    component: PropertySellLeaseFormFormPage,
    permissions: ['super-admin', 'property-agent'],
  },
  {
    path: '/sellLeaseForm/edit/:sellLeaseFormid',
    component: PropertySellLeaseFormFormPage,
    permissions: ['super-admin', 'property-agent'],
  },
  {
    path: '/sellLeaseForm/detail/:sellLeaseFormid',
    component: PropertySellLeaseFormDetailPage,
    permissions: ['super-admin', 'property-agent'],
  },

  // estate notice
  {
    path: '/notice',
    component: NoticeListPage,
    permissions: [
      'super-admin',
      'super-property-manager',
      'property-manager-主任',
      'property-manager-經理',
    ],
  },
  {
    path: '/notice/new',
    component: NoticeFormPage,
    permissions: [
      'super-admin',
      'super-property-manager',
      'property-manager-主任',
      'property-manager-經理',
    ],
  },
  {
    path: '/notice/edit/:noticeid',
    component: NoticeFormPage,
    permissions: [
      'super-admin',
      'super-property-manager',
      'property-manager-主任',
      'property-manager-經理',
    ],
  },

  // service provider
  {
    path: '/serviceprovider',
    component: ServiceProviderListPage,
    permissions: ['super-admin'],
  },
  {
    path: '/serviceprovider/new',
    component: ServiceProviderFormPage,
    permissions: ['super-admin'],
  },
  {
    path: '/serviceprovider/edit/:providerCompanyid',
    component: ServiceProviderFormPage,
    permissions: ['super-admin'],
  },
  {
    path: '/serviceprovider/edit/:providerCompanyid/newstaff',
    component: ServiceProviderStaffFormPage,
    permissions: ['super-admin'],
  },
  {
    path: '/serviceprovider/edit/:providerCompanyid/edit/:adminid',
    component: ServiceProviderStaffEditFormPage,
    permissions: ['super-admin'],
  },

  // service matching
  {
    path: '/servicematching',
    component: ServiceMatchingListPage,
    permissions: ['super-admin', 'cs'],
  },
  {
    path: '/servicematching/detail/:serviceMatchingCaseId',
    component: ServiceMatchingDetailPage,
    permissions: ['super-admin', 'cs'],
  },

  {
    path: '/servicematching/new',
    component: ServiceMatchingFormPage,
    permissions: ['super-admin', 'cs'],
  },
  {
    path: '/servicematching/edit/:serviceMatchingCaseId',
    component: ServiceMatchingFormPage,
    permissions: ['super-admin', 'cs'],
  },

  // renovation
  {
    path: '/renovation',
    component: RenovationListPage,
    permissions: ['super-admin', 'renovation-staff'],
  },
  {
    path: '/renovation/new',
    component: RenovationFormPage,
    permissions: ['super-admin', 'renovation-staff'],
  },
  {
    path: '/renovation/detail/:renovationid',
    component: RenovationFormPage,
    permissions: ['super-admin', 'renovation-staff'],
  },

  // house checking
  {
    path: '/houseCheck',
    component: HouseCheckListPage,
    permissions: ['super-admin'],
  },
  {
    path: '/houseCheck/:houseCheckid',
    component: HouseCheckDetailPage,
    permissions: ['super-admin'],
  },

  // commission
  {
    path: '/commission',
    component: CommissionListPage,
    permissions: ['super-admin'],
  },
  {
    path: '/commission/new',
    component: CommissionFormPage,
    permissions: ['super-admin'],
  },
  {
    path: '/commission/edit/:commissionid',
    component: CommissionFormPage,
    permissions: ['super-admin'],
  },

  //chat room
  {
    path: '/chatrooms',
    component: ChatRoomListPage,
    permissions: [
      'super-admin',
      'cs',
      'super-property-manager',
      'property-agent',
    ],
  },
  {
    path: '/PMChatrooms',
    component: PropertyManagerChatRoomListPage,
    permissions: [
      'super-admin',
      'cs',
      'property-manager-主任',
      'property-manager-經理',
    ],
  },
  {
    path: '/chatrooms/:roomid',
    component: ChatRoomDetailPage,
    permissions: [
      'super-admin',
      'cs',
      'super-property-manager',
      'property-manager-主任',
      'property-manager-經理',
      'property-agent',
    ],
  },
  {
    path: '/textTemplate',
    component: TextTemplateListPage,
    permissions: ['super-admin', 'cs'],
  },
  {
    path: '/textTemplate/new',
    component: TextTemplateFormPage,
    permissions: ['super-admin', 'cs'],
  },
  {
    path: '/textTemplate/edit/:textTemplateid',
    component: TextTemplateFormPage,
    permissions: ['super-admin', 'cs'],
  },

  // media setting
  {
    path: '/mediaSetting',
    component: MediaSettingFormPage,
    permissions: ['super-admin'],
  },

  // others
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/privacyPolicy', component: PrivacyPolicyPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
