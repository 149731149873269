import { ActivityStatusType } from 'app/models';
import { Badge } from 'reactstrap';

export const renderActivityStatusBadge = (type: ActivityStatusType) => {
  switch (type) {
    case 'Published':
      return <Badge className="font-size-12 badge-soft-success">已公佈</Badge>;
    case 'Unpublished':
      return (
        <Badge className="font-size-12 badge-soft-secondary">未公佈</Badge>
      );
    default:
      return type;
  }
};
