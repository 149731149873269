import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import './privacy-policy.scss';

const PrivacyPolicyPage = () => {
  return (
    <React.Fragment>
      <div className="page-content privacy-policy">
        <MetaTags>
          <title>PRIVACY POLICY | Easy Living</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>PRIVACY POLICY</h4>
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <h5 className="mb-3">This policy sets out:</h5>

                              <ol className="mb-4">
                                <li>
                                  the information we collect about you when you
                                  visit our website, use our mobile apps, use
                                  our products or services, or otherwise
                                  interact with us;
                                </li>
                                <li>
                                  how we use, share, store, and secure the
                                  information; and
                                </li>
                                <li>
                                  how you may access and control the
                                  information.
                                </li>
                              </ol>

                              <p className="mb-4">
                                In this policy, <b>“Easy Living”</b> or{' '}
                                <b>“we”</b> refers to
                                <b>“Easy Living Consultant Limited”</b> of 23/F,
                                Pioneer Centre, 750 Nathan Rd and{' '}
                                <b>“Platform”</b> means our mobile app available
                                on google play and app store and our software,
                                namely <b>“宜居城市”</b>.
                              </p>

                              <p className="mb-4">
                                In this policy, <b>“personal information”</b>{' '}
                                refers to any data, information, or combination
                                of data and information that is provided by you
                                to us, or through your use of our products or
                                services, that relates to an identifiable
                                individual.
                              </p>

                              <ol>
                                <li className="mb-3">
                                  <b>What information we collect about you</b>
                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      We collect the following types of
                                      information about you:
                                      <ol className="mb-3">
                                        <li className="mb-3">
                                          account and profile information that
                                          you provide when you register for an
                                          account or sign up for our products or
                                          services, for example Including but
                                          not limited to your name, username or
                                          similar identifier, other personal
                                          description, job title, date of birth
                                          and gender, physical address (billing
                                          or delivery or both), email address,
                                          telephone number(s) (collectively, “
                                          <b>Account Data</b>”);
                                        </li>

                                        <li className="mb-3">
                                          information you provide through
                                          support channels, for example when you
                                          report a problem to us or interact
                                          with our support team, including any
                                          contact information, documentation, or
                                          screenshots (collectively,{' '}
                                          <b>“Support Data”</b>);
                                        </li>
                                        <li className="mb-3">
                                          content you provide through use of our
                                          products or services, for example
                                          Including but not limited to blog
                                          posts, comments, discussion forums,
                                          chats, reviews (collectively,{' '}
                                          <b>“User Content”</b>);
                                        </li>

                                        <li className="mb-3">
                                          communication, marketing, and other
                                          preferences that you set when you set
                                          up your account or profile, or when
                                          you participate in a survey or a
                                          questionnaire that we send you
                                          (collectively,{' '}
                                          <b>“Preference Data”</b>);
                                        </li>

                                        <li className="mb-3">
                                          details of any transactions,
                                          purchases, or orders that you've made
                                          with us (collectively,{' '}
                                          <b>“Transaction Data”</b>);
                                        </li>

                                        <li className="mb-3">
                                          payment information, for example
                                          Including but not limited to your
                                          payment card information or bank
                                          account details (collectively,
                                          <b>“Financial Data”</b>);
                                        </li>

                                        <li className="mb-3">
                                          information about your device or
                                          connection, for example Including but
                                          not limited to your internet protocol
                                          (IP) address, log-in data, browser
                                          type and version, time-zone setting,
                                          browser plug-in types and versions,
                                          operating system and platform, and
                                          other technology on the devices you
                                          use to access our products or services
                                          and information we collect through
                                          cookies and other data collection
                                          technologies (please read our Cookies
                                          Policy for details) (collectively,
                                          <b>“Technical Data”</b>); and
                                        </li>
                                        <li className="mb-3">
                                          Information about your use of or visit
                                          to our Platform, for example Including
                                          but not limited to your clickstream
                                          to, through, and from our Platform,
                                          products you viewed, used, or searched
                                          for, page response times, download
                                          errors, length of visits to certain
                                          pages, page interaction information
                                          (such as scrolling, clicks, and mouse-
                                          overs), or methods to browse away from
                                          the page. (collectively,{' '}
                                          <b>“Usage Data”</b>).
                                        </li>
                                      </ol>
                                    </li>

                                    <li className="mb-3">
                                      We collect the above information when you
                                      provide it to us or when you use or visit
                                      our Platform. We may also receive
                                      information about you from other sources,
                                      including:
                                      <ol className="mb-3">
                                        <li className="mb-3">
                                          our personnel, agents, advisors,
                                          consultants, and contractors based in
                                          Hong Kong and China in connection with
                                          our operations or services, for
                                          example our staff engaged in the
                                          fulfilment of your order, processing
                                          of your payment, and provision of
                                          support services;
                                        </li>

                                        <li className="mb-3">
                                          other users of our products or
                                          services, for example Including but
                                          not limited to reviews of listings
                                          posted by users and promotion
                                          purposes;
                                        </li>

                                        <li className="mb-3">
                                          other services linked to your account,
                                          for example If you register or log in
                                          your account using your Google
                                          credentials, Facebook and the others,
                                          we receive the information including
                                          but not limited to your name and email
                                          address to authenticate you, as
                                          permitted by your profile settings. ;
                                        </li>

                                        <li className="mb-3">
                                          our group companies or overseas
                                          offices that provide Including but not
                                          limited to information technology
                                          services, system administrative
                                          services, and marketing services.; and
                                        </li>

                                        <li className="mb-3">
                                          our business partners and service
                                          providers based in Including but not
                                          limited to Hong Kong, Japan, Taiwan,
                                          China, Singapore and Korea who provide
                                          Including but not limited to
                                          technical, payment, delivery services,
                                          advertising networks, analytics,
                                          market research, and search
                                          information services.
                                        </li>
                                      </ol>
                                    </li>

                                    <li className="mb-3">
                                      We do not collect sensitive data or
                                      special category data about you. This
                                      includes details about your race, ethnic
                                      origin, politics, religion, trade union
                                      membership, genetics, biometrics, health,
                                      or sexual orientation.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>How we use information we collect</b>

                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      We only use your personal information
                                      where the law allows us to. We use your
                                      personal information only where:
                                      <ol className="mb-3">
                                        <li className="mb-3">
                                          we need to perform the contract we
                                          have entered into (or are about to
                                          enter into) with you, including to
                                          operate our products or services, to
                                          provide customer support and
                                          personalised features, and to protect
                                          the safety and security of our
                                          Platform;
                                        </li>

                                        <li className="mb-3">
                                          it satisfies a legitimate interest
                                          which is not overridden by your
                                          fundamental rights or data protection
                                          interests, for example for research
                                          and development, and in order to
                                          protect our legal rights and interests
                                        </li>

                                        <li className="mb-3">
                                          you've given us consent to do so for a
                                          specific purpose, for example we may
                                          send you direct marketing materials or
                                          publish your information as part of
                                          our testimonials or customer stories
                                          to promote our products or services
                                          with your permission; or
                                        </li>

                                        <li className="mb-3">
                                          we need to comply with a legal or
                                          regulatory obligation.
                                        </li>
                                      </ol>
                                    </li>

                                    <li className="mb-3">
                                      If you have given us consent to use your
                                      personal information for a specific
                                      purpose, you have the right to withdraw
                                      your consent any time by contacting us
                                      (please refer to paragraph 9 for contact
                                      information), but please note this will
                                      not affect any use of your information
                                      that has already taken place.
                                    </li>

                                    <li className="mb-3">
                                      We do not share your personal information
                                      with any company outside our group for
                                      marketing purpose, unless with your
                                      express specific consent to do so.
                                    </li>

                                    <li className="mb-3">
                                      For visitors to or users of our Platform
                                      who are located in the European Union, we
                                      have set out our legal bases for
                                      processing your information in the Legal
                                      Bases Table at the end of this policy.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>How we share information we collect</b>

                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      We share information with third parties
                                      that help us operate, provide, support,
                                      improve, and market our products and
                                      services, for example third-party service
                                      providers who provide Including but not
                                      limited to the sale and member related
                                      activities, website and application
                                      development, data storage and backup,
                                      infrastructure, billing, payment
                                      processing, customer support, business
                                      analytics, and other services.
                                    </li>

                                    <li className="mb-3">
                                      Third-party service providers have access
                                      to your personal information only for the
                                      purpose of performing their services and
                                      in compliance with applicable laws and
                                      regulations. We require these third-party
                                      service providers to maintain
                                      confidentiality and security of all
                                      personal information that they process on
                                      our behalf and to implement and maintain
                                      reasonable security measures to protect
                                      the confidentiality, integrity, and
                                      availability of your personal information.
                                    </li>

                                    <li className="mb-3">
                                      We take reasonable steps to confirm that
                                      all third-party service providers that we
                                      engage process personal information in the
                                      manner that provides at least the same
                                      level of protection as is provided under
                                      this policy. Where any third-party
                                      provider is unable to satisfy our
                                      requirements, we will require them to
                                      notify us immediately and we will take
                                      reasonable steps to prevent or stop
                                      non-compliant processing.
                                    </li>

                                    <li className="mb-3">
                                      We may share personal information on
                                      aggregated or de-identified basis with
                                      third parties for research and analysis,
                                      profiling, and similar purposes to help us
                                      improve our products and services.
                                    </li>

                                    <li className="mb-3">
                                      If you use any third-party software in
                                      connection with our products or services,
                                      for example any third-party software that
                                      our Platform integrates with, you might
                                      give the third-party software provider
                                      access to your account and information.
                                      Policies and procedures of third-party
                                      software providers are not controlled by
                                      us, and this policy does not cover how
                                      your information is collected or used by
                                      third-party software providers. We
                                      encourage you to review the privacy
                                      policies of third-party software providers
                                      before you use the third-party software.
                                    </li>

                                    <li className="mb-3">
                                      Our Platform may contain links to
                                      third-party websites over which we have no
                                      control. If you follow a link to any of
                                      these websites or submit information to
                                      them, your information will be governed by
                                      their policies. We encourage you to review
                                      the privacy policies of third-party
                                      websites before you submit information to
                                      them.
                                    </li>

                                    <li className="mb-3">
                                      We may share your information with
                                      government and law enforcement officials
                                      to comply with applicable laws or
                                      regulations, for example when we respond
                                      to claims, legal processes, law
                                      enforcement, or national security
                                      requests.
                                    </li>

                                    <li className="mb-3">
                                      If we are acquired by a third party as a
                                      result of a merger, acquisition, or
                                      business transfer, your personal
                                      information may be disclosed and/or
                                      transferred to a third party in connection
                                      with such transaction. We will notify you
                                      if such transaction takes place and inform
                                      you of any choices you may have regarding
                                      your information.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>
                                    How we store and secure information we
                                    collect
                                  </b>

                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      We use data hosting service providers
                                      based in Hong Kong and China to host the
                                      information we collect.
                                    </li>

                                    <li className="mb-3">
                                      We have adopted the following measures to
                                      protect the security and integrity of your
                                      personal information:
                                      <ol className="mb-3">
                                        <li className="mb-3">
                                          information is encrypted using TLS/SSL
                                          technology;
                                        </li>
                                        <li className="mb-3">
                                          your account is password-protected,
                                          with the requirement(s) that passwords
                                          must be at least 2 characters long;
                                        </li>
                                        <li className="mb-3">
                                          access to your personal information is
                                          restricted to personnel or service
                                          providers on a strictly need-to-know
                                          basis, who will only process your
                                          information on our instructions and
                                          who are subject to a duty of
                                          confidentiality; and
                                        </li>
                                        <li className="mb-3">
                                          our information collection, storage,
                                          and processing practices are reviewed
                                          regularly.
                                        </li>
                                      </ol>
                                    </li>

                                    <li className="mb-3">
                                      We have put in place procedures to deal
                                      with any suspected privacy breach and will
                                      notify you and any applicable regulator of
                                      a breach where we are legally required to
                                      do so.
                                    </li>

                                    <li className="mb-3">
                                      While we implement safeguards designed to
                                      protect your information, please note that
                                      no transmission of information on the
                                      Internet is completely secure. We cannot
                                      guarantee that your information, during
                                      transmission through the Internet or while
                                      stored on our systems or processed by us,
                                      is absolutely safe and secure.
                                    </li>

                                    <li className="mb-3">
                                      We only retain personal information for so
                                      long as it is reasonably necessary to
                                      fulfil the purposes we collected it for,
                                      including for the purposes of satisfying
                                      any legal, accounting, or reporting
                                      requirements. After three years, we will
                                      delete or anonymise your information, or
                                      if this is not possible, we will securely
                                      store your information and isolate it from
                                      further use. We periodically review the
                                      basis and appropriateness of our data
                                      retention policy.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>
                                    How we transfer information internationally
                                  </b>

                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      We collect information globally and
                                      primarily store that information in Hong
                                      Kong and China. We transfer, process, and
                                      store your information outside your
                                      country of residence where we or our
                                      service providers operate for the purpose
                                      of providing our products and services to
                                      you.
                                    </li>

                                    <li className="mb-3">
                                      Some of the countries in which our
                                      companies or service providers are located
                                      may not have the privacy and data
                                      protection laws that are equivalent to
                                      those in your country of residence. When
                                      we share information with these companies
                                      or service providers, we make use of
                                      contractual clauses, corporate rules, and
                                      other appropriate mechanisms to safeguard
                                      the transfer of information.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>Your rights</b>

                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      You have the right to:
                                      <ol className="mb-3">
                                        <li className="mb-3">
                                          be informed of what we do with your
                                          personal information;
                                        </li>

                                        <li className="mb-3">
                                          request a copy of personal information
                                          we hold about you;
                                        </li>

                                        <li className="mb-3">
                                          require us to correct any inaccuracy
                                          or error in any personal information
                                          we hold about you;
                                        </li>

                                        <li className="mb-3">
                                          request erasure of your personal
                                          information (note, however, that we
                                          may not always be able to comply with
                                          your request of erasure for record
                                          keeping purposes, to complete
                                          transactions, or to comply with our
                                          legal obligations);
                                        </li>

                                        <li className="mb-3">
                                          object to or restrict the processing
                                          by us of your personal information
                                          (including for marketing purposes);
                                        </li>

                                        <li className="mb-3">
                                          request to receive some of your
                                          personal information in a structured,
                                          commonly used, and machine readable
                                          format, and request that we transfer
                                          such information to another party; and
                                        </li>

                                        <li className="mb-3">
                                          withdraw your consent at any time
                                          where we are relying on consent to
                                          process your personal information
                                          (although this will not affect the
                                          lawfulness of any processing carried
                                          out before you withdraw your consent).
                                        </li>
                                      </ol>
                                    </li>

                                    <li className="mb-3">
                                      Our Platform enables you to update certain
                                      information about yourself, for example
                                      You may change your business or personal
                                      information by updating your user profile
                                      or changing your user settings.
                                    </li>

                                    <li className="mb-3">
                                      You may opt out of receiving marketing
                                      materials from us by using the unsubscribe
                                      link in our communications, by updating
                                      your preferences within your account on
                                      our Platform, or by contacting us. Please
                                      note, however, that even if you opt out
                                      from receiving marketing materials from
                                      us, you will continue to receive
                                      notifications or information from us that
                                      are necessary for the use of our products
                                      or services.
                                    </li>

                                    <li className="mb-3">
                                      As a security measure, we may need
                                      specific information from you to help us
                                      confirm your identity when processing your
                                      privacy requests or when you exercise your
                                      rights.
                                    </li>

                                    <li className="mb-3">
                                      Any request under paragraph 6.1 will
                                      normally be addressed free of charge.
                                      However, we may charge a reasonable
                                      administration fee if your request is
                                      clearly unfounded, repetitive, or
                                      excessive.
                                    </li>

                                    <li className="mb-3">
                                      We will respond to all legitimate requests
                                      within one (1) month. Occasionally, it may
                                      take us longer than a month if your
                                      request is particularly complex or if you
                                      have made a number of requests.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>Changes to this policy</b>
                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      We may amend this policy from time to time
                                      by posting the updated policy on our
                                      Platform. By continuing to use our
                                      Platform after the changes come into
                                      effect, you agree to be bound by the
                                      revised policy.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>Policy towards children</b>

                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      Our products and services are not directed
                                      to individuals under 16. We do not
                                      knowingly collect personal information
                                      from individuals under 16. If we become
                                      aware that an individual under 16 has
                                      provided us with personal information, we
                                      will take steps to delete such
                                      information. Contact us if you believe
                                      that we have mistakenly or unintentionally
                                      collected information from an individual
                                      under 16.
                                    </li>
                                  </ol>
                                </li>

                                <li className="mb-3">
                                  <b>Contact us</b>

                                  <ol className="mb-3">
                                    <li className="mb-3">
                                      Please contact us at{' '}
                                      <b>marketing@hkeasyliving.com </b>
                                      or submit any written request to: <br />
                                      <b>“Easy Living Consultant Limited”</b>
                                      <br />
                                      <b>23/F, Pioneer Centre, 750 Nathan Rd</b>
                                    </li>
                                    <li className="mb-3">
                                      Please contact us in the first instance if
                                      you have any questions or concerns. If you
                                      have unresolved concerns, you have the
                                      right to file a complaint with a data
                                      protection authority in the country where
                                      you live or work or where you feel your
                                      rights have been infringed.
                                    </li>
                                  </ol>
                                </li>
                              </ol>
                              <h4 className="mb-3">COOKIES POLICY</h4>
                              <p className="mb-3">
                                Cookies are small text files that are placed on
                                your device by a web server when you access our
                                Platform. We use cookies to identify your access
                                and monitor usage and web traffic on our
                                Platform to customise and improve our products
                                and services.
                              </p>

                              <p className="mb-3">
                                We use both persistent cookies and session
                                cookies. A persistent cookie stays in your
                                browser and will be read by us when you return
                                to our Site or a partner site that uses our
                                services. Session cookies only last for as long
                                as the session lasts (usually the current visit
                                to a website or a browser session).
                              </p>

                              <p className="mb-3">
                                We use the following types of cookies:
                              </p>

                              <ol className="mb-3">
                                <li className="mb-3">
                                  Strictly necessary cookies – these are cookies
                                  that are required for the operation of our
                                  site. They include, for example, cookies that
                                  enable you to log into secure areas of our
                                  website.
                                </li>
                                <li className="mb-3">
                                  Analytical/performance cookies – these allow
                                  us to recognise and count the number of
                                  visitors and to see how visitors move around
                                  our Site when they are using it. This helps us
                                  to improve the way our site works, for
                                  example, by ensuring that users are easily
                                  finding what they are looking for.
                                </li>
                                <li className="mb-3">
                                  Functionality cookies – these are used to
                                  recognise you when you return to our site.
                                  Targeting cookies – these cookies record your
                                  visit to our site, the pages you have visited,
                                  and the links you have followed.You can block
                                  cookies by activating the setting on your
                                  browser that allows you to refuse the use of
                                  all or some cookies. However, if you do so,
                                  you may not be able to access all or parts of
                                  our site.
                                </li>
                              </ol>

                              <h4 className="mb-3">LEGAL BASES TABLE</h4>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Processing purpose</th>
                                    <th scope="col">Type of data processed</th>
                                    <th scope="col">Legal basis</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      To register you as a user on out Platform
                                    </td>
                                    <td>Account Data</td>
                                    <td>To perform our contract with you</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      To enable you to use our products and
                                      services
                                    </td>
                                    <td>
                                      Account Data, Transaction Data, Support
                                      Data, Technical Data [and User Content]
                                    </td>
                                    <td>To perform our contract with you</td>
                                  </tr>
                                  <tr>
                                    <td>To process your payments</td>
                                    <td>
                                      Account Data, Transaction Data, Financial
                                      Data
                                    </td>
                                    <td>To perform our contract with you</td>
                                  </tr>

                                  <tr>
                                    <td>To collect overdue amounts</td>
                                    <td>
                                      Account Data, Transaction Data, Financial
                                      Data
                                    </td>
                                    <td>
                                      Legitimate interest to recover debts due
                                      to us
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      To notify you about changes to our
                                      products, services or terms
                                    </td>
                                    <td>
                                      Account Data, Transaction Data, Financial
                                      Data
                                    </td>
                                    <td>To perform our contract with you</td>
                                  </tr>

                                  <tr>
                                    <td>
                                      To administer and maintain safety and
                                      security of our Platform
                                    </td>
                                    <td>Technical Data, Usage Data</td>
                                    <td>To perform our contract with you</td>
                                  </tr>

                                  <tr>
                                    <td>
                                      To study usage of our products or services
                                    </td>
                                    <td>
                                      Transaction Data, Support Data, Technical
                                      Data, Usage Data
                                    </td>
                                    <td>
                                      Legitimate interest to improve our
                                      Platform, products and services
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      To gather feedback on our products,
                                      services or features
                                    </td>
                                    <td>Account Data</td>
                                    <td>
                                      Legitimate interest to improve our
                                      Platform, products and services
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      To register you as a user on out Platform
                                    </td>
                                    <td>Account Data</td>
                                    <td>To perform our contract with you</td>
                                  </tr>

                                  <tr>
                                    <td>
                                      To provide information on products or
                                      services that may be of interest to you
                                    </td>
                                    <td>Account Data, Preference Data</td>
                                    <td>
                                      Consent, which you may withdraw any time
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
