/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useCallback } from 'react';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
// permission store
import { useAdminPermission } from 'store/Permission';

const SidebarContent = props => {
  const { chatRoom, auth } = useSelector((rootState: RootState) => rootState);
  const { profile } = auth;

  const ref = useRef<any>();
  const location = useLocation();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          const pathStr = pathName.split('/');
          let containPath = false;
          pathStr.forEach(str => {
            if (str && `/${str}` === items[i].pathname) {
              containPath = true;
            }
          });
          if (containPath) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  const { hasPermission } = useAdminPermission();

  const getChatRoomRoute = () => {
    if (profile) {
      const {
        PropertyManagerRoles,
        CSRole,
        SuperAdminRole,
        SuperPropertyManagerRole,
        PropertyAgentRole,
      } = profile;
      if (PropertyManagerRoles && PropertyManagerRoles.length > 0) {
        return '/PMChatrooms';
      } else if (
        CSRole ||
        SuperAdminRole ||
        SuperPropertyManagerRole ||
        PropertyAgentRole
      ) {
        return '/chatrooms';
      }
    }
    return 'no-chatrooms';
  };

  let hasUnread = false;
  Object.values(chatRoom.unreadEstateChatroomMap).forEach(bool => {
    if (bool) hasUnread = true;
  });

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>
            {hasPermission(['super-admin', 'event-manager']) ? (
              <li>
                <Link to="/activities" className="waves-effect">
                  <i className="bx bx-calendar-event"></i>
                  <span>活動管理</span>
                </Link>
              </li>
            ) : null}

            {hasPermission([
              'super-admin',
              'super-property-manager',
              'property-manager-經理',
            ]) ? (
              <li>
                <Link to="/estates" className="waves-effect">
                  <i className="far fa-building"></i>
                  <span>物業管理</span>
                </Link>
              </li>
            ) : null}

            {hasPermission(['super-admin', 'property-agent']) ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-buildings"></i>
                  <span>樓盤管理</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <NavLink
                      to="/properties"
                      activeClassName="active mm-active"
                    >
                      樓盤
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sellLeaseForm"
                      activeClassName="active mm-active"
                    >
                      樓盤租賃及出售表格
                    </NavLink>
                  </li>
                </ul>
              </li>
            ) : null}

            {hasPermission(['super-admin', 'renovation-staff']) ? (
              <li>
                <Link to="/renovation" className="waves-effect">
                  <i className="bx bx-paint-roll"></i>
                  <span>裝修管理</span>
                </Link>
              </li>
            ) : null}

            {hasPermission(['super-admin']) ? (
              <li>
                <Link to="/houseCheck" className="waves-effect">
                  <i className="mdi mdi-sticker-alert-outline"></i>
                  <span>驗樓個案</span>
                </Link>
              </li>
            ) : null}

            {hasPermission([
              'super-admin',
              'super-property-manager',
              'property-manager-經理',
              'property-manager-主任',
            ]) ? (
              <li>
                <Link to="/notice" className="waves-effect">
                  <i className="bx bx-notepad"></i>
                  <span>屋苑通告</span>
                </Link>
              </li>
            ) : null}

            {hasPermission(['super-admin']) ? (
              <li>
                <Link to="/serviceprovider" className="waves-effect">
                  <i className="bx bx-radar"></i>
                  <span>服務供應商</span>
                </Link>
              </li>
            ) : null}

            {hasPermission(['super-admin', 'cs']) ? (
              <li>
                <Link to="/servicematching" className="waves-effect">
                  <i className="bx bx-support"></i>
                  <span>家居維修配對</span>
                </Link>
              </li>
            ) : null}

            {hasPermission(['super-admin']) ? (
              <li>
                <Link to="/commission" className="waves-effect">
                  <i className="bx bx-dollar"></i>
                  <span>家居維修佣金</span>
                </Link>
              </li>
            ) : null}

            {hasPermission([
              'super-admin',
              'cs',
              'super-property-manager',
              'property-manager-主任',
              'property-manager-經理',
              'property-agent',
            ]) ? (
              <li className="position-relative">
                <Link
                  to="/#"
                  className="has-arrow waves-effect position-relative"
                >
                  <i className="bx bx-chat"></i>
                  <span>對話</span>
                </Link>
                {hasUnread ? (
                  <div
                    className="position-absolute"
                    style={{ top: 0, right: 20 }}
                  >
                    <i className="mdi mdi-circle text-danger font-size-10" />
                  </div>
                ) : null}
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <NavLink
                      to={getChatRoomRoute()}
                      activeClassName="active mm-active"
                    >
                      對話紀錄
                    </NavLink>
                  </li>
                  {profile && (profile.CSRole || profile.SuperAdminRole) ? (
                    <li>
                      <NavLink
                        to="/textTemplate"
                        activeClassName="active mm-active"
                      >
                        對話範本
                      </NavLink>
                    </li>
                  ) : null}
                </ul>
              </li>
            ) : null}

            {hasPermission(['super-admin']) ? (
              <li>
                <Link to="/mediaSetting" className="waves-effect">
                  <i className="bx bxl-youtube"></i>
                  <span>媒體管理</span>
                </Link>
              </li>
            ) : null}

            {hasPermission(['super-admin']) ? (
              <li>
                <Link to="/admins" className="waves-effect">
                  <i className="bx bx-user-circle"></i>
                  <span>管理員</span>
                </Link>
              </li>
            ) : null}

            {hasPermission(['super-admin']) ? (
              <li>
                <Link to="/users" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>會員</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
