import {
  Building,
  Estate,
  HKIslandType,
  KowloonType,
  LocationType,
  NTType,
} from 'app/models';

export const renderDistrict = (estate: Estate<Building>) => {
  switch (estate.region) {
    case 'hk':
      return HKIslandType[estate.district];
    case 'kln':
      return KowloonType[estate.district];
    case 'nt':
      return NTType[estate.district];
    default:
      return '-';
  }
};

export const getDistrictOption = region => {
  switch (region) {
    case 'hk':
      return Object.keys(HKIslandType).map(key => {
        return { value: key, label: HKIslandType[key] };
      });
    case 'kln':
      return Object.keys(KowloonType).map(key => {
        return { value: key, label: KowloonType[key] };
      });
    case 'nt':
      return Object.keys(NTType).map(key => {
        return { value: key, label: NTType[key] };
      });
    default:
      break;
  }
  return [];
};

export const renderAddress = user => {
  let address = `
  ${LocationType[user.EstateBuilding.Estate.region]} ${renderDistrict(
    user.EstateBuilding.Estate,
  )} ${user.EstateBuilding.Estate.name} ${user.EstateBuilding.name}`;
  if (user.floor && user.flat) {
    address += ` ${user.floor}樓 ${user.flat}室`;
  }
  return address;
};
